import React, { useContext, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import access_img from "../../assets/images/access_img.png";
import { LeftNavContext } from "../../LeftNavProvider";
const AccessRestrictedModal = () => {
  const { isModalVisible, setIsModalVisible, setToggle } =
    useContext(LeftNavContext);
  const history = useHistory();
  const location = useLocation();
  const handleGoHome = () => {
    setIsModalVisible(false);
    if (isModalVisible === "settings") {
      return;
    }
    setToggle(false);
    history.push("/dashboard");
    // Redirect to home or perform desired action
  };
  const isBasic = localStorage.getItem("user_account_access_type") === "BASIC";
  useEffect(() => {
    if (
      isBasic &&
      (location.pathname?.includes("/purchases") ||
        location.pathname?.includes("/cards") ||
        location.pathname?.includes("/payment_links") ||
        location.pathname?.includes("/bulk") ||
        location.pathname?.includes("/settings/customfields") ||
        location.pathname?.includes("/settings/currencies") ||
        location.pathname?.includes("/settings/grades") ||
        location.pathname?.includes("/settings/customisation/custom_modules") ||
        location.pathname?.includes("/settings/policies") ||
        location.pathname?.includes("/sales"))
    ) {
      setIsModalVisible(true);
    } else {
      setIsModalVisible(false);
    }
  }, [location.pathname]);

  if (!isModalVisible) return null; // Hide the modal when it's not visible

  return (
    <div style={styles.modalOverlay}>
      <div style={styles.modalContent}>
        <div style={styles.icon}>
          <img src={access_img} height={200} />
        </div>
        <h4 style={styles.title}>Access Restricted</h4>
        <p style={styles.description}>
          Your account does not have access to this module.
          <br />
          Please contact the administrator for assistance.
        </p>
        <button className="send-btn" onClick={handleGoHome}>
          {isModalVisible === "settings" ? "Close" : "Go To Home"}
        </button>
      </div>
    </div>
  );
};

const styles = {
  modalOverlay: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 1000,
  },
  modalContent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "1rem",
    flexDirection: "column",
    backgroundColor: "white",
    borderRadius: "8px",
    padding: "30px",
    width: "500px",
    maxWidth: "90%",
    height: "450px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
  },
  homeIcon: {
    fontSize: "40px",
  },
  title: {
    margin: 0,
    fontSize: "1.4rem",
    fontWeight: "600",
    color: "#212121",
  },
  description: {
    fontSize: "14px",
    marginTop: "10px",
    textAlign: "center",
  },
  button: {
    backgroundColor: "#007bff",
    color: "white",
    border: "none",
    padding: "10px 20px",
    fontSize: "16px",
    borderRadius: "5px",
    cursor: "pointer",
    marginTop: "20px",
    transition: "background-color 0.3s",
  },
};

// Button hover effect (can be defined separately if using external CSS)
styles.buttonHover = {
  backgroundColor: "#0056b3",
};

export default AccessRestrictedModal;
