import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { message } from "antd";

export const useHandleResponseNode = (
  actionType,
  successMessage,
  failureMessage,
  response,
  successCall,
  noReset,
  failCall
) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (response?.data?.error === false) {
      message.success(<span className="messageText">{successMessage}</span>);
      if (successCall) {
        successCall();
      }
      if (!noReset)
        dispatch({
          type: actionType.RESET,
        });
    } else if (response?.data?.error) {
      message.error(
        <span className="messageText">
          {failureMessage ? failureMessage : response?.data?.message}
        </span>
      );
      if (failCall) {
        failCall();
      }
      dispatch({
        type: actionType.RESET,
      });
    }
    // eslint-disable-next-line
  }, [response]);
};

const useHandleResponse = (
  actionType,
  successMessage,
  failureMessage,
  response,
  successCall,
  noReset
) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (response?.success === true) {
      message.success(<span className="messageText">{successMessage}</span>);
      if (successCall) {
        successCall();
      }
      if (!noReset)
        dispatch({
          type: actionType.RESET,
        });
    } else if (response?.success === false) {
      message.error(<span className="messageText">{failureMessage}</span>);
      dispatch({
        type: actionType.RESET,
      });
    }
    // eslint-disable-next-line
  }, [response]);
};

export default useHandleResponse;
