import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import failed from "../../assets/images/failed.png";
import success from "../../assets/images/succesasset.png";
import { apis } from '../../config/APIs';
import endpointPayApi from '../../config/Axios';

const EmailAction = ({ type }) => {
  const location = useLocation();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [urlBroken, setURLBroken] = useState(true);
  const [moduleType, setModuleType] = useState("");
  useEffect(() => {
    if (location.search) {

      const searchParams = new URLSearchParams(location.search)
      const email_action = searchParams.get("action")
      const user_id = searchParams.get("user_id")
      const module_type = searchParams.get("module_type")
      const record_id = searchParams.get("id")
      setModuleType(searchParams.get("module_type"))
      setLoading(true)

      if (email_action && user_id && module_type && record_id) {
        setURLBroken(false);

        endpointPayApi.post(apis.emailActionAPI, {
          id: record_id,
          module_type: module_type,
          action: email_action,
          user_id: user_id,
        }, {
          headers: {
            'content-type': 'application/json'
          },      
        }).then(res => {
          setData(res.data)
          setLoading(false)
          setURLBroken(false);
        }).catch((err)=>{
          setURLBroken(true);
          setLoading(false);
          setData(err?.response?.data)
        })

      } else {
        setURLBroken(true);
        setLoading(false);
      }
    }
    // eslint-disable-next-line
  }, [location.search])
  return (
    <div style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column"
    }}>
      {loading ? <div>Loading...</div> : 
      <>
      <img src={urlBroken ? failed : success} alt="" style={{
        padding: "2rem 0"
      }} width={300} />
      <h2 style={{ color: "#333" }}>
        {!data?.error ? `${moduleType} ${type} successful` : data?.message}
      </h2>
      </>
      }
    </div>
  );
};

export default EmailAction;