import { MoreOutlined } from "@ant-design/icons";
import { Button, Dropdown, Menu, Modal, Space, Table, message } from "antd";
import React, { useEffect, useState } from "react";
import DeleteIcon from "../../../assets/icons/delete.png";
import EditIcon from "../../../assets/icons/edit.png";
import { apis } from '../../../config/APIs';
import useForgoCardsAxios from '../../../config/useAxios';
import "./currencies.css";

import { useDispatch, useSelector } from "react-redux";
import {
  getAllExchangeRates, getCompanyMultipleCurrency
} from "../../../actions/companySettings";
import { amountFormat } from "../../../utils";
import { formatDate } from "../../Sales/Common";
import AddExhangeRate from "./AddExchangeRate";

const ViewExchangeRates = ({ open, onCancel, record, hasWriteAccess ,baseCurrency}) => {
  const { onCall } = useForgoCardsAxios({ api: apis.deleteExchangeRateAPIs, method: 'post', payApi: 'settings' });
  const [isExchangeRateVisible, setIsExchangeRateVisible] = useState(false);
  const [isExchangeRateDeleteModal, setIsExchangeRateDeleteModal] = useState(false);
  const [isRecordId, setIsRecordId] = useState();
  const [isRecord, setIsRecord] = useState();
  const [companyRecord, setCompanyRecord] = useState();

  const getAllExchangeRatesResponse = useSelector(
    (state) => state?.companySetting?.getAllExchangeRates?.data?.data
  );

  const dispatch = useDispatch();

  useEffect(()=>{
    dispatch(getAllExchangeRates({company_currency_id : record?.id}));
    setCompanyRecord(record);
    return ()=> setCompanyRecord('');

  }, [record,isExchangeRateVisible])
  
  const handleClose = () => {
    onCancel();
    setCompanyRecord('');
  };
  const handleDelete = (id) => {
    setIsRecordId(id);
    setIsExchangeRateDeleteModal(true)
  }
  const handleUpdateExchangeRate = (record) => (
    setIsExchangeRateVisible(true), setIsRecord(record)
  );

  const handleDeleteExchangeRate = () => {
    onCall({ data: { id: isRecordId } }).then((data) => (
      message.success(<span className="messageText">{data?.message}</span>),
      setIsExchangeRateDeleteModal(false),
      setTimeout(() => {
        dispatch(getAllExchangeRates({company_currency_id : record?.id}));
      }, 100),
    dispatch(getCompanyMultipleCurrency())

    )).catch((err) => (
      message.error(<span className="messageText">{err?.message}</span>),
      setIsExchangeRateDeleteModal(false)
    ));
  }

  const columns = [
    {
      title: "Exchange Date",
      dataIndex: "exchange_date",
      key: "exchange_date",
      render: (text) => <div>{formatDate(text)}</div>,
    },
    {
      title: "Exchange Rate in (INR)",
      dataIndex: "exchange_rate",
      key: "exchange_rate",
      render: (text) => <div>{amountFormat(text)}</div>
    },
    {
      title: "Actions",
      key: "action",
      render: (_, record) => (
        <Space
          size='middle'
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item
                  onClick={() =>
                    hasWriteAccess && handleUpdateExchangeRate(record)
                  }
                >
                  <img
                    src={EditIcon}
                    alt={"View Details"}
                    className='action-logo'
                  />
                  { "Edit Exchange Rate"}
                </Menu.Item>
                <Menu.Item
                  onClick={() => {
                    hasWriteAccess && handleDelete(record.id);
                  }}
                >
                  <img src={DeleteIcon} alt={"logo"} className='action-logo' />{" "}
                  Delete Exchange Rate
                </Menu.Item>
              </Menu>
            }
          >
            <Button
              shape='default'
              icon={<MoreOutlined />}
              style={{ border: "none", color: "#5B87F9" }}
            />
          </Dropdown>
        </Space>
      ),
    },
  ];


  return (
    <>
      <div className='add-currency-container'>
        <Modal
          title={"Exchange Rates"}
          open={open}
          onCancel={handleClose}
          footer={[]}
          className='right-alinged-modal add-currency-modal'
          width={650}
        >
          <h3 style={{color:'gray', opacity:0.5}}>{`${record?.name} (${record?.iso_code})`}</h3>
          <Table
            rowKey='id'
            columns={[...columns]}
            dataSource={getAllExchangeRatesResponse}
            className='settingsTable'
          />
        </Modal>

        <Modal
          title='Delete Exchange Rate'
          open={isExchangeRateDeleteModal && hasWriteAccess}
          onCancel={() => setIsExchangeRateDeleteModal(false)}
          maskClosable={false}
        >
          <p className='modal-text'>
            Are you sure you want to delete the exchange rate?
          </p>

          <div
            className='form-btm mt2'
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Button
              type='primary'
              htmlType='submit'
              className='pp-main-button '
              id='save'
              onClick={handleDeleteExchangeRate}
            >
              <span>Delete</span>
            </Button>
            <Button
              className='pp-secondary-button'
              style={{ marginLeft: "2rem" }}
              onClick={() => setIsExchangeRateDeleteModal(false)}
            >
              <span>cancel</span>
            </Button>
          </div>
        </Modal>

      <AddExhangeRate open={isExchangeRateVisible && hasWriteAccess} onCancel={() => setIsExchangeRateVisible(false)}
       record={companyRecord ? companyRecord : ""}
        selectedRecord={isRecord ? isRecord : ""}
        baseCurrency={baseCurrency}
       />
        
      </div>
    </>
  );
};

export default ViewExchangeRates;
