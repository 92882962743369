import { all } from "redux-saga/effects";
import { accountsWatcher } from "./actions/accounts/saga";
import { loginWatcher } from "./actions/auth/saga";
import { categoriesWatcher } from "./actions/categories/saga";
import { companySettingWatcher } from "./actions/companySettings/saga";
import { customersWatcher } from "./actions/customers";
import { dashboardWatcher } from "./actions/dashboard/saga";
import { employeesWatcher } from "./actions/employees/saga";
import { masterdataWatcher } from "./actions/masterdata/saga";
import { ocrWatcher } from "./actions/ocr/saga";
import { paymentsWatcher } from "./actions/payments/saga";
import { transfersWatcher } from "./actions/transfers";
import { z_accountsWatcher } from "./actions/z_accounts";
import { onboardWarcher } from "./components/OnBoarding/action/OnBoardForm/saga";

export function* rootSagas() {
  yield all([
    loginWatcher(),
    dashboardWatcher(),
    categoriesWatcher(),
    employeesWatcher(),
    companySettingWatcher(),
    accountsWatcher(),
    masterdataWatcher(),
    customersWatcher(),
    transfersWatcher(),
    ocrWatcher(),
    onboardWarcher(),
    paymentsWatcher(),
    z_accountsWatcher(),
  ]);
}
