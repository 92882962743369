import { Input } from "antd";
import React from "react";
import { Controller } from "react-hook-form";

const FormRadio = ({
  options,
  errors,
  errorMessage,
  label,
  placeholder,
  required,
  readOnly = false,
  horizontal = false,
  radio_name,
  disabled,
  ...restOfProps
}) => {
  return (
    <>
      <Controller
        {...restOfProps}
        shouldUnregister={true}
        render={({ field: { onChange, value } }) => (
          <div
            style={{
              alignItems: horizontal && "center",
              display: horizontal && "flex",
            }}
            className="radio-div"
          >
            <span
              // className="title"
              style={{
                marginBottom: "15px",
                display: "inline-block",
                color: "#3B3B3B",
              }}
            >
              {label}
              {required && (
                <sup
                  style={{
                    color: "red",
                    fontSize: "14px",
                    position: "relative",
                    top: "-1px",
                  }}
                >
                  *
                </sup>
              )}
            </span>
            <div
              style={{
                paddingTop: !horizontal && "0px",
                paddingLeft: horizontal && "10px",
                display: "flex",
                // justifyContent: "space-between",
                gap: "1.1rem",
              }}
            >
              {options?.map((ele, i) => (
                <label
                  style={{
                    display: "inline-flex",
                    color: "#333",
                    cursor: "pointer",
                    alignItems: "center",
                  }}
                  htmlFor={radio_name ? radio_name + i : "radio" + i}
                >
                  <Input
                    className="pp-form-radio"
                    placeholder={placeholder}
                    onChange={onChange}
                    name={radio_name ? radio_name + i : "radio"}
                    id={radio_name + i ?? "radio" + i}
                    readOnly={readOnly}
                    checked={value === ele.value}
                    type="radio"
                    value={ele.value}
                    disabled={disabled}
                  />
                  &nbsp;&nbsp;
                  <span
                    style={{
                      color: "#333",
                      fontSize: 14,
                      fontWeight: 500,
                    }}
                  >
                    {ele.label}
                  </span>
                </label>
              ))}
              {errors?.[restOfProps?.name]?.message || errorMessage ? (
                <>
                  <br />
                  <div className="errorMsg">
                    {errors?.[restOfProps?.name]?.message || errorMessage}
                  </div>
                </>
              ) : null}
            </div>
          </div>
        )}
      />
    </>
  );
};

export default FormRadio;
