import { Input } from "antd";
import React from "react";
import { Controller } from "react-hook-form";
import { amountEnterFormat, notNumber } from "../../utils";
import FormSelect from "./FormSelect";
import { countries } from "../OnBoarding/Auth/SignUpFrom/UserDetailsForm";

const FormInput = ({
  lessThan,
  value: val,
  type,
  rows,
  amount,
  number,
  errors,
  prefix,
  errorMessage,
  label,
  placeholder,
  required,
  addonBefore,
  addonAfter,
  readOnly = false,
  horizontal = false,
  suffix,
  autoComplete = "off",
  disabled = false,
  style,
  isMobile,
  phnCode,
  noSpace,
  maxLength,
  ...restOfProps
}) => {
  const getValue = (value) => {
    if (
      amount &&
      value &&
      (value?.split(".")?.[1]?.length > 0 || !value?.includes("."))
    ) {
      return amountEnterFormat(value);
    } else if (amount && value?.includes(".")) {
      return (
        amountEnterFormat(value?.split(".")?.[0]) + `.${value?.split(".")?.[1]}`
      );
    }
    return value;
  };
  return (
    <div>
      <Controller
        {...restOfProps}
        shouldUnregister={true}
        render={({ field: { onChange, value, name } }) => (
          <div
            style={{
              alignItems: horizontal && "center",
              display: horizontal && "flex",
            }}
          >
            {label && (
              <span
                style={{
                  fontSize: "14px",
                  color: "#3B3B3B",
                  display: "inline-block",
                  marginBottom: 5,
                }}
              >
                {label}
                {required && (
                  <sup
                    style={{
                      color: "red",
                      fontSize: "14px",
                      position: "relative",
                      top: "-1px",
                    }}
                  >
                    *
                  </sup>
                )}
              </span>
            )}
            <div
              style={{
                paddingTop: !horizontal && "0px",
                paddingLeft: horizontal && "10px",
              }}
            >
              {readOnly ? (
                <div>{value}</div>
              ) : type === "textarea" ? (
                <Input.TextArea
                  style={{
                    resize: "none",
                    border: "1px solid #D9D9D9",
                    borderRadius: "6px",
                    marginTop: 5,
                  }}
                  rows={rows}
                  placeholder={placeholder}
                  onChange={(e) => {
                    if (number && notNumber(e.target.value?.trimStart())) {
                      return;
                    }
                    onChange(e);
                  }}
                  value={val ? val : value ? value : null}
                  readOnly={readOnly}
                  name={name}
                />
              ) : type === "password" ? (
                <Input.Password
                  className="pp-form-input"
                  placeholder={placeholder}
                  prefix={prefix}
                  suffix={suffix}
                  onChange={(e) => {
                    e.target.value = e.target.value?.trimStart?.();
                    onChange(e);
                  }}
                  value={getValue(value) ? getValue(value) : null}
                  readOnly={readOnly}
                  name={name}
                  addonBefore={addonBefore}
                  addonAfter={addonAfter}
                />
              ) : (
                <div className={isMobile ? "mobile_number" : ""}>
                  {isMobile && (
                    <FormSelect
                      inline
                      control={restOfProps?.control}
                      style={{ width: "100%" }}
                      {...restOfProps?.register("phone_country_code")}
                      options={countries?.map((item) => ({
                        value: item?.dial_code,
                        dial_code: item?.dial_code,
                        label: (
                          <span
                            style={{
                              color: "#212121",
                              fontSize: 13,
                            }}
                          >
                            <img
                              src={`https://cdn.jsdelivr.net/npm/svg-country-flags@1.2.10/svg/${item?.code?.toLowerCase()}.svg`}
                              className="mrHalf"
                              width={20}
                              style={{
                                boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.4)",
                              }}
                            />
                            {`(${item?.dial_code})`}
                          </span>
                        ),
                      }))}
                      hideCreateOption={true}
                      filterKey={"dial_code"}
                      noPaddingTop={true}
                      noSearch={true}
                      value={phnCode}
                      disabled={disabled}
                    />
                  )}
                  <Input
                    className="pp-form-input"
                    placeholder={placeholder}
                    autoComplete={autoComplete}
                    prefix={prefix}
                    suffix={suffix}
                    style={style}
                    onChange={(e) => {
                      e.target.value = e.target.value?.trimStart?.();
                      if (amount)
                        e.target.value = e.target.value?.replaceAll?.(",", "");

                      if (
                        amount &&
                        e.target.value?.split?.(".")?.length > 0 &&
                        e.target.value?.split?.(".")?.[1]?.length > 2
                      ) {
                        return;
                      }
                      if (
                        number &&
                        e.target.value?.split?.(".")?.length > 0 &&
                        e.target.value?.split?.(".")?.[1]?.length > 2
                      ) {
                        return;
                      }

                      if (number && notNumber(e.target.value?.trimStart())) {
                        return;
                      }

                      if (lessThan && Number(e.target.value) > lessThan) {
                        return;
                      }
                      if (noSpace) {
                        e.target.value = e.target.value?.trim();
                      } 

                      onChange(e);
                    }}
                    value={val ? val : getValue(value) ? getValue(value) : null}
                    readOnly={readOnly}
                    name={name}
                    addonBefore={addonBefore}
                    addonAfter={addonAfter}
                    disabled={disabled}
                    maxLength={maxLength}
                  />
                </div>
              )}
            </div>
            {errors?.[restOfProps?.name]?.message || errorMessage ? (
              <div className="errorMsg">
                {errors?.[restOfProps?.name]?.message || errorMessage}
              </div>
            ) : null}
          </div>
        )}
      />
    </div>
  );
};

export default FormInput;
