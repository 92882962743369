import endpointPayApi from "./Axios";
import jwt_decode from "jwt-decode";

const currencyFormat = new Intl.NumberFormat("en-US", {
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
});

const currencyFormat2 = new Intl.NumberFormat("en-US", {
  maximumFractionDigits: 2,
});

export const getSymbolCode = () => {
  let baseCurrency = localStorage.getItem("base_currency");
  if (baseCurrency) {
    baseCurrency = JSON?.parse(baseCurrency);
  }
  return baseCurrency?.symbol_code;
};

export const resetToken = () => {
  try {
    const token = jwt_decode(localStorage.getItem("token"));
    endpointPayApi
      .get(
        `v1/ems/0/check/generateToken?user_id=${token.user_id}&company_id=${token.company_id}&session_id=${token.session_key}`
      )
      .then((responseToken) => {
        if (responseToken?.data?.status === 200) {
          const payApiToken = responseToken?.data?.data;
          const payApiTokenDecoded = jwt_decode(payApiToken);

          localStorage.setItem("tokenPayApi", payApiToken);
          localStorage.setItem("user_id_payApi", payApiTokenDecoded.user_id);
        }
      });
  } catch (error) {
    console.error(error);
  }
};

export const getSymbol = () => {
  let baseCurrency = localStorage.getItem("base_currency");
  if (baseCurrency) {
    baseCurrency = JSON.parse(baseCurrency);
  }
  return baseCurrency?.symbol;
};

export const millify = (amount, isK, isZ) => {
  if (Number.isNaN(parseInt(amount))) {
    return "-";
  }
  if (
    parseInt(amount)?.toString()?.length > 3 &&
    parseInt(amount)?.toString()?.length < 6 &&
    isK
  ) {
    return currencyFormat.format(parseFloat(amount) / 1e3) + "K";
  } else if (parseInt(amount)?.toString()?.length < 6) {
    return currencyFormat.format(parseFloat(amount));
  } else if (
    parseInt(amount)?.toString()?.length < 10 &&
    parseInt(amount)?.toString()?.length >= 6
  ) {
    return currencyFormat.format(parseInt(amount) / 1e6) + "M";
  } else if (
    parseInt(amount)?.toString()?.length < 13 &&
    parseInt(amount)?.toString()?.length >= 9
  ) {
    return currencyFormat.format(parseInt(amount) / 1e9) + "B";
  } else if (isZ && parseInt(amount)?.toString()?.length >= 12) {
    return currencyFormat.format(parseInt(amount) / 1e15) + "P";
  } else if (parseInt(amount)?.toString()?.length >= 12) {
    return currencyFormat.format(parseInt(amount) / 1e12) + "T";
  }
  return currencyFormat.format(parseFloat(amount));
};

export const graph_millify = (amount, isK, isZ) => {
  if (Number.isNaN(parseInt(amount))) {
    return "-";
  }
  if (
    parseInt(amount)?.toString()?.length > 3 &&
    parseInt(amount)?.toString()?.length < 6 &&
    isK
  ) {
    return currencyFormat2.format(parseFloat(amount) / 1e3) + "K";
  } else if (parseInt(amount)?.toString()?.length < 6) {
    return currencyFormat2.format(parseFloat(amount));
  } else if (
    parseInt(amount)?.toString()?.length < 10 &&
    parseInt(amount)?.toString()?.length >= 6
  ) {
    return currencyFormat2.format(parseInt(amount) / 1e6) + "M";
  } else if (
    parseInt(amount)?.toString()?.length < 13 &&
    parseInt(amount)?.toString()?.length >= 9
  ) {
    return currencyFormat2.format(parseInt(amount) / 1e9) + "B";
  } else if (isZ && parseInt(amount)?.toString()?.length >= 12) {
    return currencyFormat2.format(parseInt(amount) / 1e15) + "P";
  } else if (parseInt(amount)?.toString()?.length >= 12) {
    return currencyFormat2.format(parseInt(amount) / 1e12) + "T";
  }
  return currencyFormat2.format(parseFloat(amount));
};

export const currencyMapper = {
  USD: "$",
  EUR: "€",
  GBP: "£",
  JPY: "¥",
  AUD: "A$",
  CAD: "C$",
  CHF: "CHF",
  CNY: "¥",
  SEK: "kr",
  NZD: "NZ$",
  INR: "₹",
  RUB: "₽",
  BRL: "R$",
  ZAR: "R",
  SGD: "S$",
  HKD: "HK$",
  KRW: "₩",
  MXN: "MX$",
  MYR: "RM",
  IDR: "Rp",
  AED: "د.إ",
};

export const currencyNames = {
  USD: "United States Dollar",
  EUR: "Euro",
  GBP: "British Pound Sterling",
  JPY: "Japanese Yen",
  AUD: "Australian Dollar",
  CAD: "Canadian Dollar",
  CHF: "Swiss Franc",
  CNY: "Chinese Yuan",
  SEK: "Swedish Krona",
  NZD: "New Zealand Dollar",
  INR: "Indian Rupee",
  RUB: "Russian Ruble",
  BRL: "Brazilian Real",
  ZAR: "South African Rand",
  SGD: "Singapore Dollar",
  HKD: "Hong Kong Dollar",
  KRW: "South Korean Won",
  MXN: "Mexican Peso",
  MYR: "Malaysian Ringgit",
  IDR: "Indonesian Rupiah",
};

export const payment_status = {
  WAITING_FOR_AML: "Processing",
  INSERTED: "Processing",
  WAITING_FOR_EXTERNAL_PROVIDER: "Processing",
  CANCELLED: "Cancelled",
  PROCESSED: "Processed",
};

export const exchageRates = {
  USD: 1,
  EUR: 0.92,
  GBP: 0.77,
  JPY: 156.93,
  AUD: 1.49,
  CAD: 1.34,
  CHF: 0.9,
  CNY: 7.29,
  SEK: 11.09,
  NZD: 1.66,
  INR: 83.09,
  RUB: 96.05,
  BRL: 4.95,
  ZAR: 18.8,
  SGD: 1.37,
  HKD: 7.82,
  KRW: 1327.5,
  MXN: 17.14,
  MYR: 4.67,
  IDR: 15361.0,
  AED: 3.673,
};
