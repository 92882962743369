import { InfoCircleOutlined } from "@ant-design/icons";
import { Col, Popover, Row, Select } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { get_purposes } from "../../../actions/z_accounts";
import TableComponent from "../../Common/TableComp/TableComp";

const UploadPaymentsSecond = ({
  data,
  type,
  fileErrs: errors,
  step,
  setdata,
  setFileErrs,
  isInsufficient
}) => {
  
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const purposes = useSelector(
    (state) =>
      state?.z_accs?.purposes?.data?.data?.responseData?.map((ele) => ({
        label: ele.description,
        value: ele.id,
      })) ?? []
  );
  useEffect(() => {
    dispatch(get_purposes());
  }, []);
  const columnsTransfers = [
    {
      title: t("Beneficiary Name"),
      dataIndex: "beneficiary_name",
      render: (record, item, index) => (
        <p style={{ margin: 0 }}>
          {record}
          {errors[index]?.["beneficiary_name"] && (
            <Popover content={errors[index]?.["beneficiary_name"]}>
              <InfoCircleOutlined style={{ color: "red" }} />
            </Popover>
          )}
        </p>
      ),
    },
    {
      title: t("Beneficiary Email"),
      dataIndex: "email",
      render: (record, item, index) => (
        <p style={{ margin: 0 }}>
          {record}
          {errors[index]?.["email"] && (
            <Popover content={errors[index]?.["email"]}>
              <InfoCircleOutlined style={{ color: "red" }} />
            </Popover>
          )}
        </p>
      ),
    },
    {
      title: t("bank_name"),
      dataIndex: "payment_bank_name",
      render: (record, item, index) => (
        <p style={{ margin: 0 }}>
          {record}
          {errors[index]?.["payment_bank_name"] && (
            <Popover content={errors[index]?.["payment_bank_name"]}>
              <InfoCircleOutlined style={{ color: "red" }} />
            </Popover>
          )}
        </p>
      ),
    },
    {
      title: t("bank_account_num"),
      dataIndex: "payment_bank_account_no",
      render: (record, item, index) => (
        <p style={{ margin: 0 }}>
          {record}
          {errors[index]?.["payment_bank_account_no"] && (
            <Popover content={errors[index]?.["payment_bank_account_no"]}>
              <InfoCircleOutlined style={{ color: "red" }} />
            </Popover>
          )}
        </p>
      ),
    },
    {
      title: t("ifsc_code"),
      dataIndex: "payment_bank_ifsc_number",
      render: (record, item, index) => (
        <p style={{ margin: 0 }}>
          {record}
          {errors[index]?.["payment_bank_ifsc_number"] && (
            <Popover content={errors[index]?.["payment_bank_ifsc_number"]}>
              <InfoCircleOutlined style={{ color: "red" }} />
            </Popover>
          )}
        </p>
      ),
    },
    {
      title: t("Amount"),
      dataIndex: "payment_amount",
      render: (record, item, index) => (
        <p style={{ margin: 0 }}>
          {record}
          {errors[index]?.["payment_amount"] && (
            <Popover content={errors[index]?.["payment_amount"]}>
              <InfoCircleOutlined style={{ color: "red" }} />
            </Popover>
          )}
        </p>
      ),
    },
    {
      title: "Phone No",
      dataIndex: "phone",
      render: (record, item, index) => (
        <p style={{ margin: 0 }}>
          {record}
          {errors[index]?.["phone"] && (
            <Popover content={errors[index]?.["phone"]}>
              <InfoCircleOutlined style={{ color: "red" }} />
            </Popover>
          )}
        </p>
      ),
    },
    {
      title: "Purpose",
      dataIndex: "purpose_code",
      width: 200,
      render: (record, _item, index) => (
        <p style={{ margin: 0 }}>
          <Select
            options={purposes}
            value={record ? record : null}
            style={{
              width: "100%",
            }}
            onChange={(e) => {
              const arr = [...data];
              const errs = errors;
              arr[index].purpose_code = e;
              delete errs[index].purpose_code;
              if (!Object.keys(errs[index])?.length) {
                delete errs[index];
              }
              setdata([...arr]);
              setFileErrs(errs);
            }}
          />
          {errors[index]?.["purpose_code"] && (
            <Popover content={errors[index]?.["purpose_code"]}>
              <InfoCircleOutlined style={{ color: "red" }} />
            </Popover>
          )}
        </p>
      ),
    },
  ];

  const columnsPayments = [
    {
      title: t("email"),
      dataIndex: "email",
    },
    {
      title: t("phone_no"),
      dataIndex: "phoneNo",
    },
    {
      title: t("payment_amount"),
      dataIndex: "payment_amount",
    },
  ];

  return (
    <>
      <div
        className="upload_payment_table"
        style={{ display: step === 1 ? "block" : "none" }}
      >
        <Row wrap={false} className="table-wrapper">
          <Col flex="auto" className="table-wrapper-col">
            <div className="flex-grow">
              <div className="" style={{ paddingTop: "22px" }}>
                <TableComponent
                  columns={type ? columnsPayments : columnsTransfers}
                  loading=""
                  data={data}
                  hasCheckbox={false}
                  pagination={
                    data?.length > 10
                      ? {
                          total: data.length ?? 0,
                        }
                      : undefined
                  }
                  // selectedRows={selectedRows}
                  // setSelectedRows={setSelectedRows}
                  // handleRowClick={handleSelectRow}
                  rowKey="id"
                />
                {isInsufficient && (
                  <div className="errorMsg">Insufficient Balance</div>
                )}
                {Object.keys(errors).length > 0 && (
                  <div
                    style={{ color: "red", marginBottom: 14, marginTop: 10 }}
                  >
                    Please ensure that you include the necessary and accurate
                    details in the Excel file, and then proceed to upload it
                    once again.
                  </div>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default UploadPaymentsSecond;
