import { Layout } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Local_Variables } from "../actions/auth";
import { useBeforeunload } from "../config/useBeforeunload";
import { getBoolVal } from "../utils";
import AccessRestrictedModal from "./Common/AccessRestricted";
import ErrorBoundary from "./ErrorBoundary";
import Header from "./Header/Header";
import CustomerLeftNavigation from "./LeftNavigation/CustomerLeftNavigation";
import LeftNavigation from "./LeftNavigation/LeftNavigation";
import VendorLeftNavigation from "./LeftNavigation/VendorLeftNavigation";

const Content = Layout.Content;
const HeaderFooterWrap = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const logoutResponse = useSelector((state) => state.auth.logout || {});
  const collapsed = useSelector(
    (state) => state.auth.localVariables?.collapsed
  );

  // const listener = (event) => {
  //   event.preventDefault();
  //   event.returnValue =
  //     "You have unsaved changes. Are you sure you want to leave?";
  //   dispatch(logout());
  //   localStorage.clear();
  //   return "You have unsaved changes. Are you sure you want to leave?";
  // };
  React.useEffect(() => {
    dispatch({
      type: Local_Variables,
      payload: {
        is_admin: getBoolVal(localStorage.getItem("is_admin")),
      },
    });

    // window.addEventListener("beforeunload", listener);
    // return () => {
    //   window.removeEventListener("beforeunload", listener);
    // };
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (logoutResponse.success) {
      localStorage.clear();
      history.push("/");
      window.location.reload()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logoutResponse]);

  useBeforeunload(() => "On refresh application will be logged out.");

  return (
    <Layout
      className="flex-row"
      style={{
        minHeight: "100vh",
        background: "#f9f9f9",
      }}
    >
      <AccessRestrictedModal />
      {localStorage?.getItem("isCustomer") ? (
        <CustomerLeftNavigation />
      ) : localStorage?.getItem("isVendor") ? (
        <VendorLeftNavigation />
      ) : (
        <LeftNavigation />
      )}
      <Layout
        className={`site-layout main-content ${collapsed ? "collapsed" : ""}`}
        style={{
          background: "#f9f9f9",
        }}
      >
        <ErrorBoundary>
          <Header />
          <Content>
            {/* {props.role && !isSuperAdmin() && !hasAccess(props.role, "read") ? <div>
              This user do not have permissions for {props.name}
            </div> :
              props.children} */}
            {props.children}
          </Content>
        </ErrorBoundary>
      </Layout>
    </Layout>
  );
};

export default HeaderFooterWrap;
