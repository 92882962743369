import { CaretRightOutlined, MinusCircleOutlined } from "@ant-design/icons";
import {
  Button,
  Collapse,
  DatePicker,
  Input,
  Modal,
  Select,
  Steps,
  message,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { createPolicy } from "../../actions/masterdata/masterdata";
import InputSelect from "../Analytics/InputSelect";
import "./styles/advancedapproval.css";
const { Step } = Steps;

const AddAdvancedApproval = ({
  open,
  onClose,
  type,
  selectedData,
  approvalData: aData,
  hasWriteAccess,
}) => {
  const { Option } = Select;
  const { Panel } = Collapse;
  const dispatch = useDispatch();
  const [dateSelect, setDateSelect] = React.useState({});
  const [typeObj, setTypeObj] = React.useState({});
  const [approvalHi, setApprovalHi] = React.useState({});
  const [approvalData, setAdvanceApprovalData] = React.useState([]);
  const [selOptions, setSelOptions] = React.useState([]);
  const [delApprovers, setDelApprovers] = React.useState([]);
  const [delCriteria, setDelCriteria] = React.useState([]);

  const { t } = useTranslation();
  const [isAll, setIsAll] = useState(false);
  const [conditionLength, setConditionLength] = useState(1);
  const [approvalLength, setApprovalLength] = useState(1);
  const rolesResponse = useSelector(
    (state) => state.companySetting.getRoles || {}
  );
  const { data: { response: { roles: rolesData = [] } = {} } = {} } =
    rolesResponse;
  const advanceApprovalResponse = useSelector(
    (state) => state.companySetting.advanceApproval || {}
  );
  const metaData = useSelector((state) => state.policies.metadata || {});
  const create = useSelector((state) => state.policies.create || {});
  const { data: { data: policy_data = {} } = {} } = metaData;
  let keys = {
    cost_center_id: "cost_center",
    project_id: "projects",
    designation_id: "departments",
    created_by: "employees",
    user_id: "employees",
    employee_id: "employees",
  };
  const {
    reset,
    watch,
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      description: "",
      is_enable: true,
      priority: 1,
      defineCriteria: [
        {
          field_data_type: "",
          field_name: "",
          field_name_display: "",
          field_value: "",
          field_operator: "",
          field_priority: 1,
          field_condition: "when",
        },
      ],
      defineApprovers: [
        {
          priority: 1,
          notes: "",
          approval_type: "",
          approval_type_value: "",
          newHierarchyDataSet: [],
        },
      ],
    },
    shouldUseNativeValidation: false,
  });
  const condition = watch("defineCriteria");
  const approval = watch("defineApprovers");
  const onSubmit = (data) => {
    if (selectedData?.id) {
      data.id = selectedData?.id;
      data.is_enable = selectedData?.is_enable;
      data.defineApprovers = [...data.defineApprovers, ...delApprovers];
      data.defineCriteria = [...data.defineCriteria, ...delCriteria];
      setDelApprovers([]);
    }
    dispatch(createPolicy({ ...data, module_type: type }));
  };

  const handleClose = () => {
    onClose();
    reset();
  };
  const handleAddCondition = () => {
    const newCondition = [
      ...condition,
      {
        field_data_type: "",
        field_name: "",
        field_name_display: "",
        field_value: "",
        field_operator: "",
        field_priority: condition?.filter((ele) => !ele.is_deleted)?.length + 1,
        field_condition: "",
      },
    ];
    setValue("defineCriteria", newCondition);
  };
  const handleAddApproval = () => {
    const newApproval = [
      ...approval,
      {
        priority: approval?.filter((ele) => !ele.is_deleted)?.length + 1,
        notes: "",
        approval_type: "",
        approval_type_value: "",
        newHierarchyDataSet: [],
      },
    ];
    setValue("defineApprovers", newApproval);
  };
  const handleRemoveCondition = (index) => {
    const filterArray = [...condition];
    if (selectedData?.id && filterArray[index]?.id) {
      filterArray[index].is_deleted = true;
      let obj = {
        ...filterArray[index],
      };
      setDelCriteria((arr) => [...arr, obj]);
      filterArray.splice(index, 1);
    } else filterArray.splice(index, 1);
    let idx = 1;
    filterArray.forEach((ele) => {
      if (!ele.is_deleted) {
        ele.field_priority = idx;
        idx++;
      }
    });
    setDateSelect((dt) => ({ ...dt, [index]: null }));
    setValue("defineCriteria", filterArray);
  };
  const handleRemoveApproval = (index) => {
    const filterArray = [...approval];
    if (selectedData?.id && filterArray[index]?.id) {
      filterArray[index].is_deleted = true;
      let obj = {
        ...filterArray[index],
      };
      setDelApprovers((arr) => [...arr, obj]);
      filterArray.splice(index, 1);
    } else filterArray.splice(index, 1);
    let idx = 1;
    filterArray.forEach((ele) => {
      if (!ele.is_deleted) {
        ele.priority = idx;
        idx++;
      }
    });
    setValue("defineApprovers", filterArray);
  };

  useEffect(() => {
    setConditionLength(condition?.filter((ele) => !ele.is_deleted)?.length);
    setApprovalLength(approval?.length);
  }, [condition, approval]);

  React.useEffect(() => {
    const {
      data: { response: { advance_approvals = [] } = {}, status = false } = {},
    } = advanceApprovalResponse;
    if (aData && Array.isArray(aData) && aData?.length) {
      setAdvanceApprovalData(
        aData?.map((ele, idx) => {
          delete ele.id;
          return ele;
        })
      );
    } else if (status && Array.isArray(advance_approvals)) {
      setAdvanceApprovalData(
        advance_approvals?.map((ele, idx) => ({ ...ele, level: idx }))
      );
      // setSelectedRoles(advance_approvals.map(k => k.role_id))
      // message.success(<span className="messageText">{res_message}</span>);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [advanceApprovalResponse]);

  const getComponent = (type, i) => {
    switch (type) {
      case "date":
      case "date_creation":
      case "date_inv":
        return (
          <>
            <Select
              className="w25"
              options={[
                { label: t("date_range"), value: "dr" },
                { label: t("is_before"), value: "before" },
                { label: t("is_after"), value: "after" },
              ]}
              disabled={!hasWriteAccess}
              onChange={(e) => {
                setDateSelect((dt) => ({ ...dt, [i]: e }));
              }}
            />
            {dateSelect[i] === "dr" ? (
              <>
                <DatePicker
                  className="w25"
                  type={"date"}
                  onChange={(e) => {
                    let obj = {
                      start_date: moment(e).format("DD/MM/YYYY"),
                    };
                  }}
                />
                <DatePicker
                  className="w25"
                  type={"date"}
                  // disabledDate={(d) => {
                  // 	return (
                  // 		d &&
                  // 		new Date(d.format("YYYY-MM-DD")).getTime() <
                  // 		new Date(
                  // 			moment(filtersObj?.start_date, "DD/MM/YYYY").format(
                  // 				"YYYY-MM-DD"
                  // 			)
                  // 		).getTime()
                  // 	);
                  // }}
                  // onChange={(e) => {
                  // 	let obj = {
                  // 		end_date: moment(e).format("DD/MM/YYYY"),
                  // 	}
                  // 	if (takeKey) {
                  // 		obj.date = ele;
                  // 	}
                  // 	setFilterObj((fils) => ({
                  // 		...fils,
                  // 		...obj
                  // 	}));
                  // }}
                />
              </>
            ) : (
              <DatePicker
                className="w50"
                type={"date"}
                // onChange={(e) => {
                // 	let obj = {
                // 		[dateSelect === "before" ? "before_date" : "after_date"]:
                // 			moment(e).format("DD/MM/YYYY"),
                // 	}
                // 	if (takeKey) {
                // 		obj.date = ele;
                // 	}
                // 	setFilterObj((fils) => ({
                // 		...fils,
                // 		...obj
                // 	}));
                // }}
              />
            )}
          </>
        );
      case "string":
        return (
          <>
            <InputSelect
              options={[]}
              isAll={isAll}
              setIsAll={setIsAll}
              isFilter={true}
              disabled={!hasWriteAccess}
              values={[]}
              // onChange={(vals) => {
              // 	setFilterObj((fils) => ({
              // 		...fils,
              // 		[ele]: vals,
              // 	}));
              // }}
            />
          </>
        );
      case "number_amt":
      case "due_balance_amount":
      case "total_amount":
      case "payment_amount":
      case "amount_cents":
      case "amount":
      case "number":
        return (
          <>
            <Select
              className="w25"
              {...register(`defineCriteria.${i}.field_operator`, {
                required: "Field Operator is required",
              })}
              disabled={!hasWriteAccess}
              options={policy_data?.numberExpression?.map((ele) => ({
                label: ele?.replace("_", " "),
                value: ele,
              }))}
              onChange={(e) => {
                setDateSelect((dt) => ({ ...dt, [i]: e }));
                let defineCriteria = [...condition];
                defineCriteria[i].field_operator = e;
                setValue("defineCriteria", defineCriteria);
                // trigger("defineCriteria")
              }}
              value={condition[i].field_operator}
            />
            {dateSelect[i] === "range" ? (
              <>
                <Input
                  className="w25"
                  pattern="[0-9]*"
                  onChange={(e) => {
                    // setFilterObj((fils) => ({
                    // 	...fils,
                    // 	start_amount: e.target.value,
                    // }));
                  }}
                  disabled={!hasWriteAccess}
                  placeholder={t("min_amount")}
                />
                <Input
                  className="w25"
                  pattern="[0-9]*"
                  // onChange={(e) => {
                  // 	setFilterObj((fils) => ({
                  // 		...fils,
                  // 		end_amount: e.target.value,
                  // 	}));
                  // }}
                  placeholder={t("max_amount")}
                />
              </>
            ) : (
              <Input
                className="w50"
                pattern="[0-9]*"
                value={condition[i].field_value}
                disabled={!hasWriteAccess}
                {...register(`defineCriteria.${i}.field_value`, {
                  required: "Field Value is required",
                })}
                onChange={(e) => {
                  if (Number.isNaN(Number(e.target.value)) && e.target.value) {
                    return;
                  }
                  let defineCriteria = [...condition];
                  defineCriteria[i].field_value = e.target.value;
                  setValue("defineCriteria", defineCriteria);
                  // trigger("defineCriteria")
                }}
              />
            )}
          </>
        );
      case "select":
      case "select_vendor":
      case "select_by":
      case "select_project":
      case "select_role":
      case "select_cc":
      case "select_gd":
        return (
          <>
            <Select
              className="w25"
              {...register(`defineCriteria.${i}.field_operator`, {
                required: "Field Operator is required",
              })}
              disabled={!hasWriteAccess}
              options={policy_data?.stringExpression?.map((ele) => ({
                label: ele?.replace("_", " "),
                value: ele,
              }))}
              onChange={(e) => {
                setDateSelect((dt) => ({ ...dt, [i]: e }));
                let defineCriteria = [...condition];
                defineCriteria[i].field_operator = e;
                setValue("defineCriteria", defineCriteria);
                // trigger("defineCriteria")
              }}
              value={condition[i].field_operator}
            />
            <Select
              className="w75"
              disabled={!hasWriteAccess}
              options={selOptions[i]}
              {...register(`defineCriteria.${i}.field_value`, {
                required: "Field Value is required",
              })}
              showSearch
              filterOption={(input, option) =>
                (option?.label?.toLowerCase() ?? "").includes(
                  input?.toLowerCase()
                )
              }
              value={condition[i].field_value}
              onChange={(val) => {
                let defineCriteria = [...condition];
                defineCriteria[i].field_value = val;
                setValue("defineCriteria", defineCriteria);
                // trigger("defineCriteria")
              }}
            />
          </>
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    if (selectedData?.id) {
      setValue("name", selectedData?.name);
      setValue("description", selectedData?.description);
      setValue("defineApprovers", selectedData?.defineApprovers);
      setValue("defineCriteria", selectedData?.defineCriteria);
      selectedData?.defineCriteria?.forEach((element, index) => {
        setTypeObj((typ) => ({
          ...typ,
          [index]: keys[element.field_name] ? "select" : element.field_name,
        }));
        setSelOptions((opts) => ({
          ...opts,
          [index]: policy_data?.[keys[element.field_name]]?.map((ele) => ({
            label: ele.name ?? ele.title,
            value: ele.id,
          })),
        }));
        setDateSelect((dt) => ({ ...dt, [index]: element.field_operator }));
      });
    }
  }, [selectedData]);
  const stepRender = (item, pIndex, isExist) => (
    <>
      {item.level === 0 || item.role_name ? (
        <div
          className="disabledDiv"
          style={{
            background: "white",
          }}
        >
          {item.role_name}
        </div>
      ) : (
        <Select
          style={{
            background: "white",
          }}
          placeholder={t("select_role")}
          className="select-role"
          onChange={(val) => {
            const defineApprovers = [...approval];
            const hiData = [...defineApprovers[pIndex].newHierarchyDataSet];
            let value = JSON.parse(JSON.stringify(rolesData))?.find(
              (ele) => ele.id === val
            );
            delete value.id;
            let idx = hiData?.findIndex((obj) => obj.level === item.level);
            hiData[idx] = {
              ...value,
              ...hiData[idx],
              role_id: val,
              role_name: value?.role_name,
            };
            defineApprovers[pIndex].newHierarchyDataSet = hiData;
            setValue("defineApprovers", defineApprovers);
            setAdvanceApprovalData([...hiData]);
            // trigger("defineApprovers")
          }}
          value={item.role_id}
        >
          {rolesData &&
            rolesData
              ?.filter(
                (ele) =>
                  ele.role_name !== "Admin" &&
                  !approvalData
                    ?.map((ele) => (ele.is_deleted ? "" : ele.role_id))
                    ?.includes(ele.id)
              )
              .map(({ id, role_name }) => (
                <Option key={id} value={id}>
                  {role_name}
                </Option>
              ))}
        </Select>
      )}

      {item.level !== 0 && !isExist && hasWriteAccess ? (
        <MinusCircleOutlined
          className="minusButton inlineItem mtHalf cursor ml2"
          onClick={() => handleApproveList("remove", item.level, pIndex)}
        />
      ) : null}
    </>
  );

  const name = watch("name");
  const description = watch("description");

  const handleApproveList = (type, key, pIndex) => {
    if (type === "add") {
      let defineApprovers = [...approval];
      let hiData = [
        ...defineApprovers[pIndex].newHierarchyDataSet,
        {
          can_approve_or_reject: false,
          level: defineApprovers[pIndex].newHierarchyDataSet?.filter(
            (ele) => !ele.is_deleted
          )?.length,
          role_id: "",
          role_name: "",
        },
      ];
      if (
        !defineApprovers[pIndex].newHierarchyDataSet.slice(-1)?.[0]?.role_id
      ) {
        message.error(
          <span className="messageText">
            Please select the above field to add more in Hierarchy
          </span>
        );
        return;
      }

      setAdvanceApprovalData(hiData);
      defineApprovers[pIndex].newHierarchyDataSet = hiData;
      setValue("defineApprovers", defineApprovers);
    } else {
      let defineApprovers = [...approval];
      const index = defineApprovers[pIndex].newHierarchyDataSet.findIndex(
        (obj) => obj.level === key
      );
      const updatedArray = [...defineApprovers[pIndex].newHierarchyDataSet];
      if (updatedArray[index]?.id && updatedArray[index]?.role_id) {
        updatedArray[index].is_deleted = true;
        delete updatedArray[index].level;
      } else updatedArray.splice(index, 1);
      let idx = 0;
      updatedArray.forEach((ele) => {
        if (!ele.is_deleted) {
          ele.level = idx;
          idx++;
        }
      });
      defineApprovers[pIndex].newHierarchyDataSet = updatedArray;
      setValue("defineApprovers", defineApprovers);
      setAdvanceApprovalData([...updatedArray]);
    }
  };

  return (
    <>
      <Modal
        onCancel={handleClose}
        className="right-alinged-modal add-modal"
        width={720}
        title={
          hasWriteAccess ? (
            <span className="title">
              {selectedData?.name ? "Edit" : "Add"} Advanced Approval
            </span>
          ) : (
            <span className="title">View Advanced Approval</span>
          )
        }
        visible={open}
      >
        <div className="mt2">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <div className="form-section">
                <div className="form-top mb2">
                  <div className="one w80">
                    <div className="pp-form-item">
                      <label className="required">Name</label>
                      <div className="ant-col ant-form-item-control">
                        <Input
                          placeholder="Enter Name"
                          {...register("name", {
                            required: "Name is required",
                          })}
                          className="pp-form-input"
                          onChange={(e) => {
                            setValue("name", e.target.value);
                          }}
                          value={name}
                          disabled={!hasWriteAccess}
                        />
                        {errors.name?.message && (
                          <div className="errorMsg">{errors.name?.message}</div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="two w80">
                    <div className="pp-form-item">
                      <label className="inline-block">Description</label>
                      <div className="ant-col ant-form-item-control">
                        <Input.TextArea
                          {...register("description")}
                          className="pp-form-input"
                          disabled={!hasWriteAccess}
                          onChange={(e) => {
                            setValue("description", e.target.value);
                          }}
                          value={description}
                          placeholder="Enter Description (Optional)"
                          style={{ borderRadius: "5px" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="conditions-section">
                  <div className="condition-header">
                    <span>Define Conditions for this approval</span>
                  </div>
                  <div className="condition">
                    <Collapse
                      defaultActiveKey={["1"]}
                      bordered={false}
                      expandIconPosition="end"
                      expandIcon={({ isActive }) => (
                        <CaretRightOutlined rotate={isActive ? 90 : 0} />
                      )}
                    >
                      <Panel header="Define the Condition ( if any )" key="1">
                        <span className="condition-note">
                          Trigger the Approval flow when the following
                          conditions are satisfied.
                        </span>
                        {Array.isArray(condition) &&
                          condition
                            ?.filter((ele) => !ele.is_deleted)
                            ?.map((_, index) => (
                              <div className="form-sectiom">
                                <div className="one">
                                  <span style={{ color: "black" }}>
                                    {index + 1}
                                  </span>
                                </div>
                                <div className="ant-col ant-form-item-control two">
                                  {index === 0 ? (
                                    <Select value={_.field_condition} disabled>
                                      <Option value="when">WHEN</Option>
                                    </Select>
                                  ) : (
                                    <Select
                                      disabled={!hasWriteAccess}
                                      value={_.field_condition}
                                      onChange={(val) => {
                                        let defineCriteria = [...condition];
                                        defineCriteria[index].field_condition =
                                          val;
                                        setValue(
                                          "defineCriteria",
                                          defineCriteria
                                        );
                                      }}
                                    >
                                      <Option value="and">AND</Option>
                                      <Option value="or">OR</Option>
                                    </Select>
                                  )}
                                </div>
                                <div className="cant-col ant-form-item-control three">
                                  <Select
                                    className=""
                                    {...register(
                                      `defineCriteria.${index}.field_name`,
                                      {
                                        required: "Field Name  is required",
                                      }
                                    )}
                                    disabled={!hasWriteAccess}
                                    onChange={(val, data) => {
                                      setTypeObj((typ) => ({
                                        ...typ,
                                        [index]: keys[val] ? "select" : val,
                                      }));
                                      let defineCriteria = [...condition];
                                      defineCriteria[index].field_name = val;
                                      defineCriteria[index].field_value = "";
                                      defineCriteria[index].field_operator = "";
                                      defineCriteria[index].field_name_display =
                                        data.label;
                                      defineCriteria[index].field_data_type =
                                        data.dataType;

                                      setSelOptions((opts) => ({
                                        ...opts,
                                        [index]: policy_data?.[keys[val]]?.map(
                                          (ele) => ({
                                            label: ele.name ?? ele.title,
                                            value: ele.id,
                                          })
                                        ),
                                      }));
                                      setValue(
                                        "defineCriteria",
                                        defineCriteria
                                      );
                                      // trigger("defineCriteria")
                                    }}
                                    value={condition[index].field_name}
                                    options={policy_data?.defaultCriteriaDropDown?.map(
                                      (ele) => ({
                                        label: ele.value,
                                        value: ele.key,
                                        dataType: ele.dataType,
                                      })
                                    )}
                                  />
                                  {/* <Option value="number_amt">Amount</Option> */}
                                  {/* <Option value="select_vendor">Vendor</Option>
																	<Option value="select_by">Created by</Option>
																	<Option value="select_project">Project</Option>
																	<Option value="select_role">Role</Option>
																	<Option value="select_cc">Cost Center</Option>
																	<Option value="select_gd">Grade / Department</Option> */}
                                </div>
                                {getComponent(typeObj[index], index)}
                                {conditionLength === index + 1 &&
                                  index !== 0 &&
                                  hasWriteAccess && (
                                    <div
                                      className="six"
                                      onClick={() =>
                                        handleRemoveCondition(index)
                                      }
                                    >
                                      <span>-</span>
                                    </div>
                                  )}
                              </div>
                            ))}
                        <div className="errorMsg">
                          {errors?.defineCriteria?.length > 0
                            ? "Conditions are required"
                            : ""}
                          <br />
                        </div>
                        {hasWriteAccess && (
                          <div
                            className="add-one-more"
                            onClick={() => handleAddCondition()}
                          >
                            <button type="button">
                              <span>+</span> ADD CONDITION
                            </button>
                          </div>
                        )}
                      </Panel>
                    </Collapse>
                  </div>
                  <div className="approval">
                    <Collapse
                      defaultActiveKey={["1"]}
                      bordered={false}
                      expandIconPosition="end"
                      expandIcon={({ isActive }) => (
                        <CaretRightOutlined rotate={isActive ? 90 : 0} />
                      )}
                    >
                      <Panel header="Approvals" key="1">
                        <span className="condition-note">
                          You can either configure approver levels or allow the
                          system to auto approve or reject the reports
                        </span>
                        {/* <div className='check-box'>
													<Checkbox.Group options={options} defaultValue={['Apple']} onChange={onChange} />
												</div> */}
                        {Array.isArray(approval) &&
                          approval
                            ?.filter((ele) => !ele.is_deleted)
                            ?.map((_, pIndex) => (
                              <div className="form-sectiom">
                                <div className="one">
                                  <span style={{ color: "black" }}>
                                    {pIndex + 1}
                                  </span>
                                </div>
                                <div className="ant-col ant-form-item-control two">
                                  <Select
                                    className=""
                                    {...register(
                                      `defineApprovers.${pIndex}.approval_type`,
                                      {
                                        required: "Approval Type  is required",
                                      }
                                    )}
                                    disabled={
                                      !hasWriteAccess ||
                                      (selectedData?.id &&
                                        (_.approval_type === "NEWHIERARCHY" ||
                                          _.approval_type ===
                                            "EXISTINGHIERARCHY"))
                                    }
                                    onChange={(val) => {
                                      setApprovalHi((typ) => ({
                                        ...typ,
                                        [pIndex]: val,
                                      }));
                                      let defineApprovers = [...approval];
                                      defineApprovers[pIndex].approval_type =
                                        val;
                                      defineApprovers[
                                        pIndex
                                      ].approval_type_value = "";
                                      if (
                                        val === "NEWHIERARCHY" ||
                                        val === "EXISTINGHIERARCHY"
                                      ) {
                                        defineApprovers[
                                          pIndex
                                        ].newHierarchyDataSet =
                                          selectedData?.id &&
                                          selectedData?.defineApprovers?.[
                                            pIndex
                                          ]?.newHierarchyDataSet?.length
                                            ? selectedData?.defineApprovers?.[
                                                pIndex
                                              ]?.newHierarchyDataSet
                                            : approvalData;
                                      }
                                      setValue(
                                        "defineApprovers",
                                        defineApprovers
                                      );
                                      // trigger("defineApprovers")
                                    }}
                                    value={_.approval_type}
                                    options={policy_data?.defaultApproverDropDown?.map(
                                      (ele) => ({
                                        label: ele.value,
                                        value: ele.key,
                                      })
                                    )}
                                  />
                                </div>
                                <div className="ant-col ant-form-item-control three">
                                  {_.approval_type === "NEWHIERARCHY" ||
                                  _.approval_type === "EXISTINGHIERARCHY" ? (
                                    <>
                                      <Steps
                                        direction="vertical"
                                        size="small"
                                        current={
                                          _.newHierarchyDataSet?.length + 1 ||
                                          100
                                        }
                                      >
                                        {_.newHierarchyDataSet &&
                                          _.newHierarchyDataSet
                                            ?.filter((ele) => !ele.is_deleted)
                                            ?.map((item, index) => (
                                              <Step
                                                key={item.id}
                                                title={stepRender(
                                                  item,
                                                  pIndex,
                                                  _.approval_type ===
                                                    "EXISTINGHIERARCHY"
                                                )}
                                                icon={
                                                  <div className="stepsNumber">
                                                    {index + 1}
                                                  </div>
                                                }
                                              />
                                            ))}
                                      </Steps>
                                      {_.approval_type !==
                                        "EXISTINGHIERARCHY" &&
                                        hasWriteAccess &&
                                        rolesData?.filter(
                                          (ele) => ele.role_name !== "Admin"
                                        )?.length !==
                                          _.newHierarchyDataSet?.filter(
                                            (ele) => !ele.is_deleted
                                          )?.length && (
                                          <Button
                                            type="button"
                                            ghost
                                            className="approve-add-btn mt1"
                                            style={{ background: "white" }}
                                            onClick={() =>
                                              handleApproveList(
                                                "add",
                                                "",
                                                pIndex
                                              )
                                            }
                                          >
                                            {t("add_one_more")}
                                          </Button>
                                        )}
                                    </>
                                  ) : (
                                    <Select
                                      className=""
                                      options={policy_data?.defaultApproverDropDown
                                        ?.find(
                                          (itm) =>
                                            itm.key ===
                                            approval?.[pIndex]?.approval_type
                                        )
                                        ?.userList?.map((ele) => ({
                                          label: ele.name,
                                          value: ele.id,
                                        }))}
                                      disabled={!hasWriteAccess}
                                      {...register(
                                        `defineApprovers.${pIndex}.approval_type_value`,
                                        {
                                          required:
                                            "Approval Type Value is required",
                                        }
                                      )}
                                      value={
                                        approval?.[pIndex]?.approval_type_value
                                      }
                                      onChange={(val) => {
                                        let defineApprovers = [...approval];
                                        defineApprovers[
                                          pIndex
                                        ].approval_type_value = val;
                                        setValue(
                                          "defineApprovers",
                                          defineApprovers
                                        );
                                        // trigger("defineApprovers")
                                      }}
                                    />
                                  )}
                                </div>
                                {approvalLength > 1 && hasWriteAccess && (
                                  <div
                                    className="four"
                                    onClick={() => handleRemoveApproval(pIndex)}
                                  >
                                    <span>-</span>
                                  </div>
                                )}
                              </div>
                            ))}
                        <div className="errorMsg">
                          {errors?.defineApprovers?.length > 0
                            ? "Approvals are required"
                            : ""}
                          <br />
                        </div>
                        {console.log("add advanced approval error", {
                          errors: errors?.defineApprovers?.length,
                        })}

                        {hasWriteAccess && (
                          <div
                            className="add-one-more"
                            onClick={() => handleAddApproval()}
                          >
                            <button type="button">
                              <span>+</span> ADD ONE MORE
                            </button>
                          </div>
                        )}
                      </Panel>
                    </Collapse>
                  </div>
                </div>
              </div>
              <div
                className="form-btm-section"
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <button
                  className="send-btn "
                  id="save"
                  disabled={create?.loading || !hasWriteAccess}
                >
                  Save
                </button>
                <button
                  className="back-btn"
                  type="button"
                  style={{ marginLeft: "2rem" }}
                  onClick={handleClose}
                >
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
};
export default AddAdvancedApproval;
