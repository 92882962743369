import { Button, message, Modal } from "antd";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  create_customers,
  CreateCustomer,
  customers,
} from "../../actions/customers";
import "../../components/Transfers/transfer.css";
import { p_types } from "../Payments/SendMoney/Recipients";
import AddVendorAddress from "../Sales/AddCustomerAddress";
import { FormInput, FormSelect } from "../inputs";
import FormRadio from "../inputs/FormRadio";
import { countries } from "../OnBoarding/Auth/SignUpFrom/UserDetailsForm";
import { countryValidations } from "../Settings/Organisation/country";
import { achCountryCodes } from "../../utils";
import { countries as countrs } from "../Settings/Organisation/country";
import { gridApis } from "../../config/GridApis";
import usePayAxios from "../../config/useAxios";
import { get_person_accounts } from "../../actions/z_accounts";

const CreateManageBeneficiary = ({
  isVisible,
  onClose,
  benData,
  createSet,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { onCall: docTypes, data } = usePayAxios({
    api: gridApis.getRecipientDocTypes,
    method: "get",
  });
  const create_manage_bene = useSelector((state) => state.customers?.create);
  const currenciesRes = useSelector((state) =>
    state?.currencies?.data?.data?.length
      ? state?.currencies?.data?.data?.map((ele) => ({
          label: (
            <span
              style={{
                color: "#212121",
                fontSize: 15,
              }}
            >
              <img
                src={`https://cdn.jsdelivr.net/npm/svg-country-flags@1.2.10/svg/${ele?.country_code?.toLowerCase()}.svg`}
                className="mr1"
                width={25}
                style={{
                  boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.4)",
                }}
              />
              {`${ele?.country_name} (${ele?.iso_code})`}
            </span>
          ),
          value: ele?.id,
          country_name: ele?.country_name,
          country_code: ele?.country_code,
          iso_code: ele?.iso_code,
        }))
      : []
  );
  const bankAcc = useSelector(
    (state) => state?.z_accs?.accounts?.data?.data || []
  );
  const {
    control,
    register,
    unregister,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    watch,
    trigger,
  } = useForm({
    defaultValues: {
      company_id: "",
      company_name: "",
      account_mode_type: "within_bank",
      beneficiary_name: "",
      beneficiary_type: "",
      recipient_type: "person",
      identification_type_code: "",
      identification_type_code_value: "",
      primary_contact: "",
      email: "",
      account_number: "",
      ifsc_code: "",
      swift_code: "",
      bank_name: "",
      bank_city: "",
      bank_country: "",
      currency_code: "",
      country_code: "",
      routing_code: "",
      iso_code: "",
      shippingAddressSame: true,
      billing_address: [
        {
          country: "",
          address: "",
          address_two: "",
          city: "",
          state: "",
          zipcode: "",
          // phone: "",
          address_type: "billing",
        },
      ],
      shipping_address: [
        {
          country: "",
          address: "",
          address_two: "",
          city: "",
          state: "",
          zipcode: "",
          // phone: "",
          address_type: "shipping",
        },
      ],
    },
    shouldUseNativeValidation: false,
  });

  const onSubmit = (data) => {
    const bnk = {
      account_number: data.account_number,
      ifsc_code: data.ifsc_code,
      bank_name: data.bank_name,
      bank_city: data.bank_city,
      bank_country: data.bank_country,
      routing_code: data?.routing_code,
      id: benData?.bank_accounts?.[0]?.id,
      account_number_type: "BBAN",
    };
    if (data?.swift_code) {
      bnk.swift_code = data?.swift_code;
    }
    let payload = {
      company_id: localStorage.getItem("company_id"),
      company_name: "--",
      beneficiary_name: data.beneficiary_name,
      beneficiary_type: "individual",
      template_name: `${data.account_mode_type}-${data.beneficiary_name}`,
      currency_code: data.iso_code,
      primary_contact: data.primary_contact,
      recipient_type: data.recipient_type,
      identification_type_code: data.identification_type_code,
      identification_type_code_value: data.identification_type_code_value,
      account_mode_type: data.account_mode_type,
      email: data.email,
      country_code: data.country_code,
      phone_country_code: data.phone_country_code,
      billing_address: data.billing_address,
      same_as_billing_address: true,
      bank_account_details: [bnk],
      account_id: data?.account_id,
    };
    if (benData?.id) {
      payload.beneficiary_id = benData?.id;
    } else {
      payload.shipping_address = data.billing_address?.map((ele) => ({
        ...ele,
        address_type: "shipping",
      }));
    }
    dispatch(create_customers(payload));
  };

  useEffect(() => {
    docTypes({});
    dispatch(
      get_person_accounts({
        page_number: 1,
        page_size: 50,
        person_id: "ID-1146",
      })
    );
  }, []);

  useEffect(() => {
    if (benData?.id) {
      setValue("beneficiary_name", benData.beneficiary_name);
      setValue("beneficiary_type", benData.beneficiary_type);
      setValue("account_id", benData.account_id);
      setValue("email", benData.email);
      setValue("country_code", benData.country_code);
      setValue("primary_contact", benData.primary_contact);
      setValue("account_mode_type", benData.account_transfer_mode_type);
      const curr = currenciesRes?.find(
        (ele) => ele.country_code === benData.country_code
      );
      setValue("currency_code", curr?.value);
      setValue("recipient_type", benData?.recipient_type);
      setValue("identification_type_code", benData?.identification_type_code);
      setValue(
        "identification_type_code_value",
        benData?.identification_type_code_value
      );
      setValue("iso_code", curr?.iso_code);
      setValue("phone_country_code", benData.phone_country_code);

      // Set bank account details (assuming the first bank account)
      if (benData.bank_accounts && benData.bank_accounts.length > 0) {
        setValue("bank_name", benData.bank_accounts[0].bank_name);
        setValue("bank_city", benData.bank_accounts[0].bank_city);
        setValue("bank_country", benData.bank_accounts[0].bank_country);
        setValue("account_number", benData.bank_accounts[0].account_number);
        if (benData.bank_accounts[0].routing_code) {
          setValue("routing_code", benData.bank_accounts[0].routing_code);
          unregister("swift_code");
        }
        if (benData.bank_accounts[0].swift_code) {
          setValue("swift_code", benData.bank_accounts[0].swift_code);
          unregister("routing_code");
        }
      }

      // Set billing address details (assuming the first billing address)
      if (benData.billing_addresses && benData.billing_addresses.length > 0) {
        setValue("billing_address.0.city", benData.billing_addresses[0].city);
        setValue("billing_address.0.state", benData.billing_addresses[0].state);
        setValue(
          "billing_address.0.zipcode",
          benData.billing_addresses[0].zipcode
        );
        setValue(
          "billing_address.0.country",
          countrs.find(
            (ele) =>
              ele.code === benData.billing_addresses[0].country ||
              ele.value === benData.billing_addresses[0].country
          )?.code
        );
        setValue(
          "billing_address.0.address",
          benData.billing_addresses[0].address
        );
        setValue("billing_address.0.id", benData.billing_addresses[0].id);
      }
    }
  }, [benData]);

  useEffect(() => {
    if (bankAcc?.length === 1) {
      setValue("account_id", bankAcc?.[0]?.id);
    }
  }, [bankAcc]);

  useEffect(() => {
    if (create_manage_bene?.data?.error === false) {
      message.success(
        <span className="messageText">{create_manage_bene?.data?.message}</span>
      );
      if (createSet) createSet(create_manage_bene?.data?.data);
      reset();
      dispatch(
        customers({
          type: "individual",
          page_number: 1,
        })
      );
      onClose();
      dispatch({
        type: CreateCustomer.RESET,
      });
    }
    if (create_manage_bene?.data?.error) {
      message.error(
        <span className="messageText">{create_manage_bene?.data?.message}</span>
      );
      dispatch({
        type: CreateCustomer.RESET,
      });
    }
  }, [create_manage_bene]);

  const identityTypes = Array.isArray(data?.data)
    ? data?.data?.map((ele) => ({ label: ele.display_value, value: ele.value }))
    : [];

  const method = watch("account_mode_type");
  return (
    <>
      <Modal
        onCancel={() => {
          reset();
          onClose();
        }}
        className="right-alinged-modal ben add-modal"
        width={700}
        title={
          <div className="d-flex">
            <div className="flex-grow">
              <span className="title">{t("create_manage_benificiary")}</span>
              <span
                className="fs16 lh20 color-818181 fw-medium"
                style={{
                  marginLeft: "18px",
                  fontSize: "14px",
                  lineHeight: "20px",
                  color: "#818181",
                  fontWeight: "medium",
                }}
              >
                {t("enter_details")}
              </span>
            </div>
          </div>
        }
        visible={isVisible}
        getContainer={""}
      >
        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <div className="pp-form-item transfer-method">
                <FormRadio
                  label={"Transfer Method"}
                  control={control}
                  required={true}
                  options={p_types?.filter(
                    (ele) => ele.value !== "SWIFT_CROSSBORDER"
                  )}
                  disabled={benData?.id}
                  radio_name={"account_mode_type"}
                  {...register("account_mode_type", {
                    required: "Please select the method.",
                    onChange: (e) => {
                      if (e.target.value !== "SWIFT_CROSSBORDER") {
                        const curr = currenciesRes?.find(
                          (ele) => ele.country_code === "US"
                        );
                        setValue("currency_code", curr?.value);
                        setValue("country_code", curr?.country_code);
                        setValue("iso_code", curr?.iso_code);
                        if (e.target.value?.includes("WITHIN")) {
                          setValue("routing_code", "021000021");
                          setValue("bank_name", "Zenus Bank");
                          setValue("bank_country", "PR");
                          setValue("bank_city", "Juan Ponce de Leon, San Juan");
                        } else {
                          setValue("routing_code", "");
                          setValue("bank_name", "");
                          setValue("bank_country", "");
                          setValue("bank_city", "");
                        }
                      }
                    },
                  })}
                  errors={errors}
                />
              </div>
              <div className="wrap-width">
                <div className="pp-form-item">
                  <FormSelect
                    control={control}
                    {...register("account_id", {
                      required: "Account is required",
                    })}
                    placeholder="Select Account"
                    errors={errors}
                    inline
                    filterKey={`label`}
                    label={t("Your Account")}
                    required={true}
                    hideCreateOption
                    disabled={benData?.id}
                    options={
                      Array.isArray(bankAcc)
                        ? bankAcc?.map((ele) => ({
                            label: `${
                              ele.account_name
                            } - ****${ele.account_number?.slice(-4)}`,
                            value: ele.id,
                          }))
                        : []
                    }
                  />
                </div>
                <div className="pp-form-item">
                  <FormInput
                    control={control}
                    {...register("beneficiary_name", {
                      required: t("beneficiary_name_required"),
                      validate: (val) => {
                        const regexp = new RegExp(
                          /^[a-zA-Z0-9\/\-?:().,'+ ]*$/g
                        );
                        if (regexp.test(val)) {
                          return true;
                        }
                        return "Enter Valid Recipient Name";
                      },
                    })}
                    required
                    errors={errors}
                    label={t("name")}
                    placeholder={t("Enter Recipient Name")}
                    maxLength={35}
                  />
                </div>
                <div className="pp-form-item">
                  <FormSelect
                    control={control}
                    {...register("recipient_type", {
                      required: "Recipient Type is required",
                    })}
                    placeholder="Select Recipient Type"
                    errors={errors}
                    inline
                    filterKey={`label`}
                    label={t("Recipient Type")}
                    required={true}
                    hideCreateOption
                    options={[
                      { label: "Person", value: "person" },
                      { label: "Business", value: "legal" },
                    ]}
                  />
                </div>
                <div className="pp-form-item">
                  <FormSelect
                    control={control}
                    {...register("identification_type_code")}
                    placeholder="Select Identification Type"
                    errors={errors}
                    inline
                    filterKey={`label`}
                    label={t("Identification Type")}
                    hideCreateOption
                    options={identityTypes}
                  />
                </div>
                <div className="pp-form-item">
                  <FormInput
                    control={control}
                    {...register("identification_type_code_value", {
                      validate: (val) => {
                        const regexp = new RegExp(
                          /^[a-zA-Z0-9\/\-?:().,'+ ]*$/g
                        );
                        if (regexp.test(val)) {
                          return true;
                        }
                        return "Please enter valid Identification Number";
                      },
                    })}
                    errors={errors}
                    label={"Identification Number"}
                    placeholder={"Enter Identification Number"}
                    maxLength={35}
                  />
                </div>
                <div className="pp-form-item">
                  <FormInput
                    control={control}
                    {...register("email", {
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: t("enter_valid_Email"),
                      },
                    })}
                    errors={errors}
                    label={t("Email")}
                    placeholder={t("email_add")}
                    disabled={benData?.id}
                    noSpace={true}
                  />
                </div>
                <div className="pp-form-item">
                  <FormInput
                    control={control}
                    register={register}
                    {...register("primary_contact", {
                      pattern: {
                        value:
                          countryValidations.find(
                            (ele) =>
                              ele.code ==
                              countries?.find(
                                (ele) =>
                                  ele.dial_code === watch("phone_country_code")
                              )?.code
                          )?.phone_validation ?? /^[0-9]{10}$/i,
                        message: t("Enter valid phone number."),
                      },
                      onChange: (_e) => {
                        trigger("primary_contact"); // Trigger validation when the value changes
                      },
                    })}
                    errors={errors}
                    number={true}
                    label={t("phone")}
                    placeholder={t("enter_phone")}
                    isMobile={true}
                    phnCode={watch("phone_country_code")}
                    disabled={benData?.id}
                    noSpace={true}
                  />
                </div>
                <br />
                <div className="line-head">
                  <label>Bank Information</label>
                </div>
                <div className="pp-form-item">
                  <FormSelect
                    control={control}
                    {...register("currency_code", {
                      required: "Currency is required",
                      onChange: (e) => {
                        const curr = currenciesRes?.find(
                          (ele) => ele.value === e.target.value
                        );
                        setValue("country_code", curr?.country_code);
                        setValue("iso_code", curr?.iso_code);
                      },
                    })}
                    placeholder="Select Currency"
                    errors={errors}
                    inline
                    filterKey={`iso_code`}
                    label={t("Currency")}
                    required={true}
                    hideCreateOption
                    options={currenciesRes}
                    disabled={!method.includes("SWIFT")}
                    noDD={!method.includes("SWIFT")}
                  />
                </div>
                <div className="pp-form-item">
                  <FormInput
                    control={control}
                    {...register("account_number", {
                      required: t("account_number_required"),
                      validate: (val) => {
                        const regexp = new RegExp(
                          /^[a-zA-Z0-9\/\-?:().,'+ ]*$/g
                        );
                        if (regexp.test(val)) {
                          return true;
                        }
                        return "Enter Valid Account Number";
                      },
                    })}
                    required
                    errors={errors}
                    label={t("number_account")}
                    placeholder={t("enter_number")}
                    noSpace={true}
                  />
                </div>

                <>
                  {method?.includes("SWIFT") ? (
                    <div className="pp-form-item">
                      <FormInput
                        control={control}
                        {...register("swift_code", {
                          required: t("SWIFT Code is required"),
                        })}
                        required
                        errors={errors}
                        label={t("SWIFT Code")}
                        placeholder={t("Enter SWIFT Code")}
                        noSpace={true}
                      />
                    </div>
                  ) : (
                    <div
                      className="pp-form-item"
                      style={{
                        display: method?.includes("WITHIN") ? "none" : "block",
                      }}
                    >
                      <FormInput
                        control={control}
                        {...register("routing_code", {
                          required: !method?.includes("SWIFT")
                            ? t("Routing Number is required")
                            : false,
                          validate: (val) => {
                            const regexp = new RegExp(/^[a-zA-Z0-9]*$/g);
                            if (regexp.test(val)) {
                              return true;
                            }
                            return "Enter Valid Routing Number";
                          },
                        })}
                        required
                        errors={errors}
                        label={t("Routing Number")}
                        placeholder={t("Enter Routing Number")}
                        noSpace={true}
                      />
                    </div>
                  )}
                  <div
                    className="pp-form-item"
                    style={{
                      display: method?.includes("WITHIN") ? "none" : "block",
                    }}
                  >
                    <FormInput
                      control={control}
                      {...register("bank_name", {
                        required: t("bank_name_required"),
                        validate: (val) => {
                          const regexp = new RegExp(
                            /^[a-zA-Z0-9\/\-?:().,'+ ]*$/g
                          );
                          if (regexp.test(val)) {
                            return true;
                          }
                          return "Enter Valid Bank name";
                        },
                      })}
                      required
                      errors={errors}
                      label={t("bank_name")}
                      placeholder={t("enter_bank_name")}
                    />
                  </div>
                </>

                <div className="pp-form-item">
                  <FormInput
                    control={control}
                    {...register("bank_city", {
                      required: t("Bank City is required"),
                      validate: (val) => {
                        const regexp = new RegExp(
                          /^[a-zA-Z0-9\/\-?:().,'+ ]*$/g
                        );
                        if (regexp.test(val)) {
                          return true;
                        }
                        return "Enter Valid Bank City";
                      },
                    })}
                    required
                    errors={errors}
                    label={t("Bank City")}
                    placeholder={t("Enter bank city")}
                    maxLength={35}
                  />
                </div>
                <div className={`pp-form-item`}>
                  <FormSelect
                    control={control}
                    {...register(`bank_country`, {
                      required: "Bank Country is required",
                    })}
                    inline
                    required={true}
                    label={t("Bank Country")}
                    placeholder={t("select")}
                    options={countrs
                      ?.filter((ele) =>
                        method?.includes("ACH")
                          ? achCountryCodes.includes(ele.code)
                          : true
                      )
                      ?.map((ele) => ({
                        label: (
                          <>
                            <img
                              src={`https://cdn.jsdelivr.net/npm/svg-country-flags@1.2.10/svg/${ele.code?.toLowerCase()}.svg`}
                              className="mr1"
                              style={{
                                boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.4)",
                              }}
                              width={25}
                            />{" "}
                            {ele.label} ({ele.code})
                          </>
                        ),
                        value: ele.code,
                        country: ele.label,
                      }))}
                    hideCreateOption={true}
                    errors={errors}
                    filterKey={"country"}
                    disabled={method?.includes("WITHIN")}
                  />
                </div>
                <br />
                <div className="line-head">
                  <label>Address</label>
                </div>
                <AddVendorAddress
                  control={control}
                  register={register}
                  watch={watch}
                  setValue={setValue}
                  errors={errors}
                  billing_address={watch("billing_address")}
                  shipping_address={[]}
                  noShowSame={true}
                  addressRequired={true}
                />
              </div>
            </div>
            <div
              style={{ display: "flex", marginTop: "2rem" }}
              className="sticky-btm"
            >
              <button
                type="primary"
                htmlType="submit"
                className="send-btn"
                id="save"
              >
                {benData?.id ? t("Update") : t("save")}
              </button>

              <button
                className="back-btn"
                style={{ marginLeft: "2rem" }}
                onClick={() => {
                  reset();
                  onClose();
                }}
                type="button"
              >
                {t("cancel")}
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
};

export default CreateManageBeneficiary;
