import { Button, Modal, Typography, Input } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { rejectPayment } from "../../actions/payments";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
const Text = Typography.Text;
const TextArea = Input.TextArea;

const RemovePayment = ({ open, onClose, id, type }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const [reasonValue, setReasonValue] = React.useState("");
  const dispatch = useDispatch();
  const reportsRejectResponse = useSelector(
    (state) => state.payments.reject_payment || {}
  );

  const renderModalClass = () => {
    switch (type) {
      case "remove_sch":
        return "center-aligned-modal";
      case "delete":
        return "center-aligned-modal";
      case "addNewExp":
        return "center-aligned-modal-big expense";
      case "addUnrepExp":
        return "right-alinged-modal";
      case "addUnrepAdvance":
        return "right-alinged-modal";
      case "addUnrepTrip":
        return "right-alinged-modal";
      default:
        return "";
    }
  };
  const renderModalData = (type) => {
    switch (type) {
      case "remove_sch":
        return renderDeleteOption("remove_sch");
      case "delete":
        return renderDeleteOption();
      default:
        return "";
    }
  };

  const renderDeleteOption = (type) => {
    return (
      <form
        onSubmit={(e) => {
          e.preventDefault();
          const obj = {
            id: id,
            reject_reason: reasonValue,
            type: location?.state?.bulk
              ? "COMPANY_BULK_PAYMENTS"
              : "COMPANY_PAYMENTS",
          };
          dispatch(
            rejectPayment(
              type
                ? {
                    payment_id: id,
                    remarks: reasonValue,
                    type: "COMPANY_PAYMENTS",
                  }
                : obj
            )
          );
        }}
      >
        <Text>
          {type === "remove_sch"
            ? t("Please specify an appropriate reason to remove scheldule")
            : t("rejection_reason")}
        </Text>
        <br />
        <TextArea
          rows={4}
          placeholder="Enter Reason"
          className="reasonTextArea"
          onChange={(e) => setReasonValue(e.target.value)}
          required={true}
        />
        <br />
        <button
          className="send-btn mt1"
          disabled={reportsRejectResponse?.loading}
        >
          {t("confirm")}
        </button>
        <button
          className="back-btn ml1 mt1"
          onClick={() => onClose()}
          type="button"
        >
          {t("cancel")}
        </button>
      </form>
    );
  };

  const renderModalTitle = () => {
    switch (type) {
      case "remove_sch":
        return "Remove Schedule";
      case "delete":
        return "Reject Report";
      default:
        return "";
    }
  };

  return (
    <Modal
      visible={open}
      title={renderModalTitle()}
      onCancel={() => {
        onClose();
      }}
      className={renderModalClass()}
    >
      {renderModalData(type)}
    </Modal>
  );
};

export default RemovePayment;
