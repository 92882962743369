import { MinusCircleOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Collapse,
  Form,
  Input,
  message,
  Select,
  Typography,
} from "antd";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { apis } from "../../../../config/APIs";
import usePayAxios from "../../../../config/useAxios";
import useScrollToError from "../../../../config/useScrollToError";

const { Panel } = Collapse;

//  import { validationRules } from '../../../utils';
const { Option } = Select;
const { TextArea } = Input;
const { Text } = Typography;

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required("Name is required")
    .test("name-space-validation", "Name cannot be only spaces", (value) => {
      return value && typeof value === "string" && value.trim() !== "";
    }),

  description: Yup.string()
    .required("Description is required")
    .test(
      "description-space-validation",
      "Description cannot be only spaces",
      (value) => {
        return value && typeof value === "string" && value.trim() !== "";
      }
    ),
  is_enable: Yup.boolean(),
  policy_action: Yup.string().required("Policy action is required"),
  defineCriteria: Yup.array().of(
    Yup.object().shape({
      field_data_type: Yup.string().required("Field data type is required"),
      field_name: Yup.string().required("Field name is required"),
      field_value: Yup.string().required("Field value is required"),
      field_operator: Yup.string().required("Field operator is required"),
      field_condition: Yup.string().required("Field condition is required"),
    })
  ),
  defineConditions: Yup.array().of(
    Yup.object().shape({
      notes: Yup.string(),
      approval_type: Yup.string().required("Approval type is required"),
      approval_type_value: Yup.string().when("approval_type", {
        is: "AMOUNT",
        then: Yup.string()
          .required("Amount is required")
          .test(
            "greaterThanZero",
            "Amount must be greater than 0",
            (value) => parseFloat(value) > 0
          ),
        otherwise: Yup.string(),
      }),
      approval_type_condition: Yup.string().required(
        "Approval type condition is required"
      ),
    })
  ),
});

const AddTransfer = (props) => {
  const [chan_key, set_chan_key] = useState("");
  const { onCall: getMetaData, data } = usePayAxios({
    api: apis.getPolicyMetaBudgetDetails,
    method: "get",
  });
  const { onCall: createBudgetPolicy, loading } = usePayAxios({
    api: apis.createPolicyBudget,
    method: "post",
  });
  const { onCall: updateBudgetPolicy, loading: updateLoading } = usePayAxios({
    api: apis.updatePolicyBudget,
    method: "post",
  });
  const initialValues = {
    name: "",
    description: "",
    priority: 1,
    policy_action: "BLOCKING",
    defineCriteria: [
      {
        field_data_type: "string",
        field_name: "created_by",
        field_name: "created_by",
        field_name_display: "",
        field_value: null,
        field_operator: "is",
        field_priority: 1,
        field_condition: "when",
      },
    ],
    defineConditions: [
      {
        priority: 1,
        notes: "",
        approval_type: "AMOUNT",
        approval_type_value: "",
        approval_type_condition: "DAILY",
      },
    ],
  };
  const { t } = useTranslation();
  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: (vals) => {
      vals.is_enable = true;
      if (vals.id) {
        updateBudgetPolicy({
          data: vals,
        })
          .then((res) => {
            if (res.error === false) {
              props.getAll();
              handleCancelButton();
              message.success(
                <span className="messageText">
                  {"Payments policy updated successfully."}
                </span>
              );
            } else {
              message.error(<span className="messageText">{res.message}</span>);
            }
          })
          .catch((err) => {
            message.error(
              <span className="messageText">
                {err?.response?.data?.message}
              </span>
            );
          });
      } else
        createBudgetPolicy({
          data: vals,
        })
          .then((res) => {
            if (res.error === false) {
              props.getAll();
              handleCancelButton();
              message.success(
                <span className="messageText">
                  {"Payments policy created successfully."}
                </span>
              );
            } else {
              message.error(<span className="messageText">{res.message}</span>);
            }
          })
          .catch((err) => {
            message.error(
              <span className="messageText">
                {err?.response?.data?.message}
              </span>
            );
          });
    },
  });
  // Use the custom hook
  useScrollToError(
    Object.keys(formik?.errors)[0],
    formik?.errors,
    formik?.submitCount
  );

  const key_map = {
    employees: "employees",
    created_by: "employees",
    designation_id: "departments",
    departments: "departments",
    cost_center_id: "cost_centers",
    cost_centers: "cost_centers",
    projects: "projects",
    project_id: "projects",
  };

  useEffect(() => {
    getMetaData({});
  }, []);
  useEffect(() => {
    console.log(
      "field_name",
      props?.selectedRow?.defineCriteria?.[0]?.field_name
    );
    if (props?.selectedRow?.defineCriteria?.[0]?.field_name) {
      const field_name = props?.selectedRow?.defineCriteria?.[0]?.field_name;
      set_chan_key(field_name === "created_by" ? "employees" : field_name);
    } else {
      set_chan_key("employees");
    }
  }, [props?.selectedRow?.defineCriteria?.[0]?.field_name]);
  useEffect(() => {
    if (props.selectedRow?.id) {
      formik.setValues({
        ...props.selectedRow,
      });
    } else {
      formik.resetForm();
    }
    // eslint-disable-next-line
  }, [props.selectedRow]);

  const handleCancelButton = () => {
    formik.resetForm(initialValues);
    props.handleCancel();
  };

  return (
    <>
      <Form
        layout="vertical"
        // form={form}
        onFinish={formik.handleSubmit}
        className="modal-form"
      >
        <Form.Item label={t("policy_name")} required={true}>
          <Input
            placeholder={t("default_policy")}
            className="pp-form-input"
            onChange={formik.handleChange}
            name="name"
            value={formik.values.name}
          />
          {formik.touched.name && (
            <div className="errorMsg">{formik.errors.name}</div>
          )}
        </Form.Item>
        <Form.Item label={t("desc")} required={true}>
          <TextArea
            placeholder={t("enter_desc")}
            className="pp-form-input"
            autoSize={{ minRows: 3, maxRows: 5 }}
            onChange={formik.handleChange}
            value={formik.values.description}
            name="description"
          />
          {formik.touched.description && (
            <div className="errorMsg">{formik.errors.description}</div>
          )}
        </Form.Item>
        <div className="dividerText">{t("define_conditions")}</div>
        <Form.Item name="checkboxGroup" style={{ marginBottom: "5px" }}>
          <Collapse
            accordion
            className="customCollapse"
            defaultActiveKey={["1"]}
            expandIconPosition="right"
          >
            <Panel
              header={
                <b>
                  {t("define_users")} ( {t("if_any")} )
                </b>
              }
              key="1"
            >
              <Text>{t("choose_who_will")}</Text>
              <div className="viewPolicyCards">
                1<div className="darkbox">{t("rule_will")}</div>
                <div className="lightbox">
                  <Select
                    placeholder={t("select")}
                    onChange={(val) => {
                      console.log({ val });
                      formik.setFieldValue(
                        `defineCriteria.0.field_name`,
                        val === "employees" ? "created_by" : val
                      );
                      // formik.setFieldValue(`field_name_display`, "Cost Center")
                      formik.setFieldValue(`defineCriteria.0.field_value`, "");
                      set_chan_key(val);
                    }}
                    value={chan_key}
                  >
                    <Option value="employees">{t("specific_user")}</Option>
                    <Option value="designation_id">
                      {t("specific_grade")}
                    </Option>
                    <Option value="cost_center_id">{t("cost_center")}</Option>
                    <Option value="project_id">{t("project")}</Option>
                  </Select>
                </div>
                <div className="lightbox">
                  <Select
                    placeholder={t("select")}
                    onChange={(val) => {
                      formik.setFieldValue(`defineCriteria.0.field_value`, val);
                    }}
                    showSearch
                    filterOption={(input, option) =>
                      (option?.label?.toLowerCase() ?? "").includes(
                        input?.toLowerCase()
                      )
                    }
                    value={formik.values.defineCriteria[0].field_value}
                    options={data?.data?.[key_map[chan_key]]?.map((ele) => ({
                      label: ele.title || ele.name || ele.email,
                      value: ele.id,
                    }))}
                  />
                </div>
              </div>
              <div className="errorMsg">
                {formik.touched.defineCriteria &&
                  formik.errors.defineCriteria?.length > 0 &&
                  `Please select ${key_map[chan_key]} from dropdown.`}
              </div>
            </Panel>
          </Collapse>
        </Form.Item>
        <Collapse accordion className="customCollapse" defaultActiveKey={["3"]} expandIconPosition="right">
          <Panel
            header={
              <b>
                {t("define_the_limit")} ( {t("if_any")} )
              </b>
            }
            key="3"
          >
            <Text>{t("create_limits")}</Text>
            {formik?.values?.defineConditions?.map((ele, i) => {
              return (
                !ele.is_deleted && (
                  <>
                    <div className="viewPolicyCards">
                      {ele.priority}
                      <div className="darkbox">{t("when")}</div>
                      <div className="lightbox">
                        <Select
                          placeholder={t("select")}
                          value={
                            formik.values.defineConditions[i].approval_type
                          }
                          onChange={(val) => {
                            formik.setFieldValue(
                              `defineConditions[${i}].approval_type`,
                              val
                            );
                          }}
                        >
                          <Option value="AMOUNT">{t("Amount")}</Option>
                          {/* <Option value="OTHER">{t('Other')}</Option> */}
                        </Select>
                      </div>
                      <div className="darkbox">{t("for")}</div>
                      {formik.values.defineConditions?.[i]?.approval_type ===
                      "AMOUNT" ? (
                        <div className="lightbox">
                          <Select
                            placeholder="Select "
                            onChange={(val) => {
                              formik.setFieldValue(
                                `defineConditions[${i}].approval_type_condition`,
                                val
                              );
                            }}
                            value={
                              formik.values.defineConditions[i]
                                .approval_type_condition
                            }
                          >
                            {!formik.values.defineConditions?.find(
                              (ele) =>
                                !ele.is_deleted &&
                                ele.approval_type_condition === "DAILY"
                            ) && <Option value="DAILY">{t("DAILY")}</Option>}
                            {!formik.values.defineConditions?.find(
                              (ele) =>
                                !ele.is_deleted &&
                                ele.approval_type_condition === "MONTHLY"
                            ) && (
                              <Option value="MONTHLY">{t("MONTHLY")}</Option>
                            )}
                            {!formik.values.defineConditions?.find(
                              (ele) =>
                                !ele.is_deleted &&
                                ele.approval_type_condition === "YEARLY"
                            ) && <Option value="YEARLY">{t("YEARLY")}</Option>}
                          </Select>
                        </div>
                      ) : null}
                    </div>
                    {formik.values.defineConditions?.[i]?.approval_type ===
                      "AMOUNT" && (
                      <div className="viewPolicyCards">
                        <div className="darkbox">{t("Amount")}</div>
                        <div className="lightbox">
                          <Input
                            name="amount"
                            onChange={(e) => {
                              if (Number.isNaN(Number(e.target.value))) {
                                return;
                              }
                              formik.setFieldValue(
                                `defineConditions[${i}].approval_type_value`,
                                e.target.value
                              );
                            }}
                            value={
                              formik.values.defineConditions?.[i]
                                ?.approval_type_value
                            }
                          />
                        </div>
                        {i > 0 && (
                          <MinusCircleOutlined
                            className="ml3 cursor"
                            onClick={() => {
                              let conditions = formik.values.defineConditions;
                              if (conditions[i].id) {
                                conditions[i].is_deleted = true;
                              } else {
                                conditions.splice(i, 1);
                              }
                              conditions
                                ?.filter((ele) => !ele.is_deleted)
                                ?.forEach((ele, i) => {
                                  ele.priority = i + 1;
                                });
                              formik.setFieldValue(
                                "defineConditions",
                                conditions
                              );
                            }}
                          />
                        )}
                      </div>
                    )}
                  </>
                )
              );
            })}
            {formik.values.defineConditions?.filter((ele) => !ele.is_deleted)
              ?.length <= 2 && (
              <Button
                type="default"
                ghost
                className="approve-add-btn mt1"
                onClick={() => {
                  formik.setFieldValue(`defineConditions`, [
                    ...formik.values.defineConditions,
                    {
                      priority:
                        formik.values.defineConditions?.filter(
                          (ele) => !ele.is_deleted
                        )?.length + 1,
                      notes: "",
                      approval_type: "AMOUNT",
                      approval_type_value: "",
                      approval_type_condition: "",
                    },
                  ]);
                }}
              >
                {t("add_one_more")}
              </Button>
            )}
            <div className="errorMsg">
              {formik.touched.defineConditions &&
                formik.errors.defineConditions?.length > 0 &&
                t("payment_should_Empty")}
            </div>
          </Panel>
        </Collapse>
        <Collapse
          accordion
          className="customCollapse noFloat"
          defaultActiveKey={["4"]}
          expandIconPosition="right"
        >
          <Panel header={<b>{t("actions")}</b>} key="4">
            {/* <Text style={{ marginBottom: 10, display: "inline-block" }}>
              {t('select_actions')}
            </Text> */}
            <Checkbox
              name="policy_action"
              disabled={true}
              checked={formik.values.policy_action === "BLOCKING"}
              onChange={(e) => {
                if (e.target.checked) {
                  formik.setFieldValue("policy_action", "BLOCKING");
                } else {
                  formik.setFieldValue("policy_action", "WARNING");
                }
              }}
            >
              {t("Block Transaction")}
            </Checkbox>
          </Panel>
          <div className="errorMsg">
            {formik.touched.policy_action && formik.errors.policy_action}
          </div>
        </Collapse>

        <Button
          key="1"
          htmlType="submit"
          className="formButton mt3 primaryButton"
          disabled={loading || updateLoading}
        >
          {t("save")}
        </Button>
        <Button
          key="2"
          className="cancelButton ml1 mt3"
          onClick={() => {
            formik.resetForm();
            handleCancelButton();
          }}
        >
          {t("cancel")}
        </Button>
      </Form>
    </>
  );
};

export default AddTransfer;
