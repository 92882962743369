import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { headerTitles } from "../../../actions/masterdata/masterdata";
import { CostCenter, Projects } from "../../../assets/icons/Modules";
import { hasAccess } from "../../../utils";
import ModulesSubMenu from "./ModulesSubMenu";

const CustomModules = ({}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const GeneralDataColumn = [
    {
      title: t("projects"),
      description: t("project_related"),
      to: "/settings/customisation/projects",
      hasAccess: hasAccess("projects", "read"),
      moduleIcon: (
        <img
          src={Projects}
          alt="Projects Icon"
          style={{ width: "1.4rem", height: "1.4rem" }}
        />
      ),
    },
    {
      title: t("cost_center"),
      description: t("org_cost"),
      to: "/settings/customisation/cost_center",
      hasAccess: hasAccess("cost_center", "read"),
      moduleIcon: (
        <img
          src={CostCenter}
          alt="CostCenter Icon"
          style={{ width: "1.4rem", height: "1.4rem" }}
        />
      ),
    },
  ];

  dispatch(
    headerTitles({
      title: "modules",
      description: "",
    })
  );
  return (
    <>
      <div className="custom_module">
        <div className="container_module">
          <div className="module_header">
            <div className="left_header">{t("module_name")}</div>
            <div className="right_header">{t("desc")}</div>
          </div>
          <div className="module_content">
            <ModulesSubMenu
              submenuheader={t("general")}
              submenuColumn={GeneralDataColumn}
              hasAccess={
                hasAccess("projects", "read") ||
                hasAccess("cost_center", "read")
              }
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomModules;
