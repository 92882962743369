import React, { useState } from 'react'
import { useTranslation } from "react-i18next";
import message_icon from "../../assets/icons/message_icon.svg"
import collapse_message_icon from "../../assets/icons/collapse_message_icon.svg"
import { Button, Collapse, Modal, Table, Tabs, Dropdown, Menu } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
import { json2csv } from "json-2-csv"
import { saveAs } from "file-saver"
import { EyeOutlined, DownloadOutlined } from '@ant-design/icons';
import Loader from '../Loader';
const UploadPreview = ({ previewRes, previewCol, type, fileContent, getSummaryResponse }) => {
  const { t } = useTranslation()
  const { Panel } = Collapse;
  const [openPreview, setOpenPreview] = useState(false)
  const downloadCsv = async (value, fileName) => {
    let downloadData = value.map(({ errors, ...rest }) => rest)
    let downloadHeader = [{}];
    if (downloadData?.length == 0) {
      fileContent?.rows[0]?.forEach(ele => {
        downloadHeader[0][ele] = "";
      });
      downloadData = downloadHeader;
    }
    json2csv(downloadData).then((csv) => {
      var blob = new Blob([csv], { type: "text/csv;charset=utf-8" });
      saveAs(blob, `${type}_${fileName}.csv`);
    });
  }

  const downloadCsvData = (key) => {
    switch (key) {
      case "1":
        downloadCsv([...previewRes?.skipped_row_data, ...previewRes?.ready_to_import_row_data], "all_data")
        break;
      case "2":
        downloadCsv(previewRes?.ready_to_import_row_data, "success_data")
        break;
      case "3":
        downloadCsv(previewRes?.skipped_row_data, "failed_data")
    }
  }

  return (
    <>
      {
        getSummaryResponse?.loading ? <Loader /> : (null)
      }
      <div className='preview_modal_container'>
        <Modal
          title={t('Preview Data')}
          visible={openPreview}
          onCancel={() => {
            setOpenPreview(false)
          }}
          // className={"center-aligned-modal"}
          footer={null}
          width="80%"
          centered

        >
          <>

            <Tabs defaultActiveKey="1">
              <Tabs.TabPane tab="All Data" key="1">
                {
                  <div className='preview_table overview'>
                    <Table columns={previewCol} dataSource={previewRes ? [...previewRes?.skipped_row_data, ...previewRes?.ready_to_import_row_data, ...previewRes?.records_to_create_row_data] : []} bordered pagination={false} />
                  </div>
                }
              </Tabs.TabPane>
              <Tabs.TabPane tab="Success Data" key="2">
                <div className='preview_table overview'>
                  <Table columns={previewCol} dataSource={previewRes ? [...previewRes?.ready_to_import_row_data, ...previewRes?.records_to_create_row_data] : []} bordered pagination={false} />
                </div>
              </Tabs.TabPane>
              <Tabs.TabPane tab="Failed Data" key="3">
                <div className='preview_table overview'>
                  <Table columns={previewCol} dataSource={previewRes?.skipped_row_data ? previewRes?.skipped_row_data : []} bordered pagination={false} />
                </div>
              </Tabs.TabPane>
            </Tabs>
          </>
        </Modal>
      </div>
      <div className='upload_preview_section flex-center'>
        <div className='upload_preview_container w60'>
          {
            previewRes?.ready_to_import >= 1 ? (
              <div className='row_message import'>
                <span>{`${previewRes?.ready_to_import} ${t('number_of_the')}`}</span>
              </div>
            ) : (
              <div className='row_message'>
                <img src={message_icon} alt='message icon' />
                <span>{t('none_of_the')}</span>
              </div>
            )
          }
          <div>
            <Collapse className='collapse_section' defaultActiveKey={['1']} ghost expandIconPosition={"end"} expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}>
              <Panel className='border_bottom' header={<div>
                <img src={collapse_message_icon} alt='message icon' />
                <span className='collapse_header_text'>{`No. of records skipped - ${previewRes?.skipped_records}`}</span>
              </div>} key="1">
                <div className='panel_container'>
                  {
                    previewRes?.errors?.map((item, index) => {
                      return (
                        <>
                          <div className='list_container d-flex'>
                            <div className=''>
                              <span>{index + 1}</span>
                            </div>
                            <div className='w35'>
                              <span>
                                {`row : ${item?.row} CSV Column Header : ${item?.csv_column_name} Mapped Header : ${item?.entity_column_label}`}
                              </span>
                            </div>
                            <div className='w50'>
                              <span>
                                {item?.message}
                              </span>
                            </div>

                          </div>
                        </>
                      )
                    })
                  }
                </div>
              </Panel>
              <Panel header={<div>
                <img src={collapse_message_icon} alt='message icon' />
                <span className='collapse_header_text'>{`Unmapped fields - ${previewRes?.unmapped_fields ? previewRes?.unmapped_fields?.length : 0}`}</span>
              </div>} key="2">
                <div className='panel_container'>
                  <p className='panel_text'>The following fields in your import file have not been mapped to any predefined field. The data in these fields will be ignored during the import.</p>
                  <div className='panel_list pl3'>{
                    previewRes?.unmapped_fields?.map(item => {
                      return (
                        <div className='list_key' key={item}>
                          <span className='list_text'>{item}</span>
                        </div>
                      )
                    })
                  }</div>
                </div>
              </Panel>
            </Collapse>
          </div>
          <div className='download flex-center gap1 mt2'>
            <button className='preview_btn' onClick={() => {
              setOpenPreview(true)
            }}><EyeOutlined /> Preview</button>
            <Dropdown
              className='download_btn'
              overlay={
                <Menu onClick={(e) => downloadCsvData(e?.key)}>
                  <Menu.Item key="1">All Data</Menu.Item>
                  <Menu.Item key="2">Success Data</Menu.Item>
                  <Menu.Item key="3">Failed Data</Menu.Item>
                </Menu>
              }
              placement="bottomLeft"
            >
              <button className='download_btn'><DownloadOutlined /> Download</button>
            </Dropdown>
          </div>
        </div>
      </div>
    </>
  )
}

export default UploadPreview