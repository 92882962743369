import { message, Modal, Upload } from "antd";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { apis } from "../../../config/APIs";
import { gridApis } from "../../../config/GridApis";
import usePayAxios from "../../../config/useAxios";
import useDocumentAxios from "../../../config/useDocument";
import { getFileExtension } from "../../../utils";
import OTPComponent from "../../Common/OTPComponent";
import { FormInput, FormSelect } from "../../inputs";
import ButtonUpload from "../../inputs/ButtonUpload";
import rclose from "./assets/close.png";
import pdfIcon from "../../../assets/icons/pdf_file_icon.png";
import eye from "./assets/eye.png";
import { app_loading } from "../../../actions/auth";

const AdditionalInfo = ({
  next,
  back,
  mainData,
  dbAcc,
  crAcc,
  setMainData,
}) => {
  const { t } = useTranslation();
  const [listFiles, setListFiles] = useState([]);

  const { onCall: gen_otp } = usePayAxios({
    api: apis.gen_otp,
    method: "post",
  });

  const { data: hierarchy, onCall: getOptions } = usePayAxios({
    api: apis.hierarchyOptions,
    method: "get",
  });
  const purposes = useSelector(
    (state) =>
      state?.z_accs?.purposes?.data?.data?.responseData?.map((ele) => ({
        label: ele.description,
        value: ele.id,
      })) ?? []
  );

  const [otp, setOtp] = useState({
    otpSent: false,
    otp: "",
  });
  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm({
    defaultValues: {
      notes: "",
      currency_code: "USD",
      due_date: "2024-09-26",
      enable_card_payments: false,
      documents: [],
      mail_to_counterparty: false,
    },
  });

  const { onCall: verifyOtp, loading: verifyLoading } = usePayAxios({
    api: apis.payment_verify_otp,
    method: "post",
  });

  const {
    onCall: DocumentCreate,
    reset: DocumentCreateReset,
    loading: createLoading,
  } = useDocumentAxios({
    api: apis.createDocument,
    method: "post",
    baseURL: "document",
  });
  const dispatch = useDispatch();
  const getBase64 = async (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const { onCall, loading } = usePayAxios({
    type: "grid",
    api: gridApis.createPaymentRequest,
    method: "post",
  });

  const { onCall: DocumentDelete } = useDocumentAxios({
    api: apis.deleteDocument,
    method: "delete",
    baseURL: "document",
  });

  const onSubmit = (data, event) => {
    const callSave = () => {
      dispatch(app_loading(true));

      const payload = {
        amount: Number(mainData?.amount),
        currency_code: crAcc?.currency_code,
        sender_account_id: dbAcc?.id,
        beneficiary_id: crAcc?.id,
        detail_name: data.notes,
        recipient_amount: Number(mainData?.recipient_amount),
        sender_amount: mainData?.amount ?? 0,
        exchange_fee: mainData?.exchange_fee ?? 0,
        exchange_rate: mainData?.exchange_rate ?? 0,
        purpose_code: mainData?.purpose ?? 1,
        account_mode_type:
          mainData?.method === "WITHIN_BANK" ? "WITHINBANK" : mainData?.method,
        payment_files: data.payment_files,
        transaction_account_fee: mainData?.transaction_account_fee,
      };

      if (mainData.schedule_payment_date) {
        payload.schedule_payment_date =
          mainData?.schedule_payment_date?.format("YYYY-MM-DD");
      }

      onCall({ data: payload })
        .then((res) => {
          dispatch(app_loading(false));
          if (res?.data?.order_id || res?.data?.endToEndId) {
            setMainData((b) => ({
              ...b,
              order_id: res?.data?.order_id,
              endToEndId: res?.data?.endToEndId,
              id: res?.data?.id,
              created_at: res?.data?.created_at,
              paid: true,
            }));
            next();
          } else if (res?.error) {
            message.error(<span className="messageText">{res?.message}</span>);
          }
        })
        .catch((err) => {
          dispatch(app_loading(false));
          if (err?.response?.data?.error) {
            message.error(
              <span className="messageText">
                {err?.response?.data?.message}
              </span>
            );
          }
        });
    };

    if (
      event.nativeEvent?.submitter?.id === "save" &&
      hierarchy?.data?.[0]?.seed_code === "CPS_0"
    ) {
      gen_otp({
        data: {
          user_id: localStorage.getItem("user_id"),
          email_id: localStorage.getItem("user"),
          source: "PAYMENT",
        },
      })
        .then((res) => {
          if (res.error === false) {
            message.success(
              <span className="messageText">{res?.message}</span>
            );
            setOtp({
              ...otp,
              otpSent: true,
              token: res?.data,
            });
            setMainData((b) => ({
              ...b,
              ...data,
            }));
          }
          if (res.error) {
            message.error(<span className="messageText">{res?.message}</span>);
          }
        })
        .catch((err) => {
          message.success(
            <span className="messageText">{err?.response?.data?.message}</span>
          );
        });
    } else {
      if (event.nativeEvent?.submitter?.id === "otp") {
        verifyOtp({
          data: {
            user_id: localStorage.getItem("user_id"),
            email_id: localStorage.getItem("user"),
            source: "PAYMENT",
            otp: otp.otp,
            otp_token: otp.token,
          },
        })
          .then((res) => {
            if (res.error === false) {
              callSave();
            }
          })
          .catch((err) => {
            message.error(
              <span className="messageText">
                {err?.response?.data?.message}
              </span>
            );
          });
      } else callSave();
    }
  };

  const handleFileUpload = async (fieldName, uploadFile) => {
    let uniqueNo = uuidv4();

    const formData = new FormData();
    formData.append("files", uploadFile.file);
    formData.append("name", fieldName);
    formData.append("record_type", "Document");
    formData.append("record_id", uniqueNo);

    const attachId = await DocumentCreate({
      data: formData,
      headers: {
        "Content-Type": "multipart/formdata",
      },
    });
    if (attachId?.error == false) {
      await getBase64(uploadFile.file, (url) => {
        setListFiles((prevData) => {
          const newData = { ...prevData };
          const currentArray = newData[fieldName] || [];

          // Add new item
          currentArray.push({
            fieldName: fieldName,
            attachment_id: attachId?.data[0],
            uuid: uniqueNo,
            url: url,
            uploadFileName: uploadFile?.file?.name,
          });

          newData[fieldName] = currentArray?.filter(
            (items) => items?.attachment_id
          );
          setValue(
            fieldName,
            newData[fieldName]?.map((ele) => ele.attachment_id)
          );
          return newData;
        });
      });
      DocumentCreateReset();
    }
  };

  const handleFileDelete = async (targetKey, targetValue, isRemove = false) => {
    const deleteData = await DocumentDelete({
      data: {
        ids: [targetValue],
      },
      params: {
        record_id: getObjectByFieldNameAndValue(targetKey, targetValue)?.uuid,
      },
      headers: {
        "Content-Type": "multipart/formdata",
      },
    });

    if (deleteData?.error == false) {
      if (!isRemove) {
        setValue(targetKey, "");
      }
      setListFiles((prevData) => {
        return {
          ...prevData,
          [targetKey]: prevData[targetKey]?.filter(
            (ele) => ele.attachment_id !== targetValue
          ),
        };
      });
    }
  };

  const getObjectByFieldNameAndValue = (targetKey, targetValue) => {
    return listFiles[targetKey]?.find(
      (ele) => ele.attachment_id === targetValue
    );
  };

  const handleBeforeUpload = async (file) => {
    const fileName = getFileExtension(file?.name);
    if (["png", "jpg", "jpeg", "pdf"]?.includes(fileName)) {
    } else if (file.size > 5242880) {
      message.error(
        <span className="messageText">File size must be smaller than 5 MB</span>
      );
      return Upload.LIST_IGNORE;
    } else {
      message.error(
        <span className="messageText">{`Please Upload ${[
          "png",
          "jpg",
          "jpeg",
          "pdf",
        ]?.join(" or ")} files`}</span>
      );
      return Upload.LIST_IGNORE;
    }
    return false;
  };

  useEffect(() => {
    getOptions({
      params: {
        seed_type: "company_payments_hierarchy",
      },
    });
  }, []);

  return (
    <div>
      <form className="w40 ml3" onSubmit={handleSubmit(onSubmit)}>
        {otp.otpSent && (
          <Modal
            visible={otp.otpSent}
            // title="Enter the OTP"
            onCancel={() => {
              setOtp({
                otp: "",
                otpSent: false,
              });
            }}
            destroyOnClose={false}
            maskClosable={false}
            getContainer={false}
          >
            <div className="flex-center flex-col w100">
              <div className="otp_info" style={{ maxWidth: "80%" }}>
                <div>
                  <b
                    className="mt-2 mb-1"
                    style={{
                      fontSize: "1.6rem",
                    }}
                  >
                    Enter the OTP
                  </b>
                </div>
                <span>
                  Check your email!{" "}
                  <span style={{ fontWeight: "800" }}>
                    {localStorage.getItem("user")}
                  </span>{" "}
                  We've sent an OTP to your linked email.
                </span>
              </div>
              <OTPComponent
                source={"PAYMENT"}
                label={"Enter the OTP"}
                hideTitle={true}
                value={otp.otp}
                token={otp?.token}
                userData={otp}
                loading={loading}
                onChange={(otp) => {
                  setOtp((o) => ({
                    ...o,
                    otp,
                  }));
                }}
              />
              <button className="send-btn mt2" id="otp" disabled={loading}>
                Verify OTP
              </button>
            </div>
          </Modal>
        )}
        <>
          <div className="pp-form-item">
            <FormSelect
              inline
              control={control}
              {...register("purpose", {
                required: "Transfer purpose is required",
              })}
              errors={errors}
              label={t("Transfer purpose")}
              options={purposes}
              required
              hideCreateOption={true}
            />
          </div>
          <div className="pp-form-item">
            <FormInput
              control={control}
              required={true}
              {...register("notes", {
                required: "Notes is required.",
              })}
              errors={errors}
              label={t("notes")}
            />
          </div>

          <div className="additional" style={{ marginTop: "40px" }}>
            <ButtonUpload
              control={control}
              {...register(`payment_files`, {
                onChange: (e) => {
                  handleFileUpload("payment_files", e.target.value);
                },
              })}
              beforeUpload={handleBeforeUpload}
              errors={errors}
              label={t("attach_payments")}
              multiple={false}
              loading={createLoading}
              maxSize={5}
            />
            <div className="imgs-wrap">
              {listFiles["payment_files"]?.map((ele) => {
                return (
                  <div
                    style={{
                      position: "relative",
                    }}
                  >
                    <div className="img">
                      <img
                        src={
                          getFileExtension(ele.uploadFileName) === "pdf"
                            ? pdfIcon
                            : ele.url
                        }
                      />
                      <img src={eye} alt="" className="file-eye" />
                    </div>
                    <img
                      src={rclose}
                      alt=""
                      className="file-close"
                      onClick={() => {
                        handleFileDelete("payment_files", ele.attachment_id);
                      }}
                    />
                  </div>
                );
              })}
            </div>
          </div>
          <div className="mt3">
            <button
              type="button"
              className="back-btn mr2"
              onClick={() => back()}
            >
              Back
            </button>
            <button className="send-btn" id="save" disabled={loading}>
              Send Money
            </button>
          </div>
        </>
      </form>
    </div>
  );
};

export default AdditionalInfo;
