import moment from "moment";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { get_all_payments } from "../../actions/z_accounts";
import credit from "../../assets/icons/credit.png";
import debit from "../../assets/icons/debit.png";
import { currencyMapper } from "../../config/helper";
import NoTableData from "../Common/TableComp/NoTableData";
import TableComponent from "../Common/TableComp/TableComp";
import right_arr from "../../assets/icons/rgiht_arr.png";
import { p_types } from "../Payments/SendMoney/Recipients";
import { AccountDisplay } from "../Account/AccountCard";
import { amountFormat } from "../../utils";

const AccTxnTable = ({ account_number, dash, fromDetail }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const data = useSelector(
    (state) => state?.z_accs?.all_payments?.data?.data ?? []
  );

  const loading = useSelector((state) => state?.z_accs?.all_payments?.loading);
  useEffect(() => {
    if (account_number)
      dispatch(
        get_all_payments({
          params: {
            account_id: account_number,
          },
          txns: true,
        })
      );
  }, [account_number]);

  const columns = [
    // {
    //   title: t("txn_id"),
    //   dataIndex: "transaction_ref_no",
    //   key: "transaction_ref_no",
    // },
    {
      title: t("beneficiary_name"),
      dataIndex: "counterparty_account_name",
      key: "counterparty_account_name",
      render: (text) => text,
    },
    fromDetail
      ? {
          title: t("beneficiary_account"),
          dataIndex: "counterparty_account_number",
          key: "counterparty_account_number",
          render: (text) => <AccountDisplay accountNumber={text} showEye />,
        }
      : {},
    {
      title: t("Date and Time"),
      dataIndex: "created_at",
      key: "created_at",
      render: (text) => <div>{moment(text).format("DD, MMM YY, HH:mm")}</div>,
    },
    fromDetail
      ? {
          title: "Payment Method",
          dataIndex: "transfer_mode",
          key: "transfer_mode",
          render: (text) => p_types?.find((ele) => ele.value === text)?.label,
        }
      : {},
    {
      title: t("Amount"),
      dataIndex: "amount",
      key: "amount",
      render: (text, rec) => (
        <div>
          {currencyMapper[rec.currency]}
          {amountFormat(text)}
        </div>
      ),
    },
    {
      title: "Txn Type",
      dataIndex: "transaction_type",
      key: "transaction_type",
      render: (text) => (
        <span
          style={{
            color: "#333",
            textTransform: "capitalize",
          }}
        >
          <img
            style={{
              transform: "rotate(180deg)",
            }}
            src={text === "credit" ? credit : debit}
            width={10}
          />{" "}
          {text}
        </span>
      ),
    },
    // {
    //   title: "Status",
    //   dataIndex: "status",
    //   key: "status",
    //   render: (text) => (
    //     <span className={`status status_${text?.toLowerCase()}`}>{text}</span>
    //   ),
    // },
  ];

  return (
    <>
      {!loading && data?.length === 0 ? (
        <NoTableData title={"No Transactions yet."} />
      ) : (
        <div className="paycraft-table">
          <TableComponent
            loading={loading}
            rowKey="id"
            columns={columns}
            shimmerCount={5}
            hasCheckbox={false}
            data={Array.isArray(data) ? (dash ? data?.slice(0, 5) : data) : []}
            pagination={null}
          />
          {Array.isArray(data) && data?.slice(0, 5)?.length ? (
            <div
              className="flex-end mt1 mb1 mr1"
              style={{
                gap: 7,
                alignItems: "center",
              }}
            >
              <span
                className="cursor"
                style={{
                  opacity: 1,
                  color: "#141414F4",
                  fontWeight: 500,
                }}
                onClick={() => {
                  history.push("/transactions");
                }}
              >
                View More
              </span>
              <img src={right_arr} height={10} />
            </div>
          ) : null}
        </div>
      )}
    </>
  );
};

export default AccTxnTable;
