import React, { useEffect } from "react";

import { Button, message, Modal, Space, Typography } from "antd";
import Text from "antd/lib/typography/Text";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { headerTitles } from "../../../../actions/masterdata/masterdata";
import DeleteIcon from "../../../../assets/icons/delete.png";
import EditIcon from "../../../../assets/icons/edit.png";
import { apis } from "../../../../config/APIs";
import usePayAxios from "../../../../config/useAxios";
import TableComponent from "../../../Common/TableComp/TableComp";
import "./../styles/policy.css";
import AddTransfer from "./AddTransfer";
const { Title } = Typography;

const Transfer = ({ hasWriteAccess }) => {
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [selectedId, setSelectedId] = React.useState("");
  const [modalType, setIsModalType] = React.useState("center");
  const dispatch = useDispatch();
  const { onCall: getAllPolicies, data } = usePayAxios({
    api: apis.getAllPolicyBudgets,
    method: "get",
  });
  const {
    onCall: getPolicy,
    reset,
    data: policy,
  } = usePayAxios({
    api: apis.getPolicyBudgetByID,
    method: "post",
  });
  const { onCall: deletePolicy } = usePayAxios({
    api: apis.deletePolicyBudget,
    method: "post",
  });
  const selectedRow = policy?.data ?? {};
  const { t } = useTranslation();
  const handleCancel = () => {
    setIsModalVisible(false);
    reset();
  };

  const showDeleteModal = (id) => {
    setSelectedId(id);
    setIsModalVisible(true);
    setIsModalType("center");
  };

  const renderDeleteOption = () => {
    return (
      <>
        <Text>{t("delete_sure_field")}</Text>
        <br />
        <Button
          key="1"
          className="formButton mt1"
          onClick={() =>
            deletePolicy({
              data: {
                id: selectedId,
              },
            })
              .then((res) => {
                if (res.error === false) {
                  getAllPolicies({
                    params: {
                      page_number: 1,
                    },
                  });
                  message.success(
                    <span className="messageText">
                      {"Payments policy deleted successfully."}
                    </span>
                  );
                  setIsModalVisible(false);
                  setSelectedId("");
                } else {
                  message.error(
                    <span className="messageText">{res.message}</span>
                  );
                }
              })
              .catch((err) => {
                message.error(
                  <span className="messageText">
                    {err?.response?.data?.message}
                  </span>
                );
              })
          }
        >
          {t("delete")}
        </Button>
        <Button
          key="2"
          className="cancelButton ml1 mt1"
          onClick={() => handleCancel()}
        >
          {t("cancel")}
        </Button>
      </>
    );
  };

  function handleButtonClick(e) {
    setSelectedId("");
    setIsModalVisible(true);
    setIsModalType("right");
  }

  const columns = [
    {
      title: t("name"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("desc"),
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Actions",
      key: "action",
      render: (text, record) => (
        <Space key={record.id} size="middle">
          <button
            disabled={!hasWriteAccess}
            // onClick={() => showEditModal(record.id)}
          >
            <img
              src={EditIcon}
              alt={"logo"}
              className="action-logo"
              onClick={() => {
                getPolicy({
                  data: { id: record.id },
                });
                setIsModalVisible(true);
                setIsModalType("right");
              }}
            />
          </button>
          {record.key !== "1" ? (
            <button
              disabled={!hasWriteAccess}
              // onClick={() => showDeleteModal(record.id)}
            >
              <img
                src={DeleteIcon}
                alt={"logo"}
                className="action-logo"
                onClick={() => {
                  {
                    showDeleteModal(record.id);
                  }
                  // deletePolicy({
                  //   data: {
                  //     id: record.id
                  //   }
                  // }).then(res => {
                  //   if (res.error === false) {
                  //     getAllPolicies({
                  //       params: {
                  //         page_number: 1
                  //       }
                  //     })
                  //     message.success(
                  //       <span className="messageText">{"Payments policy deleted successfully."}</span>)
                  //   } else {
                  //     message.error(<span className="messageText">{res.message}</span>)
                  //   }
                  // }).catch(err => {
                  //   message.error(<span className="messageText">{err?.response?.data?.message}</span>)
                  // })
                }}
              />
            </button>
          ) : null}
        </Space>
      ),
    },
  ];

  useEffect(() => {
    getAllPolicies({
      params: {
        page_number: 1,
      },
    });

    dispatch(
      headerTitles({
        title: "polocies_limits",
        description: "",
      })
    );
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Modal
        title={
          modalType === "center" ? (
            <>
              <img src={DeleteIcon} alt={"logo"} className="action-logo" />
              {t("delete_field")}
            </>
          ) : (
            <Title level={4}>
              {selectedRow?.id ? t("edit") : t("addNew")} {t("pay_policy")}{" "}
              &nbsp;<span className="text-small">{t("enter_details")}</span>
            </Title>
          )
        }
        visible={isModalVisible && hasWriteAccess}
        onCancel={handleCancel}
        footer={[]}
        className={
          modalType === "center"
            ? "center-aligned-modal"
            : "right-alinged-modal lg"
        }
      >
        {modalType === "center"
          ? renderDeleteOption()
          : isModalVisible && (
              <AddTransfer
                handleCancel={handleCancel}
                selectedRow={selectedRow}
                getAll={() => {
                  getAllPolicies({
                    params: {
                      page_number: 1,
                    },
                  });
                }}
              />
            )}
      </Modal>
      <div className="">
        <button
          disabled={!hasWriteAccess}
          onClick={handleButtonClick}
          className="mb1 addNew send-btn"
        >
          {t("add_new_policy")}
        </button>

        <TableComponent
          rowKey="id"
          columns={columns}
          data={Array.isArray(data?.data) ? data?.data : []}
          hasCheckbox={false}
          className="settingsTable"
          pagination={{
            onChange: (page) => {
              getAllPolicies({
                params: {
                  page_number: page,
                },
              });
            },
            total: data?.total_count,
          }}
        />
      </div>
    </>
  );
};

export default Transfer;
