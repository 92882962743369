import { useState } from "react";
import endpointDocumentApi from "../config/Axios";

const useDocumentAxios = ({ api, method }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const reset = () => {
    setData(null);
    setLoading(false);
  };

  const reqCall = () => {
    return endpointDocumentApi;
  };
  const onCall = async ({ data, headers, params }) => {
    setLoading(true);
    try {
      let result;
      if (method === "get")
        result = await reqCall()[method](api, { headers, params });
      else if (method === "delete") {
        let deleteAPI = "";
        if (params?.record_id) {
          deleteAPI = api + `?record_id=${params?.record_id}`;
        } else {
          deleteAPI = api;
        }
        result = await reqCall()[method](deleteAPI, {
          data,
        });
      } else
        result = await reqCall()[method](api, data, { headers, params });
      setLoading(false);
      setData(result.data);
      return Promise.resolve(result.data);
    } catch (err) {
      setLoading(false);
      setData(err);
      return Promise.reject(err);
    }
  };
  return {
    loading,
    data,
    reset,
    onCall,
  };
};
export default useDocumentAxios;
