import { Col, Row } from "antd";
import React from "react";
import { Route, Switch } from "react-router-dom";
import { hasAccess } from "../../utils";
import CostCenter from "./Customisation/CostCenter/CostCenter";
import Projects from "./Customisation/Projects/Projects";
import Organisation from "./Organisation";
import Roles from "./UsersControls/Roles/Roles";
import Users from "./UsersControls/Users";
import AddUsers from "./UsersControls/Users/AddUser";
import "./styles/setting.css";
// import Reimbursements from "./Customisation/Reimbursements/Reimbursements";
import Alerts from "./Alerts";
import ApprovalBills from "./Approvals/AprrovalsBills";
import Currencies from "./Currencies/Currencies";
import CustomModules from "./Customisation/CustomModules";
import HasPermissions from "./HasPermissions";
import Transfer from "./PoliciesLimits/Transfers/Transfer";
import Security from "./Security";

const Settings = () => {

  return (
    <Row>
      {/* <Col flex="256px">{renderSettingMenu()}</Col> */}
      <Col flex="1 1">
        <div className="content-div">
          <Switch>
            <Route
              path="/settings"
              exact
              component={() => (
                <HasPermissions
                  role={"organisation_settings"}
                  name="Organisation Profile"
                >
                  <Organisation />
                </HasPermissions>
              )}
            />
            <Route
              exact
              path="/settings/profile/"
              component={() => (
                <HasPermissions
                  role={"organisation_settings"}
                  name="Organisation Profile"
                >
                  <Organisation
                    hasWriteAccess={hasAccess("organisation_settings", "write")}
                  />
                </HasPermissions>
              )}
            />
            {/* Rootfi Routes */}
          
            <Route
              path="/settings/currencies/"
              component={() => (
                <Currencies hasWriteAccess={hasAccess("currencies", "write")} />
              )}
            />
          
            <Route
              path="/settings/users/"
              exact
              component={() => (
                <Users hasWriteAccess={hasAccess("users", "write")} />
              )}
            />
            <Route
              path="/settings/users/add_user/"
              component={() => <AddUsers />}
            />
            <Route
              path="/settings/roles/"
              component={() => (
                <Roles
                  hasWriteAccess={hasAccess("roles_and_permissions", "write")}
                />
              )}
            />
           
           
            <Route
              path="/settings/policies/transfers/"
              component={() => (
                <Transfer
                  hasWriteAccess={hasAccess("payments_policy", "write")}
                />
              )}
            />
          

           
            <Route
              path="/settings/alerts/"
              exact
              component={() => <Alerts />}
            />
            <Route
              path="/settings/security/"
              exact
              component={() => <Security />}
            />
            <Route
              path="/settings/customisation/projects/"
              component={() => (
                <Projects hasWriteAccess={hasAccess("projects", "write")} />
              )}
            />
            <Route
              path="/settings/customisation/cost_center/"
              component={() => (
                <CostCenter
                  hasWriteAccess={hasAccess("cost_center", "write")}
                />
              )}
            />
         
            <Route
              path="/settings/approvals/company_payments"
              component={() => (
                <ApprovalBills
                  type={"PAYMENT_APPROVAL"}
                  inv_type={"COMPANY_PAYMENTS"}
                  hasWriteAccess={hasAccess(
                    "payment_approvals_settings",
                    "write"
                  )}
                />
              )}
            />
            <Route
              path="/settings/approvals/company_bulk_payments"
              component={() => (
                <ApprovalBills
                  type={"BULK_PAYMENT_APPROVAL"}
                  inv_type={"COMPANY_BULK_PAYMENTS"}
                  hasWriteAccess={hasAccess(
                    "bulk_payment_approvals_settings",
                    "write"
                  )}
                />
              )}
            />
            <Route
              path="/settings/customisation/custom_modules"
              component={() => (
                <CustomModules
                  type={"custommodules"}
                  hasWriteAccess={hasAccess("module_customisations", "write")}
                />
              )}
            />
           
          </Switch>
        </div>
      </Col>
    </Row>
  );
};

export default Settings;
