import { call, put, takeEvery } from "redux-saga/effects";
import { apis } from "../../config/APIs";
import endpoint from "../../config/Axios";
import { sagaFunctions } from "../../utils";
import { Dashboard, InvoiceDashboard, PayDashboard } from "./dashboard";

function* dashboard(req) {
  let res = null;

  try {
    res = yield call(
      endpoint["get"],
      req.payload?.dashboard === "4" ? apis.spendAnz : apis.dashboard,
      {
        params: req.payload,
      }
    );

    if (res.status < 400) {
      if (req && req.payload)
        yield put({
          type: Dashboard.SUCCESS,
          payload: res.data,
          reqPayload: req.payload,
        });
      else
        yield put({
          type: Dashboard.SUCCESS,
          payload: res.data,
        });
    } else {
      yield put({
        type: Dashboard.FAILED,
        payload: res.data,
      });
    }
  } catch (err) {
    yield put({
      type: Dashboard.FAILED,
      payload: err?.response?.data,
    });
  }
}

function payDashboard(req) {
  return sagaFunctions(
    PayDashboard,
    "get",
    apis.dashboardBills,
    req.payload,
    {},
    true
  )();
}

function invoiceDashboard(req) {
  return sagaFunctions(
    InvoiceDashboard,
    "get",
    apis.dashboardInvoices,
    req.payload,
    {},
    true
  )();
}

export function* dashboardWatcher() {
  yield takeEvery(Dashboard.REQUEST, dashboard);
  yield takeEvery(PayDashboard.REQUEST, payDashboard);
  yield takeEvery(InvoiceDashboard.REQUEST, invoiceDashboard);
}
