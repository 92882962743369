import { Col, message, Row } from "antd";
import moment from "moment";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { headerTitles, SideMenu } from "../../../actions/masterdata/masterdata";
import { fetchBulkUploads } from "../../../actions/transfers/transfers";
import { apis } from "../../../config/APIs";
import usePayAxios from "../../../config/useAxios";
import { amountFormat, hasAccess } from "../../../utils";
import BaseCurrency from "../../Common/BaseCurrency";
import TableComponent from "../../Common/TableComp/TableComp";
import TableHeader from "../../Sales/TableHeader";
import UploadPayments from "./UploadPayments";
const BulkHistory = ({ type, user }) => {
  const { onCall } = usePayAxios({
    api: apis.downloadBulkUploads,
    method: "get",
  });
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const [page, setPage] = React.useState(1);
  const [search, setSearch] = React.useState("");
  const [openAddNew, setOpenAddNew] = React.useState(false);
  const { t } = useTranslation();
  const bulkResponse = useSelector(
    (state) => state?.transfers?.fetch_bulk_uploads?.data?.data
  );
  const bulkResponseLoading = useSelector(
    (state) => state?.transfers?.fetch_bulk_uploads?.loading
  );
  const totalRecords = useSelector(
    (state) => state?.transfers?.fetch_bulk_uploads?.data?.total_count
  );

  useEffect(() => {
    dispatch(
      headerTitles({
        title: user ? "bulk_payments" : "approvals",
        description: "access_transfer",
      })
    );
    if (type === "bulk_payment_history") {
      dispatch(fetchBulkUploads({ type: type }));
    } else {
      dispatch(
        fetchBulkUploads({
          type: type,
          params: {
            module_type: "COMPANY_BULK_PAYMENTS",
            view: user ? "" : "admin",
          },
        })
      );
    }
    if (location?.state?.setSideMenu) {
      let locationSplit = location?.pathname?.split("/");
      dispatch({
        type: SideMenu,
        payload: {
          firstNav: "/" + locationSplit?.[1],
          secondNav: "/" + locationSplit?.[2],
          showChildren: true,
          childrenMenu: locationSplit?.[3] ? "/" + locationSplit?.[3] : "",
        },
      });
    }
  }, [location?.pathname]);

  const handleSort = () => {};

  const sortOptions = [];
  const handleDownloadBulk = (STATUS, ID) => {
    onCall({
      params: {
        status: STATUS,
        id: ID,
        module_type: "COMPANY_BULK_PAYMENTS",
        view: location?.state?.user ? "" : "admin",
      },
    })
      .then((data) => {
        message.success(<span className="messageText">{"file Download"}</span>);
      })
      .catch((err) => {
        message.error(<span className="messageText">{err.message}</span>);
      });
  };
  // const handleDownloadBulkAll = (ID) => {
  //   onCall({
  //     params: {
  //       id: ID,
  //     },
  //   })
  //     .then((data) => {
  //       message.success(<span className="messageText">{"file Download"}</span>);
  //     })
  //     .catch((err) => {
  //       message.error(<span className="messageText">{err.message}</span>);
  //     });
  // };
  const handleSelectRow = (event, fileId) => {
    if (type) {
      history.push("/transfers/bulkpayment/detailpage", {
        fileId: fileId.id,
      });
    } else {
      history.push("/transfers/bulk/detailpage", {
        fileId: fileId.id,
        user,
        bulk: true,
        pathname: location.pathname,
      });
    }
  };

  const columnsTransfers = [
    {
      title: t("employee_name"),
      dataIndex: "name",
    },
    {
      title: t("from_account"),
      dataIndex: "debit_account_type",
    },
    {
      title: t("file_name"),
      dataIndex: "file_name",
    },
    {
      title: t("batch_name"),
      dataIndex: "batch_name",
      render: (text) => <>{text}</>,
    },
    {
      title: t("count"),
      dataIndex: "total_count",
    },
    {
      title: t("schedule_date"),
      dataIndex: "schedule_payment_date",
      render: (text) => (
        <div>{moment.utc(text).format("DD MMM YY, HH:mm")}</div>
      ),
    },
    {
      title: t("status"),
      dataIndex: "status",
      render: (text, record) => (
        <div
          className={`status status_${
            user ? record?.flow_status_master : text
          }`}
        >
          {user
            ? record?.flow_status_master?.replace?.(/_/g, " ")
            : text?.replace?.(/_/g, " ")}
        </div>
      ),
    },
    // {
    //   title: "",
    //   key: "action",
    //   render: (_, record) => (
    //     <Space size="middle" className="myspace">
    //       <Button
    //         disabled={!hasWriteAccess}
    //         shape="default"
    //         icon={
    //           <img
    //             src={UploadFiles}
    //             alt="Download All"
    //             style={{ width: "1.5rem" }}
    //           />
    //         }
    //         style={{ border: "none", color: "green" }}
    //         onClick={() => handleDownloadBulk("success", record?.id)}
    //       />
    //       <Button
    //         disabled={!hasWriteAccess}
    //         shape="default"
    //         icon={
    //           <img
    //             src={FailFiles}
    //             alt="Download All"
    //             style={{ width: "1.5rem" }}
    //           />
    //         }
    //         style={{ border: "none", color: "red" }}
    //         onClick={() => handleDownloadBulk("failed", record?.id)}
    //       />
    //       <Button
    //         disabled={!hasWriteAccess}
    //         shape="default"
    //         icon={
    //           <img
    //             src={AllFiles}
    //             alt="Download All"
    //             style={{ width: "1.2rem" }}
    //           />
    //         }
    //         style={{ border: "none", color: "black" }}
    //         onClick={() => handleDownloadBulkAll(record?.id)}
    //       />
    //     </Space>
    //   ),
    // },
  ];
  const columnsPayments = [
    {
      title: t("file_name"),
      dataIndex: "file_name",
    },
    {
      title: t("count"),
      dataIndex: "total_count",
    },
    {
      title: t("dateAndTime"),
      dataIndex: "updated_at",
      render: (text) => <div>{moment(text).format("D MMM YY h:mm a")}</div>,
    },
    {
      title: t("Amount"),
      dataIndex: "total_amount",
      render: (text, rec) => (
        <div>
          <BaseCurrency symbol_code={rec.currency} /> {amountFormat(text)}
        </div>
      ),
    },
  ];

  const handleSearch = (search_key) => {
    if (type === "bulk_payment_history") {
      dispatch(fetchBulkUploads({ type, search_key }));
    } else {
      dispatch(
        fetchBulkUploads({
          type,
          search_key,
          params: {
            module_type: "COMPANY_BULK_PAYMENTS",
            view: user ? "" : "admin",
          },
        })
      );
    }
    setSearch(search_key);
  };
  return (
    <>
      <Row wrap={false} className="table-wrapper">
        <Col flex="auto" className="table-wrapper-col">
          {openAddNew ? (
            <UploadPayments
              // type={"bulk_payment"}
              hasWriteAccess={hasAccess("bulk_upload_payment_links", "write")}
              setOpenAddNew={setOpenAddNew}
              cb={() => {
                if (type === "bulk_payment_history") {
                  dispatch(fetchBulkUploads({ type: type }));
                } else {
                  dispatch(
                    fetchBulkUploads({
                      type: type,
                      params: {
                        module_type: "COMPANY_BULK_PAYMENTS",
                        view: user ? "" : "admin",
                      },
                    })
                  );
                }
              }}
            />
          ) : (
            <div className="flex-grow">
              <TableHeader
                noDD={true}
                noFilter={true}
                noSort={true}
                hasWriteAccess={true}
                //   filterMenu={filterOptions}
                //   handleFilter={handleFilter}
                setOpenAddNew={setOpenAddNew}
                noAddNewBtn={!user}
                sortOptions={sortOptions}
                handleSort={handleSort}
                onSearch={handleSearch}
                //   menu={menu}
              />
              <div
                className={
                  bulkResponseLoading ? "shimmer pay-table" : "pay-table"
                }
                style={{ paddingTop: "22px", overflowX: "auto" }}
              >
                <TableComponent
                  columns={type ? columnsPayments : columnsTransfers}
                  loading={bulkResponseLoading}
                  data={Array.isArray(bulkResponse) ? bulkResponse : []}
                  hasCheckbox={false}
                  // selectedRows={selectedRows}
                  // setSelectedRows={setSelectedRows}
                  scroll={{ x: 2000 }}
                  handleRowClick={handleSelectRow}
                  rowKey="id"
                  pagination={{
                    total: totalRecords || 0,
                    current: page,
                    onChange: (pagee) => {
                      if (type === "bulk_payment_history") {
                        dispatch(
                          fetchBulkUploads({
                            type: type,
                            page_number: pagee,
                            search_key: search,
                          })
                        );
                      } else {
                        dispatch(
                          fetchBulkUploads({
                            type: type,
                            search_key: search,
                            params: {
                              module_type: "COMPANY_BULK_PAYMENTS",
                              view: user ? "" : "admin",
                              page_number: pagee,
                            },
                          })
                        );
                      }

                      setPage(pagee);
                    },
                  }}
                />
              </div>
            </div>
          )}
        </Col>
      </Row>
    </>
  );
};
export default BulkHistory;
