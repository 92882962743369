import { Col, Input, Row, Tabs, message } from "antd";
import { saveAs } from "file-saver";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { UploadBulk, downloadBulk, uploadBulk } from "../../actions/cards/cards";
import { headerTitles } from "../../actions/masterdata/masterdata";
import Close from "../../assets/icons/close.png";
import excel from "../../assets/icons/excel.png";
import file_download from "../../assets/icons/file_download.png";
import upload from "../../assets/icons/upload.png";
import "./transfer.css";

const { TabPane } = Tabs;
const { TextArea } = Input;

const BulkTransfers = ({ hasWriteAccess }) => {
  const [file, setFile] = React.useState(null);
  const [remarks, setRemarks] = React.useState("");
  const [fileData, setFileData] = React.useState(null);
  const dispatch = useDispatch();
  const { t } = useTranslation()
  const downloadLink = useSelector(
    (state) => state?.cards?.downloadBulk?.data?.response?.topup_template_link
  );

  const uploadData = useSelector((state) => state?.cards?.uploadBulk);
  useEffect(() => {
    if (uploadData?.success) {
      setFile(null);
      setRemarks("");
      setFileData(uploadData?.data?.response);

      let timeout = setTimeout(() => {
        setFileData(null);
        clearTimeout(timeout);
        dispatch({
          type: UploadBulk.RESET
        })
      }, 5000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadData]);
  useEffect(() => {
    dispatch(downloadBulk());
    dispatch(headerTitles({
      title: 'bulk_transfer',
      description: ""
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const tabTitle = {
  //   profile: () => {
  //     return (
  //       <>
  //         <div className="tabCircles">1</div>
  //         <div className="tabTitles">
  //           File Upload<p>Choose a file to upload</p>
  //         </div>
  //       </>
  //     );
  //   },
  //   // limits: () => {
  //   //   return (
  //   //     <>
  //   //       <div className="tabCircles">2</div>
  //   //       <div className="tabTitles">
  //   //         Preview and Initiate<p>Preview Uploaded Data</p>
  //   //       </div>
  //   //     </>
  //   //   );
  //   // },
  //   card: () => {
  //     return (
  //       <>
  //         <div className="tabCircles">3</div>
  //         <div className="tabTitles">
  //           Card<p>Assign Prepaid Card</p>
  //         </div>
  //       </>
  //     );
  //   },
  // };
  return (
    <>
      <Tabs type="card" className="setting-tabs">
        <TabPane key="profile">
          {fileData && (
            <div
              className="success-toast mb2"
              onClick={() => {
                setFileData(null);
              }}
            >
              Top of {fileData["Total no of cards"]} cards of Rs{" "}
              {fileData["Total Amount of load"]} {t('status_file')}
            </div>
          )}
          <div>
            <div>
              <div

                className="showcase-box cursor"
                style={file ? { position: "relative", top: "-8px" } : undefined}
                onClick={() => {
                  if (hasWriteAccess) {
                    const csv = atob(downloadLink)
                    var blob = new Blob([csv], { type: "text/csv;charset=utf-8" });
                    saveAs(blob, "sample.csv");
                  }
                }}
              >
                <div className="mt1">
                  <div>
                    <img src={file_download} width={14} alt="upload" />
                  </div>
                  <span className="text-orginal">{t('download_sample_file')}</span>
                  <p>
                    {t('sample_file')}
                  </p>
                </div>
              </div>
              <input
                type={"file"}
                id="file"
                onChange={(e) => {
                  setFileData(null);
                  if (e.target.files[0]?.type === "text/csv")
                    setFile(e.target.files[0]);
                  else
                    message.error(<span className="messageText">Only CSV files are allowed</span>)
                }}
                className="d-none"
              />
              <div
                className="showcase-box ml1 mb2 showcase-box-dashed"
                onClick={() => {
                  document.getElementById("file").click();
                  document.getElementById("file").value = null;
                }}
              >
                {file && file.name ? (
                  <div className="mt1">
                    <span
                      className="cursor"
                      onClick={(e) => {
                        e.stopPropagation();
                        setFile(null);
                      }}
                      style={{ position: "absolute", right: 10, top: 10 }}
                    >
                      <img src={Close} width={20} alt={"close"} />
                    </span>
                    <span
                      style={{
                        width: 35,
                        height: 35,
                        backgroundColor: "#44AC65",
                        display: "inline-flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "5px",
                      }}
                    >
                      <img src={excel} width={16} alt="excel" />
                    </span>
                    <p className="ellipsis mt1" title={file.name}>
                      {file.name}
                    </p>
                  </div>
                ) : (
                  <div className="mt1">
                    <div>
                      <img src={upload} width={14} alt="upload" />
                    </div>
                    <span className="text-orginal">{t('upload_ur_file')}</span>
                    <p>
                      {t('csv_edit')}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
          <Row className="mt2">
            <Col span={16}>
              <p>{t('message')}</p>
              <TextArea
                className="transferText"
                autoSize={{ minRows: 3, maxRows: 5 }}
                onChange={(e) => {
                  setRemarks(e.target.value);
                }}
                value={remarks}
              />
              <p className="mt2">
                {t('use_funds')}
                <br />
                {t('initiate_transfer_request')}
              </p>

              <button
                className="primary-btn"
                onClick={() => {
                  let formData = new FormData();
                  formData.append("input_file", file);
                  formData.append("format", "csv");
                  formData.append("remarks", remarks);
                  dispatch(uploadBulk(formData));
                }}
                // disabled={uploadData?.loading}
                disabled={!file || uploadData?.loading || !hasWriteAccess}
              >
                {t('submit')}
              </button>
            </Col>
            {/* <Col span={8} className="rightContentTransfer">
              <h3>Download Samples</h3>
              <p>
                Sample file will download the active cards information along
                with wallet TopUp fields
              </p>
            </Col> */}
          </Row>
        </TabPane>
        {/* <TabPane tab={tabTitle.limits()} key="limits">
          wwww
        </TabPane> */}
      </Tabs>
    </>
  );
};

export default BulkTransfers;
