import { Divider, message, Modal, Upload } from "antd";
import axios from "axios";
import { useFormik } from "formik";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { PulseLoader } from "react-spinners";
import { v4 as uuidv4 } from "uuid";
import * as yup from "yup";
import {
  companyProfile,
  companySetting,
  getCompanyCities,
  getCompanyCountries,
  getCompanyStates,
  getCompanyTimezones,
  UpdateCompanyProfile,
  updateCompanyProfile,
} from "../../../actions/companySettings";
import { headerTitles } from "../../../actions/masterdata/masterdata";
import close from "../../../assets/icons/close.png";
import { apis } from "../../../config/APIs";
import useDocumentAxios from "../../../config/useDocument";
import useScrollToError from "../../../config/useScrollToError.js";
import { getFileExtension, showFile } from "../../../utils";
import Loader from "../../Loader";
import "../styles/setting.css";
import AddLogoModal from "./AddLogoModal.js";
import CompanyAddressModal from "./CompanyAddressModal.js";
import CompanyDetailsModal from "./CompanyDetailsModal.js";
import CompanyPrimaryModal from "./CompanyPrimaryContact.js";
import CompanyReginalModal from "./CompanyRegional.js";
import { countries as country } from "./country";
import moment from "moment";
import { uniq } from "lodash";
import FileUploadComponent from "./FileUploadComponent.js";

const ValidationSchema = yup.object().shape({
  first_name: yup.string().required("Please provide first name").nullable(true),
  last_name: yup.string().required("Please provide last name").nullable(true),
});

message.config({
  maxCount: 3,
});

const Organisation = ({ hasWriteAccess }) => {
  const { t } = useTranslation();
  const profileUpdateData = useSelector(
    (state) => state?.companySetting?.updateCompanyProfilefImages?.data
  );

  const formik = useFormik({
    initialValues: {
      city: "",
      state: "",
      country: "",
      pincode: "",
      street_one: "",
      street_two: "",
      first_name: "",
      last_name: "",
      middle_name: "",
      gender: "",
      email: "",
      primary_phone: "",
    },
    validationSchema: ValidationSchema,
    onSubmit: (values) => {
      if (uploadData?.logo) {
        values["logo"] = [uploadData?.logo?.attachId];
      }
      if (uploadData?.incorporation) {
        values["incorporation"] = [uploadData?.incorporation?.attachId];
      }
      if (uploadData?.gst_certificate) {
        values["gst_certificate"] = [uploadData?.gst_certificate?.attachId];
      }
      if (companyData.id) {
        values.id = companyData.id;
      }
      if (companyData.id) {
        values.id = companyData.id;
      }
      const payload = {
        user_details: {
          primary_phone: values?.primary_phone,
          first_name: values?.first_name,
          last_name: values?.last_name,
          middle_name: values?.middle_name ? values?.middle_name : "",
          dob: values?.dob,
          gender: values?.gender,
        },
        address_details: [
          {
            street_one: values?.street_one,
            street_two: values?.street_two,
            city: values?.city,
            state: values?.state,
            country: values?.country,
            zipcode: values?.pincode,
            id: company?.address?.[0]?.id,
            address_type: company?.address?.[0]?.address_type,
            enable: company?.address?.[0]?.enable,
          },
        ],
      };
      dispatch(updateCompanyProfile(payload));
    },
  });
  const [fileData, setFileData] = React.useState([]);

  // Use the custom hook
  useScrollToError(
    Object.keys(formik?.errors)[0],
    formik?.errors,
    formik?.submitCount
  );
  const dispatch = useDispatch();
  const [companyData, setCompanyData] = React.useState({});
  const [dataLoading, setDataLoading] = React.useState(true);
  const [editMode, setEditMode] = React.useState(false);
  const [countryData, setCountryData] = React.useState("");

  const [output, setOutput] = React.useState({});
  const [uploadData, setUploadData] = React.useState({});
  const [isPreviewOpen, setIsPreviewOpen] = useState({
    open: false,
    url: "",
    fileType: "",
  });
  const { onCall: DocumentAttID, loading: isUploadLoading } = useDocumentAxios({
    api: apis.createDocument,
    method: "post",
    baseURL: "document",
  });
  const { onCall: DocumentDelete } = useDocumentAxios({
    api: apis?.deleteDocument,
    method: "delete",
    baseURL: "document",
  });
  const companySettingResponse = useSelector(
    (state) => state.companySetting.companySetting?.data?.data || {}
  );
  const companyProfileResponse = useSelector(
    (state) => state.companySetting.companyProfile || {}
  );
  const updateCompanyProfileResponse = useSelector(
    (state) => state.companySetting.updateCompanyProfile || {}
  );
  const loaderCheck = useSelector((state) => state.companySetting);

  const [postalcode, setPostalCode] = useState("");
  const { company_id = "" } = ({} = companySettingResponse);
  const { data: { data: company = {} } = {} } = companyProfileResponse;

  React.useEffect(() => {
    Object.values(loaderCheck).forEach((val) => setDataLoading(val.loading));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaderCheck]);
  React.useEffect(() => {
    formik?.setFieldValue("state", output?.state);
    formik?.setFieldValue("city", output?.district);
  }, [output]);

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const handleFileChange = async (info, name) => {
    if (info?.file?.status === "remove") {
      await DocumentDelete({
        data: {
          ids: uploadData?.[name]?.attachId,
        },
        params: { record_id: uploadData[info?.file?.uid]?.recordId },
        headers: {
          "Content-Type": "multipart/formdata",
        },
      });
    } else {
      if (uploadData?.[name]?.attachId) {
        await DocumentDelete({
          data: {
            ids: [uploadData?.[name]?.attachId],
          },
          params: { record_id: uploadData[name]?.recordId },
          headers: {
            "Content-Type": "multipart/formdata",
          },
        });
      }
      const uniqueNo = uploadData?.[name]
        ? uploadData?.[name]?.recordId
        : uuidv4();
      const formData = new FormData();
      formData.append("files", info?.file);
      formData.append("name", name);
      formData.append("record_type", "Company");
      formData.append("record_id", uniqueNo);
      const attachId = await DocumentAttID({
        data: formData,
        headers: {
          "Content-Type": "multipart/formdata",
        },
      });
      if (attachId?.data?.[0]) {
        await getBase64(info?.file, (url) => {
          formik.setFieldValue(`${name}`, attachId?.data[0]);
          switch (name) {
            case "logo":
              setUploadData({
                ...uploadData,
                logo: {
                  attachId: attachId?.data?.[0],
                  recordId: uniqueNo,
                  url: url,
                  fileName: info?.file?.name,
                },
              });
              break;
            case "incorporation":
              setUploadData({
                ...uploadData,
                incorporation: {
                  attachId: attachId?.data?.[0],
                  recordId: uniqueNo,
                  url: url,
                  fileName: info?.file?.name,
                },
              });
              break;
            case "gst_certificate":
              setUploadData({
                ...uploadData,
                gst_certificate: {
                  attachId: attachId?.data?.[0],
                  recordId: uniqueNo,
                  url: url,
                  fileName: info?.file?.name,
                },
              });
              break;
            default:
              break;
          }
        });
      }
    }
  };
  const beforeUpload = (file, fileList) => {
    const fileName = getFileExtension(file?.name)?.toLowerCase();
    if (["jpg", "jpeg", "png", "pdf"]?.includes(fileName)) {
      return false;
    } else {
      message.error(
        <span className="messageText">
          {"Please Upload JPG or PNG or PDF files"}
        </span>
      );
      return Upload.LIST_IGNORE;
    }
  };
  const getBase64 = async (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };
  React.useEffect(() => {
    dispatch(getCompanyCountries({ params: { isCsv: true } }));
    dispatch(getCompanyTimezones({}));
    dispatch(
      headerTitles({
        title: t("company_profile"),
        description: t("see_company"),
      })
    );
    // eslint-disable-next-line
    dispatch(companyProfile({}));
  }, []);

  React.useEffect(() => {
    if (company_id) {
      dispatch(companyProfile({ id: company_id }));
      dispatch(getCompanyStates({ params: { country_id: company.country } }));
      dispatch(getCompanyCities({ params: { state_id: company.state } }));
    }
    // eslint-disable-next-line
    // dispatch(companyProfile({}))
  }, []);

  React.useEffect(() => {
    const { data: { status = "", errors = "", data = "" } = {} } =
      updateCompanyProfileResponse;
    if (status) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      message.success(
        <span className="messageText">{t("profile_updated_success")}</span>
      );
      dispatch({
        type: UpdateCompanyProfile.RESET,
      });
      dispatch(
        companyProfile({ id: companySettingResponse?.result?.company_id })
      );
      setEditMode(false);
      setUploadData({});
    } else if (status === false) {
      errors && message.error(<span className="messageText">{errors}</span>);
      dispatch({
        type: UpdateCompanyProfile.RESET,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateCompanyProfileResponse]);

  React.useEffect(() => {
    const { data: { data: company = {}, status = false } = {} } =
      companyProfileResponse;

    if (status && company) {
      setCompanyData(company);
      let arr = [];
      if (company?.identityDetails?.[0]?.attachments) {
        arr = [...company?.identityDetails?.[0]?.attachments];
      }
      if (company?.informationDetails?.[0]?.attachments?.length) {
        arr = [
          ...arr,
          ...company?.informationDetails?.[0]?.attachments?.filter(
            (ele) => ele.place_type !== "SELFIE"
          ),
        ];
      }
      setFileData(arr);

      formik.setValues({
        city: company.address?.[0]?.city,
        state: company.address?.[0]?.state,
        country: company.address?.[0]?.country,
        street_one: company.address?.[0]?.street_one,
        street_two: company.address?.[0]?.street_two,
        pincode: company.address?.[0]?.zipcode,
        first_name: company?.profile?.first_name,
        last_name: company?.profile?.last_name,
        middle_name: company?.profile?.middle_name,
        email: company?.profile?.email,
        primary_phone: company?.profile?.primary_phone,
        gender: company?.profile?.gender,
        dob: moment(company?.profile?.dob),
      });
      if (company) {
        setUploadData((upData) => ({
          ...upData,
          gst_certificate: {
            attachId: company?.gst_certificate?.id,
            recordId: company?.id,
            url: company?.gst_certificate?.url,
            fileName: company?.gst_certificate?.file_name,
          },
          incorporation: {
            attachId: company?.incorporation?.id,
            recordId: company?.id,
            url: company?.incorporation?.url,
            fileName: company?.incorporation?.file_name,
          },
          logo: {
            attachId: company?.logo?.id,
            recordId: company?.id,
            url: company?.logo?.url,
            fileName: company?.logo?.file_name,
          },
        }));
      }
      setOutput((obj) => ({
        ...obj,
        state: company.state,
        district: company.city,
      }));
      setCountryData(
        country?.find(
          (ele) => ele.value === company.country || ele.code === company.country
        )?.code
      );
      setPostalCode(company?.pincode);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyProfileResponse]);

  const handleCountryData = (value) => {
    setCountryData(value);
    formik.setFieldValue("country", value);
    dispatch(getCompanyStates({ params: { country_id: value } }));
  };

  return (
    <>
      {isUploadLoading && (
        <div className="text-center loadingDiv">
          <PulseLoader color="#fff" />
        </div>
      )}
      {dataLoading ? <Loader /> : null}

      <Modal
        visible={isPreviewOpen?.open}
        onCancel={() => {
          setIsPreviewOpen({
            open: false,
            url: "",
            fileType: "",
          });
        }}
        footer={null}
        width="80%"
        centered
        className="preview_section_modal"
      >
        {isPreviewOpen?.fileType == "pdf" ? (
          <div style={{ width: "100%", height: "100vh" }}>
            <iframe
              src={isPreviewOpen?.url}
              allow="fullscreen"
              style={{ width: "100%", height: "100%", margin: "auto" }}
              title="description"
            ></iframe>
          </div>
        ) : (
          <div
            style={{ width: "100%", height: "100vh" }}
            className="d-flex-center"
          >
            <img
              src={isPreviewOpen?.url}
              alt="preview image"
              style={{ maxWidth: "100%", maxHeight: "100%", margin: "auto" }}
            />
          </div>
        )}
      </Modal>
      <div className="upload_payment_parent send-payment request-money">
        <div className="flex-between sec-head">
          <div className="tabsSection">
            <span className="active">Company Profile</span>
          </div>
          {/* <img src={close} width={20} /> */}
        </div>
        <form onSubmit={formik.handleSubmit} className="view-details">
          <div className="company-profile">
            <div className="p2">
              {/* <AddLogoModal
                company={company}
                formik={formik}
                setUploadData={setUploadData}
              />
              <Divider /> */}
              <CompanyDetailsModal company={company} formik={formik} />
              <Divider />
              <CompanyAddressModal company={company} formik={formik} />
              <Divider />
              <div className="flex-between mb1">
                <h3>Information Details</h3>
              </div>

              <div className="company-address company-details">
                <div className="item">
                  <label>Document Number</label>
                  <span>{company?.identityDetails?.[0]?.document_number}</span>
                </div>
                <div className="item">
                  <label>Document Expiry</label>
                  <span>{company?.identityDetails?.[0]?.document_expiry}</span>
                </div>
                <div className="item">
                  <label>SSN Details</label>
                  <span>{company?.informationDetails?.[0]?.ssn_details}</span>
                </div>
              </div>
              <div className="company-address company-details">
                {fileData?.map((ele) => {
                  return (
                    <FileUploadComponent
                      fileName={ele?.filename}
                      url={ele?.url}
                      hasImg={ele?.url}
                    />
                  );
                })}
              </div>
              {/* <CompanyPrimaryModal company={company} formik={formik} />
              <Divider />
              <CompanyReginalModal company={company} formik={formik} /> */}
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default Organisation;
