import { Button, Col, Modal, Row, Space, message } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  beneficiary_accounts,
  get_cmp_accounts
} from "../../../actions/accounts/accounts";
import { get_currencies } from "../../../actions/masterdata/masterdata";
import {
  CREATE_PAYMENT_REQUEST,
  UPDATE_PAYMENT
} from "../../../actions/payments";
import {
  get_person_accounts,
  initialize_payment,
} from "../../../actions/z_accounts";
import { apis } from "../../../config/APIs";
import endpointGridApi from "../../../config/Axios";
import endpointSettingsApi from "../../../config/Axios";
import { gridApis } from "../../../config/GridApis";
import { getSymbol } from "../../../config/helper";
import usePayAxios from "../../../config/useAxios";
import useNotificationAxios from "../../../config/useNotification";
import { amountFormat } from "../../../utils";
import OTPComponent from "../../Common/OTPComponent";
import { FormInput, FormSelect } from "../../inputs";

const CreatePayment = ({
  open,
  onClose,
  isCompany,
  source,
  from,
  payData,
  setRecord,
  onSubmit: on_submit,
  record,
  isEdit,
  setEdit,
  paymentDetailsPage,
  callOnMount,
  handleGetAllPayments,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [clickAccount, setClickAccount] = useState("");
  const [policyViolations, setPolicyViolations] = useState({});
  const [paymentId, setPaymentId] = useState("");
  const [exchange, setExchange] = useState({});
  const data1 = useSelector(
    (state) => state?.z_accs?.accounts?.data?.data || []
  );
  const currenciesRes = useSelector((state) =>
    state?.currencies?.data?.data?.length
      ? state?.currencies?.data?.data?.map((ele) => ({
          label: (
            <span
              style={{
                color: "#212121",
                fontSize: 15,
              }}
            >
              <img
                src={`https://cdn.jsdelivr.net/npm/svg-country-flags@1.2.10/svg/${ele?.currency?.country_code?.toLowerCase()}.svg`}
                className="mr1"
                width={25}
                style={{
                  boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.4)",
                }}
              />
              {`${ele?.iso_code}`}
            </span>
          ),
          value: ele.id,
        }))
      : []
  );
  const payoutRes = useSelector((state) => state?.banks?.initiate_payout?.data);
  const currencies = useSelector((state) => state?.currencies?.data?.data);

  const [otp, setOtp] = useState({
    otpSent: false,
    otp: "",
  });
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch,
    getValues,
  } = useForm({
    defaultValues: {
      debit_account: "",
      credit_account: "",
      amount: "",
      description: "",
      company_currency_id: "",
      record_type: "Beneficiary",
      payee_account_no: "00015013843",
      payee_ifsc: "RATN0000341",
      payee_name: "Ratnadeep",
      record_id: "",
    },
    shouldUseNativeValidation: false,
  });

  const corporate_budget = useSelector(
    (state) => state.cards.corporate_budget?.data?.data
  );
  const { onCall: budgetCheck, loading: budgetLoading } = usePayAxios({
    api: apis.budgetCriteriaFlowCheck,
    method: "post",
  });
  const { onCall: verifyOtp, loading: verifyLoading } = useNotificationAxios({
    api: apis.payment_verify_otp,
    method: "post",
  });
  const { onCall: genOtp, loading } = useNotificationAxios({
    api: apis.gen_otp,
    method: "post",
  });

  const BeneficiaryBankDetails = useSelector((state) =>
    state?.banks?.beneficiary_accounts?.data?.data?.map((ele) => ({
      ...ele,
      label:
        ele?.beneficiary_name + "-" + ele?.bank_accounts?.[0]?.account_number,
      value: ele?.bank_accounts?.[0]?.id,
    }))
  );

  const CompanyBankDetails = useSelector((state) =>
    state?.banks?.cmp_accs?.data?.data?.map((ele) => ({
      ...ele,
      label: ele?.bank_name + "-" + ele?.account_number,
      value: ele?.id,
    }))
  );

  const createRes = useSelector(
    (state) => state?.payments?.create_payment?.data
  );
  const payoutLoading = useSelector(
    (state) => state?.payments?.create_payment?.loading
  );
  const updatePaymentResponse = useSelector(
    (state) => state?.payments?.update_payment
  );

  useEffect(() => {
    if (updatePaymentResponse?.data?.error === true) {
      message.error(
        <span className="messageText">
          {updatePaymentResponse?.data?.message}
        </span>
      );
      dispatch({
        type: UPDATE_PAYMENT.RESET,
      });
    } else if (updatePaymentResponse?.data?.error === false) {
      message.success(
        <span className="messageText">
          {"Payment Updated Successfully" ||
            updatePaymentResponse?.data?.message}
        </span>
      );
      dispatch({
        type: UPDATE_PAYMENT.RESET,
      });
      setRecord({});
      reset();
      callOnMount();
      onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatePaymentResponse]);

  useEffect(() => {
    dispatch(get_cmp_accounts());
    dispatch(beneficiary_accounts());
    dispatch(get_currencies());
    dispatch(
      get_person_accounts({
        page_number: 1,
        page_size: 50,
        person_id: "ID-1146",
      })
    );
  }, []);

  useEffect(() => {
    if (record?.id) {
      setValue("amount", record?.amount);
      setValue("record_id", record?.record_id);
      setValue("payee_account_no", record?.payee_account_no);
      setValue("payee_ifsc", record?.payee_ifsc);
      setValue("payee_name", record?.payee_name);
      setValue("debit_account", record?.sender_account_id);
      setValue("description", record?.description);
      setValue("company_currency_id", record?.company_currency_id);
    } // setCurrencyExchange(record?.company_currency_id)
  }, [record]);

  useEffect(() => {
    if (from === "details" && payData?.id) {
      setValue("amount", payData?.amount);
      setValue("record_id", payData?.record_id);
      setValue("debit_account", payData?.sender_account_id);
      setValue("payee_account_no", payData?.payee_account_no);
      setValue("payee_ifsc", payData?.payee_ifsc);
      setValue("payee_name", payData?.payee_name);
      if (payData?.company_currency_id) {
        setValue("company_currency_id", payData?.company_currency_id);
        let obj = isCompany
          ? CompanyBankDetails?.find((ele) => ele.value === payData?.record_id)
          : BeneficiaryBankDetails?.find(
              (ele) => ele.value === payData?.record_id
            );
        setCurrencyExchange(obj?.company_currency_id);
      }
    }
  }, [payData]);

  useEffect(() => {
    if (payoutRes?.error === false) {
      setOtp({
        otp: "",
        otpSent: false,
      });
      onCloseDrawer();
    }
  }, [payoutRes]);

  useEffect(() => {
    if (createRes?.error === false) {
      message.success(
        <span className="messageText">{t("create_pay_success")}</span>
      );
      dispatch({
        type: CREATE_PAYMENT_REQUEST.RESET,
      });
      reset();
      onCloseDrawer();
      onClose(true);
    } else if (createRes?.error) {
      message.error(<span className="messageText">{createRes?.message}</span>);
      dispatch({
        type: CREATE_PAYMENT_REQUEST.RESET,
      });
    }
  }, [createRes]);

  const min_amount =
    data1.find((item) => item.merchID === watch("debit_account"))?.min_amount ??
    10;

  // console.log(errors);
  const onSubmit = async (data, event) => {
    setPolicyViolations({});
    if (event.nativeEvent?.submitter?.id === "payBtn") {
      // data.order_id = "ORD132";
      // data.currency = currencies?.find(
      //   (ele) => ele.id === data.company_currency_id
      // )?.iso_code;
      // data.amount = Number(data.amount);
      // data.mode_of_payment = "UPI";
      // data.exchange_rate = exchange?.live_exchange_rates?.rate
      //   ? exchange?.live_exchange_rates?.rate
      //   : exchange?.manual_exchange_rates?.rate;
      // data.currency_datetime = exchange?.live_exchange_rates?.date
      //   ? exchange?.live_exchange_rates?.date
      //   : exchange?.manual_exchange_rates?.date;
      // data.bc_amount = convertCurrency(Number(data.amount), exchange);
      if (payData?.id) {
        if (!otp.otpSent) {
          budgetCheck({
            data: {
              amount: data.amount + "",
            },
          })
            .then((res) => {
              let obj = {};
              if (res?.data?.errorPolicy) {
                Object.keys(res?.data?.errorPolicy).forEach((ele) => {
                  if (res?.data?.limitUpto[ele] && res?.data?.errorPolicy[ele])
                    obj[ele] = `Policy violation (${ele}): Amount for ${
                      res?.data?.errorPolicy[ele]
                    }  exceeds the limit of ${getSymbol()} ${
                      res?.data?.limitUpto[ele]
                    }`;
                });
              } else if (res?.error && res?.message) {
                message.error(<span className="errorMsg">{res?.message}</span>);
              }
              if (res.error === false && Object.keys(obj).length === 0) {
                const payload = {
                  amount: Number(payData?.amount),
                  currency_code: payData?.currency_code,
                  sender_account_id: payData?.sender_account_id,
                  beneficiary_id: payData?.record_id,
                  detail_name: payData.description,
                  recipient_amount: Number(payData?.amount),
                  sender_amount: payData?.bc_amount ?? 0,
                  exchange_fee: payData?.exchange_fee ?? 0,
                  exchange_rate: payData?.exchange_rate ?? 0,
                  purpose_code: payData?.purpose ?? 1,
                  account_mode_type:
                    payData?.mode_of_payment === "WITHIN_BANK"
                      ? "WITHINBANK"
                      : payData?.mode_of_payment,
                };
                dispatch(initialize_payment(payload));
                genOtp({
                  data: {
                    user_id: localStorage.getItem("user_id"),
                    email_id: localStorage.getItem("user"),
                    source: "PAYMENT",
                  },
                })
                  .then((res) => {
                    if (res.error === false) {
                      message.success(
                        <span className="messageText">{res?.message}</span>
                      );
                      setOtp({
                        ...otp,
                        otpSent: true,
                      });
                    }
                    if (res.error) {
                      message.error(
                        <span className="messageText">{res?.message}</span>
                      );
                    }
                  })
                  .catch((err) => {
                    message.success(
                      <span className="messageText">
                        {err?.response?.data?.message}
                      </span>
                    );
                  });
              }
              setPolicyViolations(obj);
            })
            .catch((err) => {
              if (err.response?.data?.message) {
                message.error(
                  <span className="messageText">
                    {err?.response?.data?.message}
                  </span>
                );
              }
            });
        } else if (otp.otpSent) {
          if (Object.keys(policyViolations).length === 0) {
            verifyOtp({
              data: {
                user_id: localStorage.getItem("user_id"),
                email_id: localStorage.getItem("user"),
                source: "PAYMENT",
                otp: otp.otp,
              },
            })
              .then((res) => {
                if (res.error === false) {
                  on_submit();
                }
              })
              .catch((err) => {
                message.error(
                  <span className="messageText">
                    {err?.response?.data?.message}
                  </span>
                );
              });
          }
        }
      } else {
        if (!otp.otpSent) {
          const payload = {
            amount: Number(payData?.amount),
            currency_code: payData?.currency_code,
            sender_account_id: payData?.sender_account_id,
            beneficiary_id: payData?.record_id,
            detail_name: payData.description,
            recipient_amount: Number(payData?.amount),
            sender_amount: payData?.bc_amount ?? 0,
            exchange_fee: payData?.exchange_fee ?? 0,
            exchange_rate: payData?.exchange_rate ?? 0,
            purpose_code: payData?.purpose ?? 1,
            account_mode_type:
              payData?.mode_of_payment === "WITHIN_BANK"
                ? "WITHINBANK"
                : payData?.mode_of_payment,
          };
          endpointGridApi
            .post(gridApis.initialisePayment, payload)
            .then((res) => {
              if (res?.data?.endToEndId) {
                setPaymentId(res?.data?.endToEndId);
                genOtp({
                  data: {
                    user_id: localStorage.getItem("user_id"),
                    email_id: localStorage.getItem("user"),
                    source: "PAYMENT",
                  },
                })
                  .then((res) => {
                    if (res.error === false) {
                      message.success(
                        <span className="messageText">{res?.message}</span>
                      );
                      setOtp({
                        ...otp,
                        otpSent: true,
                      });
                    }
                    if (res.error) {
                      message.error(
                        <span className="messageText">{res?.message}</span>
                      );
                    }
                  })
                  .catch((err) => {
                    message.success(
                      <span className="messageText">
                        {err?.response?.data?.message}
                      </span>
                    );
                  });
              } else if (res?.data?.data?.responseData?.errors?.length) {
                message.error(
                  <span className="messageText">
                    {res?.data?.data?.responseData?.errors?.[0]}
                  </span>
                );
              }
            });
        } else {
          verifyOtp({
            data: {
              user_id: localStorage.getItem("user_id"),
              email_id: localStorage.getItem("user"),
              source: "PAYMENT",
              otp: otp.otp,
            },
          })
            .then((res) => {
              if (res.error === false) {
                endpointGridApi
                  .post(gridApis.confirmPayment, {
                    payment_id: paymentId,
                    comments: "PAYMENT",
                  })
                  .then((res) => {
                    if (res?.error) {
                      message.error(
                        <span className="messageText">{res?.message}</span>
                      );
                    } else {
                      onCloseDrawer();
                      message.success(
                        <span className="messageText">
                          Payment Initiated Successfully
                        </span>
                      );
                    }
                  });
              }
            })
            .catch((err) => {
              message.error(
                <span className="messageText">
                  {err?.response?.data?.message}
                </span>
              );
            });
        }
      }
    }
  };

  const onCloseDrawer = useCallback(() => {
    reset?.();
    onClose?.();
    setPolicyViolations({});
  }, [reset, onClose]);

  console.log(errors);

  const setCurrencyExchange = (id) => {
    endpointSettingsApi
      .get(apis.getCompanyBaseCurrency, {
        params: { company_currency_id: id },
      })
      .then((res) => {
        if (
          !res?.data?.data?.live_exchange_rates?.rate &&
          !res?.data?.data?.manual_exchange_rates?.rate
        ) {
          message.error(
            <span className="messageText">{res?.data?.message}</span>
          );
        }
        setExchange(res?.data?.data);
      })
      .catch((e) => {
        message.error(
          <span className="messageText">{e?.response?.data?.message}</span>
        );
      });
  };
  const debit_account = data1?.find((ele) => ele.id === watch("debit_account"));
  const amount = watch("amount");
  const company_currency_id = watch("company_currency_id");
  const balCheck = Number(corporate_budget?.amount) <= Number(amount);

  return (
    <>
      <Modal
        onCancel={() => {
          if (!otp?.otpSent || from) {
            onCloseDrawer();
          }
        }}
        className="right-alinged-modal add-modal"
        width={720}
        title={
          <div className="d-flex">
            <div className="flex-grow">
              <span className="title">
                {isEdit ? t("update_company_payments") : t("Payment Details")}
              </span>
            </div>
          </div>
        }
        visible={open}
      >
        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            {(otp.otpSent || from === "details") && (
              <>
                <div className="payment-details">
                  <b>Payment Details:</b>
                  <br />
                  Debit Account: {debit_account?.account_name} -{" "}
                  {debit_account?.account_number}
                  <br />
                  Amount: {payData?.currency_code} {amountFormat(amount)}
                  <br />
                  Credit Account: {getValues("payee_name")} /{" "}
                  {getValues("payee_account_no")} / {getValues("payee_ifsc")}
                </div>
                {otp.otpSent && (
                  <OTPComponent
                    source={source}
                    value={otp.otp}
                    loading={payoutLoading}
                    onChange={(otp) => {
                      setOtp((o) => ({
                        ...o,
                        otp,
                      }));
                    }}
                  />
                )}
              </>
            )}
            <div className={otp.otpSent || from === "details" ? "d-none" : ""}>
              <div className="p1">
                <div className="pp-form-item">
                  <FormSelect
                    hidden={otp.otpSent}
                    inline
                    control={control}
                    {...register("debit_account", {
                      required: true,
                      onChange: (e) => {
                        setClickAccount(e.target.value);
                      },
                    })}
                    hideCreateOption
                    required
                    errors={errors}
                    label={t("debit_account")}
                    placeholder={t("select_debit_account")}
                    options={data1?.map((ele) => ({
                      label: `${ele?.accountName} - ${ele?.accountNumbers?.[0]?.accountNumber?.value}`,
                      value: ele?.accountId,
                    }))}
                  />
                </div>
                <div className="pp-form-item">
                  <FormSelect
                    inline
                    hidden={otp.otpSent}
                    control={control}
                    {...register("record_id", {
                      required: `${
                        t("credit_account") + " " + t("is_Requrired")
                      }`,
                      onChange: (e) => {
                        setValue("record_id", e.target.value);
                        let obj = isCompany
                          ? CompanyBankDetails?.find(
                              (ele) => ele.value === e.target.value
                            )
                          : BeneficiaryBankDetails?.find(
                              (ele) => ele.value === e.target.value
                            );
                        setValue(
                          "payee_account_no",
                          obj?.bank_accounts?.[0]?.account_number
                        );
                        setValue(
                          "payee_ifsc",
                          obj?.bank_accounts?.[0]?.ifsc_code
                        );
                        setValue("payee_name", obj?.beneficiary_name);
                        setValue("company_currency_id", obj?.currency_info?.id);
                        setCurrencyExchange(obj?.currency_info?.id);
                      },
                    })}
                    hideCreateOption
                    required
                    errors={errors}
                    label={t("credit_account")}
                    placeholder={t("select_credit_account")}
                    options={
                      isCompany ? CompanyBankDetails : BeneficiaryBankDetails
                    }
                  />
                </div>

                <div className="pp-form-item">
                  <Row align={"bottom"} justify={"space-between"} gutter={16}>
                    <Col span={8}>
                      <FormSelect
                        control={control}
                        {...register("company_currency_id")}
                        placeholder="Select Currency"
                        errors={errors}
                        label={t("Amount")}
                        required={true}
                        disabled={true}
                        hideCreateOption
                        options={currenciesRes}
                      />
                    </Col>
                    <Col span={16}>
                      <FormInput
                        control={control}
                        hidden={otp.otpSent}
                        {...register("amount", {
                          pattern: {
                            value: /^[0-9.]+$/i,
                            message: `${t("amount_validation")}`,
                          },
                          validate: (value) =>
                            value >= parseInt(min_amount) ||
                            `Amount should not be less than ${min_amount}`,
                          required: `${
                            t("amount_field") + " " + t("is_Requrired")
                          }`,
                        })}
                        errors={errors}
                        placeholder={t("enter_amount")}
                        // required={true}
                        label={""}
                        number={true}
                      />
                    </Col>
                    {balCheck && (
                      <div className="errorMsg">Insufficient Balance</div>
                    )}
                  </Row>
                </div>

                <div className="pp-form-item">
                  <FormInput
                    control={control}
                    hidden={otp.otpSent}
                    {...register("description")}
                    errors={errors}
                    label={t("desc")}
                    placeholder={t("enter_desc")}
                  />
                </div>
                {/* <div style={{ marginTop: "40px" }}>
                  <UploadField
                    title={t('attach_payments')}
                    desc="(optional)"
                    subDescripcion={
                      t('desc_sub')
                    }
                  />
                </div> */}
              </div>
            </div>
            {Object.keys(policyViolations).length > 0 && (
              <div style={{ marginTop: "40px", color: "red" }}>
                {Object.keys(policyViolations).map((ele) => (
                  <p>{policyViolations[ele]}</p>
                ))}
              </div>
            )}
            <Space
              style={{
                display: "flex",
                flexDirection: "row",
                padding: "6rem 47px 6rem 0px",
                justifyContent: "end",
              }}
            >
              {otp.otpSent ? (
                <Button
                  type="primary"
                  htmlType="submit"
                  className="pp-main-button "
                  id="payBtn"
                  disabled={payoutLoading || budgetLoading || verifyLoading}
                >
                  <span>
                    {payoutLoading || budgetLoading || verifyLoading
                      ? t(otp.otpSent ? "paying..." : "Creating...")
                      : t(otp.otpSent ? "pay" : "Create Payment")}
                  </span>
                </Button>
              ) : (
                <Button
                  type="primary"
                  htmlType="submit"
                  className="pp-main-button "
                  id="payBtn"
                  disabled={loading}
                >
                  <span>
                    {loading
                      ? t(
                          from === "details"
                            ? "sending"
                            : isEdit
                            ? "Updating"
                            : "Creating..."
                        )
                      : t(
                          from === "details"
                            ? "send_otp"
                            : isEdit
                            ? "Update Payment"
                            : "Create Payment"
                        )}
                  </span>
                </Button>
              )}
              <Button
                className="pp-secondary-button"
                style={{ marginLeft: "2rem" }}
                onClick={() => {
                  if (!otp?.otpSent || from) {
                    onCloseDrawer();
                  } else {
                    endpointGridApi.post(gridApis.cancelPayment, {
                      payment_id: paymentId,
                      comments: "",
                    });
                    onCloseDrawer();
                  }
                  setRecord({});
                }}
              >
                <span>{t("cancel")}</span>
              </Button>
            </Space>
          </form>
        </div>
      </Modal>
    </>
  );
};

export default CreatePayment;
