import { message, Modal } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { p_types } from "../Payments/SendMoney/Recipients";
import { AccountDisplay } from "../Account/AccountCard";
import usePayAxios from "../../config/useAxios";
import { gridApis } from "../../config/GridApis";
import DeleteConfirm from "../Common/DeleteConfirm";

const ViewBen = ({ open, onClose, benDetail: data, onEdit }) => {
  const { onCall: docTypes, data: data1 } = usePayAxios({
    api: gridApis.getRecipientDocTypes,
    method: "get",
  });

  const {
    onCall: deleteBen,
    data: delBen,
    loading,
  } = usePayAxios({
    api: gridApis.deleteBeneficiary,
    method: "post",
  });

  const [showDel, setShowDel] = useState(false);
  useEffect(() => {
    docTypes({});
  }, []);

  const identityTypes = Array.isArray(data1?.data)
    ? data1?.data?.map((ele) => ({
        label: ele.display_value,
        value: ele.value,
      }))
    : [];

  return (
    <Modal
      className="right-alinged-modal ben-detail-"
      title={
        <label
          style={{
            color: "#363636",
            paddingBottom: 10,
            fontSize: 21,
          }}
        >
          Recipient Details
        </label>
      }
      visible={open}
      onCancel={onClose}
    >
      <div className="company-details txn-details">
        {/* <h3>Details</h3> */}
        <DeleteConfirm
          open={showDel}
          onCancel={() => {
            setShowDel(false);
          }}
          headerTitle={"Delete Recipient"}
          onConfirm={() => {
            deleteBen({
              data: {
                id: data?.id,
              },
            })
              .then((res) => {
                if (res?.status)
                  message.success(
                    <span className="messageText">{res?.message}</span>
                  );
              })
              .catch((err) => {
                message.error(
                  <span className="messageText">
                    {err?.response?.data?.message}
                  </span>
                );
              });
          }}
          loading={loading}
          name={"Recipient"}
        />
        <div className="item">
          <label>Recipient Name</label> <span>{data?.beneficiary_name}</span>
        </div>
        <div className="item">
          <label>Recipient Type</label>{" "}
          <span className="cap">{data?.recipient_type}</span>
        </div>
        <div className="item">
          <label>Recipient Account</label>{" "}
          <span>
            <AccountDisplay
              accountNumber={data?.bank_accounts?.[0]?.account_number}
            />
          </span>
        </div>

        <div className="item">
          <label>Routing/SWIFT Code</label>{" "}
          <span>
            {data?.bank_accounts?.[0]?.routing_code ??
              data?.bank_accounts?.[0]?.swift_code}
          </span>
        </div>

        {data?.bank_accounts?.[0]?.bank_name && (
          <div className="item">
            <label>Bank Name</label>{" "}
            <span>{data?.bank_accounts?.[0]?.bank_name}</span>
          </div>
        )}
        {data?.bank_accounts?.[0]?.bank_city && (
          <div className="item">
            <label>Bank City</label>{" "}
            <span>{data?.bank_accounts?.[0]?.bank_city}</span>
          </div>
        )}
        {data?.bank_accounts?.[0]?.bank_country && (
          <div className="item">
            <label>Bank Country</label>{" "}
            <span>{data?.bank_accounts?.[0]?.bank_country}</span>
          </div>
        )}
        <div className="item">
          <label>Email</label> <span>{data?.email}</span>
        </div>
        <div className="item">
          <label>Country Code</label> <span>{data?.country_code}</span>
        </div>
        <div className="item">
          <label>Primary Contact</label> <span>{data?.primary_contact}</span>
        </div>
        {data?.identification_type_code && (
          <div className="item">
            <label>Identification Type</label>{" "}
            <span>
              {
                identityTypes.find(
                  (ele) => ele.value === data?.identification_type_code
                )?.label
              }
            </span>
          </div>
        )}
        {data?.identification_type_code_value && (
          <div className="item">
            <label>Identification Number</label>{" "}
            <span>{data?.identification_type_code_value}</span>
          </div>
        )}
        <div className="item">
          <label>Account Transfer Mode</label>{" "}
          <span>
            {
              p_types.find(
                (ele) => ele.value === data?.account_transfer_mode_type
              )?.label
            }
          </span>
        </div>
        <div className="item">
          <label>Currency Code</label>{" "}
          <span>{data?.currency_info?.iso_code}</span>
        </div>
        <div className="item">
          <label>Status</label>{" "}
          <span
            className={`status status_${data?.beneficiary_status?.toLowerCase()}`}
          >
            {data?.beneficiary_status}
          </span>
        </div>
        <div className="item">
          <label>Created Date</label>{" "}
          <span>{moment(data?.created_at).format("MM/DD/YY")}</span>
        </div>
        <h3>Address Details</h3>
        <div className="item">
          <label>Address</label>
          <span>
            {data?.billing_addresses && (
              <>
                {data?.billing_addresses?.[0]?.address}{", "}
                {data?.billing_addresses?.[0]?.address_two}
                <br />
              </>
            )}
            {data?.billing_addresses?.[0]?.city && (
              <>
                {data?.billing_addresses?.[0]?.city}
                <br />
              </>
            )}
            {data?.billing_addresses?.[0]?.state && (
              <>
                {data?.billing_addresses?.[0]?.state}
                <br />
              </>
            )}
            {data?.billing_addresses?.[0]?.country && (
              <>
                {data?.billing_addresses?.[0]?.country}
                <br />
              </>
            )}
            {data?.billing_addresses?.[0]?.zipcode && (
              <>{data?.billing_addresses?.[0]?.zipcode}</>
            )}
          </span>
        </div>
      </div>
      <div
        style={{ display: "flex", marginTop: "2rem" }}
        className="sticky-btm"
      >
        <button className="send-btn" onClick={onEdit}>
          Edit
        </button>

        <button className="back-btn ml1" onClick={onClose}>
          Cancel
        </button>
        <button
          className="delete ml1"
          style={{
            borderRadius: 5,
          }}
          onClick={() => {
            setShowDel(true);
          }}
        >
          Delete
        </button>
      </div>
    </Modal>
  );
};

export default ViewBen;
