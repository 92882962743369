import { combineReducers } from "redux";
import {
  changePassword_reducer,
  forgotPassword_reducer,
  local_varaibles,
  login_reducer,
  logout_reducer,
  notifications_reducer,
  request_demo_reducer,
  resetPassword_reducer,
} from "./actions/auth/reducer";
import {
  account_txns_reducer,
  add_categories_reducer,
  categories_reducer,
  categories_status_reducer,
  delete_categories_reducer,
} from "./actions/categories/reducer";
import {
  dashboard_reducer,
  error_modal_reducer,
  invoice_dashboard_reducer,
  pay_dashboard_reducer,
} from "./actions/dashboard/reducer";
import {
  add_employees_reducer,
  delete_employees_document_reducer,
  delete_employees_reducer,
  download_link_reducer,
  employee_by_id_reducer,
  employees_reducer,
  employees_status_reducer,
  get_employee_reducer,
  get_user_fields_reducer,
  update_employees_reducer,
  upload_bulk_employees_reducer,
  upload_employee_reducer,
} from "./actions/employees/reducer";

import {
  analytics_cards_all_reducer,
  analytics_cards_export_reducer,
  analytics_cards_reducer,
  analytics_estimates_reducer,
  block_reasons_reducer,
  block_reducer,
  card_edit_set_policies_reducer,
  card_enable_limit_reducer,
  card_policies_reducer,
  card_set_policies_reducer,
  cardsDetails_reducer,
  cards_reducer,
  corporate_budget_reducer,
  createInviteLink_reducer,
  delete_card_policy_reducer,
  disconnectCompany_reducer,
  download_bulk_reducer,
  getAllOrganisations_reducer,
  getOneCompany_reducer,
  getSyncHistory_reducer,
  get_card_limit,
  get_card_policies,
  quick_logs_reducer,
  remind_card_activation_reducer,
  replacement_reducer,
  send_otp_reducer,
  set_card_policy_reducer,
  set_pin_reducer,
  show_card_policy_reducer,
  syncData_reducer,
  topup_reducer,
  transactions_reducer,
  transfer_logs_reducer,
  update_mobile_reducer,
  upload_bulk_reducer,
  userCards_reducer,
  verify_otp_reducer,
} from "./actions/cards/reducer";

import {
  all_budgets_reducer,
  beneficiary_accounts_reducer,
  budgets_back_reducer,
  company_accounts_reducer,
  create_budgets_reducer,
  initiate_payout_reducer,
  master_account_history_reducer,
  master_pool_account_reducer,
  payout_txns_reducer,
  primary_account_reducer,
} from "./actions/accounts/reducer";

import {
  add_advancesField_reducer,
  add_companySetting_reducer,
  add_costCenterField_reducer,
  add_designations_reducer,
  add_merchantsField_reducer,
  add_projectsField_reducer,
  add_reportField_reducer,
  add_roles_reducer,
  add_transactionField_reducer,
  add_tripsField_reducer,
  add_userFields_reducer,
  advanceApproval_reducer,
  bill_approval_reducer,
  companyCities_reducer,
  companyCountries_reducer,
  companyProfile_Images_reducer,
  companyProfile_reducer,
  companySetting_reducer,
  companyStates_reducer,
  companyTzs_reducer,
  create_company_multiple_currency_reducer,
  create_exchange_rates_reducer,
  create_multi_branch_reducer,
  create_reminder_api_reducer,
  delete_advancesField_reducer,
  delete_costCenterField_reducer,
  delete_designations_reducer,
  delete_merchantsField_reducer,
  delete_projectsField_reducer,
  delete_reportField_reducer,
  delete_role_reducer,
  delete_transactionField_reducer,
  delete_tripsField_reducer,
  delete_userFields_reducer,
  enable_disable_costCenter_reducer,
  enable_disable_marchentField_reducer,
  enable_disable_projectField_reducer,
  get_advancesField_reducer,
  get_all_branch_header_reducer,
  get_all_branch_list_reducer,
  get_all_branch_reducer,
  get_all_company_details_reducer,
  get_all_countries_reducer,
  get_all_currency_reducer,
  get_all_exchange_rates_reducer,
  get_branch_id_reducer,
  get_card_venders_reducer,
  get_company_base_currency_reducer,
  get_company_multiple_currency_reducer,
  get_costCenterField_reducer,
  get_custom_fields_reducer,
  get_designations_reducer,
  get_merchantsField_reducer,
  get_portal_permission_reducer,
  get_projectsField_reducer,
  get_reminder_api_reducer,
  get_reportField_reducer,
  get_rolesPermissions_reducer,
  get_roles_reducer,
  get_singleRoles_reducer,
  get_singleUserFields_reducer,
  get_single_designations_reducer,
  get_transactionField_reducer,
  get_tripsField_reducer,
  get_userFieldsColumnTypes_reducer,
  get_userFields_reducer,
  orgs_reducer,
  reportsApproval_reducer,
  switch_brach_reducer,
  switch_brach_ruby_reducer,
  toggle_exchange_rate_reducer,
  tripApproval_reducer,
  updateAdvanceApproval_reducer,
  updateCompanyProfile_reducer,
  updateCompanyTrips_reducer,
  updateReportsApproval_reducer,
  updateTripApproval_reducer,
  update_advancesField_reducer,
  update_advancesreport_reducer,
  update_bill_approval_reducer,
  update_companyProfile_imgae_reducer,
  update_companyreport_reducer,
  update_companytransaction_reducer,
  update_costCenterField_reducer,
  update_designations_reducer,
  update_merchantsField_reducer,
  update_projectsField_reducer,
  update_reportField_reducer,
  update_roles_reducer,
  update_transactionField_reducer,
  update_tripsField_reducer,
  update_userFields_reducer,
} from "./actions/companySettings/reducer";
import {
  create_customer_reducer,
  create_invoice_reducer,
  customers_reducer,
  invoices_reducer,
  portal_dashboard_company_list_reducer,
  portal_dashboard_reducer,
  portal_record_details_reducer,
  portal_table_data_reducer,
  show_profile_reducer,
  submit_invoice_reducer,
  upload_profile_reducer,
  view_customer_reducer,
} from "./actions/customers";
import {
  change_policy_priority_reducer,
  create_policy_reducer,
  currencies_reducer,
  delete_policy_reducer,
  enable_policy_reducer,
  get_policies_reducer,
  get_policy_meta_reducer,
  get_policy_reducer,
  seed_data_reducer,
} from "./actions/masterdata/reducer";
import {
  ocr_document_delete_reducer,
  ocr_documents_reducer,
} from "./actions/ocr/reducer";

import {
  approve_payment_reducer,
  create_payment_reducer,
  delete_payment_reducer,
  edit_payment_reducer,
  get_payment_reducer,
  payments_reducer,
  recall_payment_reducer,
  reject_payment_reducer,
  submit_payment_reducer,
  update_payment_reducer,
} from "./actions/payments/reducer";
import {
  delete_payment_link_reducer,
  download_bulk_upload_reducer,
  fetch_bulk_uploads_reducer,
  fetch_payment_links_reducer,
  save_bulk_upload_payment_link_reducer,
  save_bulk_uploads,
} from "./actions/transfers";
import {
  all_payments_reducer,
  get_purposes_reducer,
  initialize_payment_reducer,
  master_account_txns_reducer,
  person_accounts_reducer,
} from "./actions/z_accounts";
import {
  create_on_board_form_reducer,
  get_onboard_fields_reducer,
  onboard_create_reducer,
  onboard_final_submit_reducer,
  onboard_step_preview_reducer,
  onboard_step_reducer,
  post_onboard_api,
} from "./components/OnBoarding/action/OnBoardForm";

export default combineReducers({
  banks: combineReducers({
    accounts: primary_account_reducer,
    cmp_accs: company_accounts_reducer,
    beneficiary_accounts: beneficiary_accounts_reducer,
    master_pool_account: master_pool_account_reducer,
    all_budgets: all_budgets_reducer,
    create_budgets: create_budgets_reducer,
    budgets_back: budgets_back_reducer,
    masterHistory: master_account_history_reducer,
    initiate_payout: initiate_payout_reducer,
    payout_txns: payout_txns_reducer,
  }),
  approvals: combineReducers({
    bills: bill_approval_reducer,
    update_bills: update_bill_approval_reducer,
  }),
  auth: combineReducers({
    login: login_reducer,
    logout: logout_reducer,
    resetPassword: resetPassword_reducer,
    forgotPassword: forgotPassword_reducer,
    changePassword: changePassword_reducer,
    localVariables: local_varaibles,
    notifications: notifications_reducer,
    requestDemo: request_demo_reducer,
  }),
  dashboard: combineReducers({
    dashboard: dashboard_reducer,
    pay: pay_dashboard_reducer,
    inv: invoice_dashboard_reducer,
  }),
  purchases: combineReducers({
    beneficiary: view_customer_reducer,
  }),
  cards: combineReducers({
    userCards: userCards_reducer,
    cards: cards_reducer,
    setPin: set_pin_reducer,
    cardsDetails: cardsDetails_reducer,
    transactions: transactions_reducer,
    topup: topup_reducer,
    block: block_reducer,
    uploadBulk: upload_bulk_reducer,
    downloadBulk: download_bulk_reducer,
    replacement: replacement_reducer,
    corporate_budget: corporate_budget_reducer,
    transferLogs: transfer_logs_reducer,
    quick_logs: quick_logs_reducer,
    cartSetPolicies: card_set_policies_reducer,
    updateSetPolicies: card_edit_set_policies_reducer,
    cardPolicies: card_policies_reducer,
    card_limit: get_card_limit,
    get_card_policies: get_card_policies,
    card_enable: card_enable_limit_reducer,
    show_card_policy: show_card_policy_reducer,
    delete_card_policy: delete_card_policy_reducer,
    set_card_policy: set_card_policy_reducer,
    update_mobile: update_mobile_reducer,
    send_otp: send_otp_reducer,
    verify_otp: verify_otp_reducer,
    remind_card_activation: remind_card_activation_reducer,
    analytics_estimates: analytics_estimates_reducer,
    block_reasons: block_reasons_reducer,
    /// RootiFi ///
    createInvite_Link: createInviteLink_reducer,
    getOne_company: getOneCompany_reducer,
    disconnect_company: disconnectCompany_reducer,
    getAll_organisations: getAllOrganisations_reducer,
    sync_data: syncData_reducer,
    get_sync_history: getSyncHistory_reducer,
  }),
  account: combineReducers({
    txns: account_txns_reducer,
  }),
  categories: combineReducers({
    categories: categories_reducer,
    categoriesStatus: categories_status_reducer,
    addCategories: add_categories_reducer,
    deleteCategories: delete_categories_reducer,
  }),

  employees: combineReducers({
    employees: employees_reducer,
    employeesStatus: employees_status_reducer,
    employeebyId: employee_by_id_reducer,
    addEmployees: add_employees_reducer,
    deleteEmployees: delete_employees_reducer,
    updateEmployees: update_employees_reducer,
    uploadEmployeeDocument: upload_employee_reducer,
    getEmployeeDocument: get_employee_reducer,
    deleteEmployeeDocument: delete_employees_document_reducer,
  }),
  users: combineReducers({
    downloadLink: download_link_reducer,
    upload: upload_bulk_employees_reducer,
    user_fields: get_user_fields_reducer,
  }),
  analytics: combineReducers({
    analytics_cards: analytics_cards_reducer,
    analytics_cards_all: analytics_cards_all_reducer,
    analytics_cards_export: analytics_cards_export_reducer,
  }),
  companySetting: combineReducers({
    orgs: orgs_reducer,
    companyProfile: companyProfile_reducer,
    companyProfileImages: companyProfile_Images_reducer,
    updateCompanyProfile: updateCompanyProfile_reducer,
    updateCompanyProfilefImages: update_companyProfile_imgae_reducer,
    reportsApproval: reportsApproval_reducer,
    updateReportsApproval: updateReportsApproval_reducer,
    advanceApproval: advanceApproval_reducer,
    updateAdvanceApproval: updateAdvanceApproval_reducer,
    tripApproval: tripApproval_reducer,
    updateTripApproval: updateTripApproval_reducer,
    companySetting: companySetting_reducer,
    updateCompanySetting: add_companySetting_reducer,
    updateTransactionReport: update_companytransaction_reducer,
    getTransactionField: get_transactionField_reducer,
    getSingleTransactionField: get_transactionField_reducer,
    addTransactionField: add_transactionField_reducer,
    deleteTransactionField: delete_transactionField_reducer,
    updateTransactionField: update_transactionField_reducer,
    updateCompanyReport: update_companyreport_reducer,
    getReportField: get_reportField_reducer,
    addReportField: add_reportField_reducer,
    deleteReportField: delete_reportField_reducer,
    updateReportField: update_reportField_reducer,
    updateAdvancesReport: update_advancesreport_reducer,
    getAdvancesField: get_advancesField_reducer,
    addAdvancesField: add_advancesField_reducer,
    deleteAdvancesField: delete_advancesField_reducer,
    updateAdvancesField: update_advancesField_reducer,
    getCostCenterField: get_costCenterField_reducer,
    addCostCenterField: add_costCenterField_reducer,
    deleteCostCenterField: delete_costCenterField_reducer,
    updateCostCenterField: update_costCenterField_reducer,
    enableDisableCostCenter: enable_disable_costCenter_reducer,
    getMerchantsField: get_merchantsField_reducer,
    addMerchantsField: add_merchantsField_reducer,
    deleteMerchantsField: delete_merchantsField_reducer,
    updateMerchantsField: update_merchantsField_reducer,
    enabledisableMarchent: enable_disable_marchentField_reducer,
    getProjectsField: get_projectsField_reducer,
    addProjectsField: add_projectsField_reducer,
    deleteProjectsField: delete_projectsField_reducer,
    updateProjectsField: update_projectsField_reducer,
    enableDisableProjectField: enable_disable_projectField_reducer,
    getTripsField: get_tripsField_reducer,
    addTripsField: add_tripsField_reducer,
    deleteTripsField: delete_tripsField_reducer,
    updateTripsField: update_tripsField_reducer,
    updateCompanyTrips: updateCompanyTrips_reducer,
    getRolesPermissions: get_rolesPermissions_reducer,
    getRoles: get_roles_reducer,
    getSingleRoles: get_singleRoles_reducer,
    addRoles: add_roles_reducer,
    updateRoles: update_roles_reducer,
    getDesignations: get_designations_reducer,
    addDesignations: add_designations_reducer,
    delete_role: delete_role_reducer,
    deleteDesignations: delete_designations_reducer,
    updateDesignations: update_designations_reducer,
    getSingleDesignations: get_single_designations_reducer,
    getUserFields: get_userFields_reducer,
    getCustomFields: get_custom_fields_reducer,
    getUserFieldsColumnTypes: get_userFieldsColumnTypes_reducer,
    getSingleUserFields: get_singleUserFields_reducer,
    addUserFields: add_userFields_reducer,
    deleteUserFields: delete_userFields_reducer,
    updateUserFields: update_userFields_reducer,
    companyCities: companyCities_reducer,
    companyStates: companyStates_reducer,
    companyCountries: companyCountries_reducer,
    companyTzs: companyTzs_reducer,
    getReminderData: get_reminder_api_reducer,
    createCompanyMultipleCurrency: create_company_multiple_currency_reducer,
    getCompanyMultipleCurrency: get_company_multiple_currency_reducer,
    getAllCurrency: get_all_currency_reducer,
    getAllCountries: get_all_countries_reducer,
    createExchangeRate: create_exchange_rates_reducer,
    getAllExchangeRates: get_all_exchange_rates_reducer,
    toggleExchangeRate: toggle_exchange_rate_reducer,
    createReminderData: create_reminder_api_reducer,
    getAllCompanyDetails: get_all_company_details_reducer,
    createMultiBranch: create_multi_branch_reducer,
    getAllBranch: get_all_branch_reducer,
    getAllBranchList: get_all_branch_list_reducer,
    getAllBranchHeader: get_all_branch_header_reducer,
    getBranchID: get_branch_id_reducer,
    switchBrach: switch_brach_reducer,
    switchBrachRuby: switch_brach_ruby_reducer,
    getCompanyBaseCurrency: get_company_base_currency_reducer,
    getCardVenders: get_card_venders_reducer,
    getPortalPermission: get_portal_permission_reducer,
  }),

  customers: combineReducers({
    all: customers_reducer,
    create: create_customer_reducer,
    create_invoice: create_invoice_reducer,
    invoices: invoices_reducer,
    submit_invoice: submit_invoice_reducer,
    upload_profile: upload_profile_reducer,
    show_profile: show_profile_reducer,
    portalTableData: portal_table_data_reducer,
    portalRecordDetails: portal_record_details_reducer,
    portalDashboard: portal_dashboard_reducer,
    portalCompanyList: portal_dashboard_company_list_reducer,
  }),
  seed_data: seed_data_reducer,
  currencies: currencies_reducer,
  errorModal: error_modal_reducer,
  transfers: combineReducers({
    fetch_payment_links: fetch_payment_links_reducer,
    delete_payment_link: delete_payment_link_reducer,
    save_bulk_uploads: save_bulk_uploads,
    fetch_bulk_uploads: fetch_bulk_uploads_reducer,
    save_bulk_upload_payment_link: save_bulk_upload_payment_link_reducer,
    download_bulk_uploads: download_bulk_upload_reducer,
  }),
  ocr: combineReducers({
    docs: ocr_documents_reducer,
    delete: ocr_document_delete_reducer,
  }),
  on_board: combineReducers({
    create_on_board_form: create_on_board_form_reducer,
    postOnboardapi: post_onboard_api,
    getOnboardFields: get_onboard_fields_reducer,
    onboardCreate: onboard_create_reducer,
    onboardStep: onboard_step_reducer,
    onboardStepPreview: onboard_step_preview_reducer,
    onboardFinalSubmit: onboard_final_submit_reducer,
  }),
  policies: combineReducers({
    create: create_policy_reducer,
    enalbe: enable_policy_reducer,
    delete: delete_policy_reducer,
    policy: get_policy_reducer,
    policies: get_policies_reducer,
    metadata: get_policy_meta_reducer,
    change_priority: change_policy_priority_reducer,
  }),
 
  payments: combineReducers({
    payments: payments_reducer,
    get_payment: get_payment_reducer,
    create_payment: create_payment_reducer,
    submit_payment: submit_payment_reducer,
    recall_payment: recall_payment_reducer,
    approve_payment: approve_payment_reducer,
    reject_payment: reject_payment_reducer,
    edit_payment: edit_payment_reducer,
    update_payment: update_payment_reducer,
    delete_payment: delete_payment_reducer,
  }),
  z_accs: combineReducers({
    accounts: person_accounts_reducer,
    txns: master_account_txns_reducer,
    initialize_payment: initialize_payment_reducer,
    purposes: get_purposes_reducer,
    all_payments: all_payments_reducer,
  }),
});
