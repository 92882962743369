import { FileOutlined } from "@ant-design/icons"; // Custom styles
import { Button, Card, Divider, Layout, Space, Typography } from "antd";
import React, { useEffect, useState } from "react";
import ACHPay from "./ACHPay";
import { LOGO } from "../../../config/Images";
import download from "./assets/down.svg";
import Wire from "./Wire";
import PaymentForm from "./Card";
import { useParams } from "react-router-dom";
import useAdminAxios from "../../../config/useAxios";
import { apis } from "../../../config/APIs";
import { amountFormat } from "../../../utils";
import moment from "moment";
const { Title, Text, Paragraph } = Typography;

const PaymentOpts = () => {
  const params = useParams();
  const { onCall, data } = useAdminAxios({
    api: apis.fetchPaymentLink,
    method: "get",
  });
  const [selectedOpt, setSelectedOpt] = useState("ach");

  useEffect(() => {
    if (params.id)
      onCall({
        params: {
          token: params.id,
        },
      });
  }, []);

  const paymentDetails = data?.data;

  return (
    <div className="payment-layout">
      <div className="w50">
        {/* Header */}
        <div className="payment-header">
          <img src={LOGO} width={180} />
          {/* <Button
            className="download"
            type="primary"
            icon={<img src={download} width={15} />}
            size="large"
          >
            Download
          </Button> */}
        </div>

        {/* Payment Info */}
        <div className="payment-card">
          <div className="detail-box">
            <Text
              style={{
                opacity: 0.9,
              }}
            >
              From {paymentDetails?.ourparty_display_name} (
              {paymentDetails?.ourparty_email})
            </Text>
            <Title level={4}>Please complete this payment to Zenus Bank</Title>
          </div>
          <Card className="amount-box" bordered={false}>
            <Title level={2}>${amountFormat(paymentDetails?.amount)}</Title>
            <Text>
              Due on {moment(paymentDetails?.due_date).format("MMMM DD, YYYY")}
            </Text>
            <Divider />
            <Paragraph italic>“{paymentDetails?.description}”</Paragraph>
          </Card>
        </div>

        {/* Attachments */}
        {paymentDetails?.attachments?.length > 0 && (
          <div className="attachments-card">
            <Title level={4}>Attachments</Title>
            <div className="attachment-wrap" style={{ width: "100%" }}>
              {paymentDetails?.attachments?.map((ele) => {
                return (
                  <div className="attachment-item">
                    <Text>
                      <FileOutlined />
                      ZenusInvoice.pdf
                    </Text>
                    <Button
                      type="primary"
                      className="download"
                      icon={<img src={download} width={15} />}
                    >
                      Download
                    </Button>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
      <div
        style={{
          position: "relative",
        }}
      >
        <Divider type="vertical" />
      </div>
      <div className="w50">
        {/* Bank Details */}
        <div className="bank-details-card">
          <Space className="tabs" size="middle">
            <Button
              className={`${selectedOpt === "ach" ? "active" : ""} download`}
              type="primary"
              onClick={() => {
                setSelectedOpt("ach");
              }}
            >
              ACH / Wire
            </Button>
            <Button
              className={`${selectedOpt === "wire" ? "active" : ""} download`}
              type="primary"
              onClick={() => {
                setSelectedOpt("wire");
              }}
            >
              International Wire
            </Button>
            <Button
              className={`${selectedOpt === "card" ? "active" : ""} download`}
              type="primary"
              onClick={() => {
                setSelectedOpt("card");
              }}
            >
              Card
            </Button>
          </Space>
          {/* Bank Info */}
          {selectedOpt === "ach" && <ACHPay paymentDetails={paymentDetails} />}
          {selectedOpt === "wire" && <Wire paymentDetails={paymentDetails} />}
          {selectedOpt === "card" && paymentDetails?.enable_card_payments && (
            <PaymentForm />
          )}
        </div>
      </div>
    </div>
  );
};

export default PaymentOpts;
