import React from "react";
export const LeftNavContext = React.createContext({});

const LeftNavProvider = (props) => {
  const [toggle, setToggle] = React.useState({});
  const [collapsed, setCollapsed] = React.useState(false);
  const [formStep, setFormStep] = React.useState(false);
  const [activeMenu, setActiveMenu] = React.useState("/dashboard");
  const [activeSubMenu, setActiveSubMenu] = React.useState("");
  const [childrenMenu, setChildrenMenu] = React.useState("");
  const [navChildren, setNavChildren] = React.useState([]);
  const [showChildren, setShowChildren] = React.useState(false);
  const [isModalVisible, setIsModalVisible] = React.useState(false);

  return (
    <LeftNavContext.Provider
      value={{
        collapsed,
        setCollapsed,
        activeMenu,
        setActiveMenu,
        activeSubMenu,
        setActiveSubMenu,
        childrenMenu,
        setChildrenMenu,
        navChildren,
        setNavChildren,
        showChildren,
        setShowChildren,
        formStep,
        setFormStep,
        isModalVisible,
        setIsModalVisible,
        toggle, setToggle
      }}
    >
      {props.children}
    </LeftNavContext.Provider>
  );
};

export default LeftNavProvider;
