import React, { useEffect } from "react";
// import * as yup from 'yup';
import { Col, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
// import { addEmployees, updateEmployees } from '../../../../actions/employees';
import { useTranslation } from "react-i18next";
import { GetCustomFields } from "../../../../actions/companySettings";
import Close from "../../../../assets/icons/close.png";
import DeleteIcon from "../../../../assets/icons/delete.png";
import EditIcon from "../../../../assets/icons/edit.png";

const UserDetails = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [tab, setTab] = React.useState("details");
  const [userData, setUserData] = React.useState({});
  const employeesResponse = useSelector(
    (state) => state.employees.employees || {}
  );
  const employeeByIdResponse = useSelector(
    (state) => state.employees.employeebyId
  );

  useEffect(() => {
    return () => {
      dispatch({ type: GetCustomFields.RESET });
    };
  }, []);

  const handleDownloadClick = async (fileUrl, fileName) => {
    window.open(fileUrl, "_blank");
    // try {
    //   const response = await fetch(fileUrl);
    //   const blob = await response.blob();

    //   // Create a link element
    //   const link = document.createElement('a');

    //   // Create a Blob URL for the file blob
    //   const blobUrl = window.URL.createObjectURL(blob);

    //   // Set the link's href and download attributes
    //   link.href = blobUrl;
    //   link.download = fileName;

    //   // Append the link to the document body
    //   document.body.appendChild(link);

    //   // Programmatically trigger a click on the link to initiate the download
    //   link.click();

    //   // Remove the link from the document
    //   document.body.removeChild(link);

    //   // Release the Blob URL
    //   window.URL.revokeObjectURL(blobUrl);
    // } catch (error) {
    //   console.error('Error downloading file:', error);
    // }
  };

  React.useEffect(() => {
    if (employeesResponse.success && props.selectedRecord) {
      // const currentValues =
      //   findLodash(employeesResponse?.data?.data, {
      //     id: props.selectedRecord,
      //   }) || {};
      const { data: { data: currentValues = {} } = {} } =
        employeeByIdResponse || {};
      setUserData(currentValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeeByIdResponse]);

  const secs = [
    { label: t("Details"), key: "details" },
    { label: t("Grades & Designations"), key: "grades" },
    { label: t("Card"), key: "card" },
  ];

  const userDetails = [
    [
      { label: "Title", value: userData?.title },
      { label: "Name", value: userData?.first_name },
      { label: "Email", value: userData?.email },
    ],
    [
      { label: "Primary Phone", value: userData?.primary_phone },
      { label: "Employee ID", value: userData?.emp_id },
      { label: "Roles", value: userData?.roles },
    ],
    [{ label: "Username", value: userData?.username }],
  ];

  const graderDetails = [
    [
      { label: "Designation Title", value: "Technology" },
      { label: "Grade Level", value: "L1" },
    ],
  ];

  return (
    <>
      <div className="debit-card-details">
        <div className="new-report-detail">
          <div className="flex-between">
            <div className="report-head w100">
              <div className="title-sec">
                <div className="d-flex w100 flex-between">
                  <div className="d-flex" style={{ gap: "1.5rem" }}>
                    <h3>{userData.first_name}</h3>
                    <div className={`status status_active`}>Active</div>
                  </div>
                  <div className="d-flex" style={{ gap: "1rem" }}>
                    {userData?.roles?.includes("Super Admin") ? (
                      userData?.email === localStorage.getItem("user") ? (
                        <button
                          disabled={!props?.hasWriteAccess}
                          onClick={() =>
                            props.showEditModal(props.selectedRecord)
                          }
                        >
                          <img
                            src={EditIcon}
                            alt={"logo"}
                            className="action-logo"
                          />
                        </button>
                      ) : null
                    ) : (
                      <button
                        disabled={!props?.hasWriteAccess}
                        onClick={() =>
                          props.showEditModal(props.selectedRecord)
                        }
                      >
                        <img
                          src={EditIcon}
                          alt={"logo"}
                          className="action-logo"
                        />
                      </button>
                    )}
                    {userData?.roles?.includes("Super Admin") ||
                    userData?.email === localStorage.getItem("user") ? null : (
                      <button
                        disabled={!props?.hasWriteAccess}
                        onClick={() =>
                          props.showDeleteModal(props.selectedRecord)
                        }
                      >
                        <img
                          src={DeleteIcon}
                          alt={"logo"}
                          className="action-logo"
                        />
                      </button>
                    )}
                    <span
                      className="cursor"
                      onClick={() => {
                        props?.setShowDetailsPage(false);
                      }}
                    >
                      <img src={Close} width={20} alt={"close"} />
                    </span>
                  </div>
                </div>
              </div>
              <div className="mtHalf">
                <span
                  style={{
                    color: "#2B2B2B",
                    fontSize: "1rem",
                    fontWeight: "normal",
                  }}
                >
                  {userData?.email}{" "}
                </span>
              </div>
            </div>
          </div>
          <div className="tabs-sec">
            <ul>
              {secs.map(({ label, key }) => (
                <li
                  onClick={() => setTab(key.toLowerCase())}
                  className={`${
                    tab.toLowerCase() === key.toLowerCase() ? "active" : ""
                  }`}
                >
                  {label}
                </li>
              ))}
            </ul>
          </div>
          {tab === "details" && (
            <div className="other-info p2" style={{ width: "100%" }}>
              {userDetails.map((item, index) => (
                <Row
                  className="flex-wrap"
                  key={index}
                  style={{ marginBottom: "2rem" }}
                  gutter={[16]}
                >
                  {/* Loop through every 4 items */}
                  {item.map((detail, i) => (
                    <Col span={detail.span ?? 7} key={i}>
                      <p>
                        <label>{detail.label}</label>
                      </p>
                      <p>{detail.value}</p>
                    </Col>
                  ))}
                </Row>
              ))}
            </div>
          )}
          {tab === "grades" && (
            <div className="other-info p2" style={{ width: "100%" }}>
              {graderDetails.map((item, index) => (
                <Row
                  className="flex-wrap"
                  key={index}
                  style={{ marginBottom: "2rem" }}
                  gutter={[16]}
                >
                  {/* Loop through every 4 items */}
                  {item.map((detail, i) => (
                    <Col span={detail.span ?? 7} key={i}>
                      <p>
                        <label>{detail.label}</label>
                      </p>
                      <p>{detail.value}</p>
                    </Col>
                  ))}
                </Row>
              ))}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default UserDetails;
