import { QuestionCircleOutlined } from "@ant-design/icons";
import { Select, Tooltip } from "antd";
import { useState } from "react";
import { useDispatch } from "react-redux";

const CardDashboard = ({
  isCustomers,
  isVendor,
  selectPlaceholder,
  children,
  title,
  tooltipText,
  style,
  className,
  showDD,
  options = [],
  onChange,
  pStyle,
  hasWriteAccess,
}) => {
  const dispatch = useDispatch();
  const [ddVal, setDdVal] = useState("");

  return (
    <div className={`dashboard-box ${className}`} style={pStyle}>
      {!title ? null : (
        <div className="dashboard-title">
          <span>
            <span className="card-title">{title}</span>

            {tooltipText && (
              <span className="help-icon">
                <Tooltip placement="top" title={tooltipText}>
                  <QuestionCircleOutlined />
                </Tooltip>
              </span>
            )}
          </span>
          {showDD && (
            <Select
              placeholder={selectPlaceholder}
              disabled={!hasWriteAccess}
              value={ddVal}
              onSearch={(val) => {
               
              }}
              onChange={(val) => {
                setDdVal(val);
                onChange(val);
              }}
              options={[{ label: "All", value: "" }, ...options]}
              style={{
                width: "10rem",
                textAlign: "right",
              }}
            />
          )}
        </div>
      )}

      <div className="dashboard-content" style={style}>
        {children}
      </div>
    </div>
  );
};

export default CardDashboard;
