import { Typography } from "antd";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  getAllCountries
} from "../../../actions/companySettings";
import OTPForm from "./SignUpFrom/OTPForm";
import UserBusinessDetailsForm from "./SignUpFrom/UserBusinessDetailsForm";
import UserDetailsForm from "./SignUpFrom/UserDetailsForm";
import "./auth.css";

const SignUp = ({ setDisplayLoginRight }) => {
  const { t } = useTranslation();
  const { Title } = Typography;
  const history = useHistory();
  const dispatch = useDispatch();
  const [pageCount, setPageCount] = useState(1);
  const [otp, setOtp] = useState({
    otpSent: false,
    otp: "",
  });
  const [userData, setUserData] = useState({});
  const allCurrencyList = useSelector((state) =>
    state?.companySetting?.getAllCurrency?.data?.data?.map((item, index) => ({
      ...item,
      value: item?.id,
      label: (
        <span
          style={{
            color: "#212121",
            fontSize: 15,
          }}
        >
          <img
            src={`https://cdn.jsdelivr.net/npm/svg-country-flags@1.2.10/svg/${item?.country_code?.toLowerCase()}.svg`}
            className="mr1"
            width={25}
            style={{
              boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.4)",
            }}
          />
          {`${item?.country_name} (${item?.iso_code})`}
        </span>
      ),
    }))
  );
  const allCountries = useSelector((state) =>
    state?.companySetting?.getAllCountries?.data?.data?.map((item, index) => ({
      ...item,
      value: item?.country_iso_code,
      label: (
        <span
          style={{
            color: "#212121",
            fontSize: 15,
          }}
        >
          <img
            src={`https://cdn.jsdelivr.net/npm/svg-country-flags@1.2.10/svg/${item?.country_iso_code?.toLowerCase()}.svg`}
            className="mr1"
            width={25}
            style={{
              boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.4)",
            }}
          />
          {`${item?.country_name} (${item?.country_iso_code})`}
        </span>
      ),
    }))
  );
  const {
    control,
    register,
    watch,
    formState: { errors },
    handleSubmit,
    setValue,
    getValues,
    reset,
    trigger
  } = useForm({
    defaultValues: {
      first_name: "",
      last_name: "",
      middle_name: "",
      email: "",
      primary_phone: "",
      phone_country_code: "+1",
      dob: "",
      step: 1,
      page_name: "USER_DETAILS",
      gender: "",
      product_type: "savings",
    },
    shouldUseNativeValidation: false,
  });

  useEffect(() => {
    if (pageCount === 4) {
      setDisplayLoginRight(false);
    }
    setOtp({
      otpSent: false,
      otp: "",
    });
    localStorage.setItem("pageCount", pageCount);
    localStorage.setItem("formData", JSON.stringify(getValues()));
  }, [pageCount]);

  useEffect(() => {
    const pc = localStorage.getItem("pageCount");
    const formData = localStorage.getItem("formData");

    if (pc && formData) {
      setPageCount(Number(pc));
      const fm = JSON.parse(formData);
      reset({
        ...fm,
      });
    }
    // dispatch(getAllCurrency());
    dispatch(getAllCountries());
  }, []);
  return (
    <>
      <div className="reset-form signup-container">
        {/* <button onClick={() => {
					setPageCount(pageCount + 1)
				}}>Click</button> */}
        <div className="head-sec">
          <Title level={3}>Get a bank account</Title>

          <div className="sub-header">
            <span>Fill the below details to get a business loan:</span>
          </div>
        </div>
        <div>
          <div>
            <div style={{ display: pageCount === 1 ? "block" : "none" }}>
              <UserDetailsForm
                register={register}
                trigger={trigger}
                control={control}
                errors={errors}
                onSuccess={() => {
                  setPageCount(pageCount + 1);
                }}
                reset={reset}
                watch={watch}
                handleSubmit={handleSubmit}
                setUserData={setUserData}
                pageCount={pageCount}
                setValue={setValue}
                userData={userData}
              />
            </div>
            <div style={{ display: pageCount === 3 ? "block" : "none" }}>
              <UserBusinessDetailsForm
                register={register}
                onSuccess={() => {
                  setPageCount(pageCount + 1);
                }}
                pageCount={pageCount}
                control={control}
                errors={errors}
                handleSubmit={handleSubmit}
                allCurrencyList={allCurrencyList}
                allCountries={allCountries}
              />
            </div>
            <div style={{ display: pageCount === 2 ? "block" : "none" }}>
              <OTPForm
                register={register}
                control={control}
                setPageCount={setPageCount}
                pageCount={pageCount}
                errors={errors}
                otp={otp}
                setOtp={setOtp}
                userData={userData}
                source={"ONBOARD"}
              />
            </div>
          </div>
          {pageCount === 4 ? null : (
            <>
              <div className="mt1">
                <span className="footer-note">
                  Already have an account ?{" "}
                  <b
                    className="cursor"
                    style={{ color: "#212121" }}
                    onClick={() => {
                      history.push("/");
                    }}
                  >
                    Log In
                  </b>
                </span>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default SignUp;
