import { applyMiddleware, compose, createStore } from "redux";
// import logger from "redux-logger";
import { message } from "antd";
import logger from "redux-logger";
import createSagaMiddleware from "redux-saga";
import endpoint, { encryptAsync, decryptAsync, source } from "./config/Axios";
import reducers from "./reducers";
import { rootSagas } from "./sagas";

const sagaMiddleware = createSagaMiddleware();

const store =
  window.location.hostname !== "localhost"
    ? createStore(reducers, {}, compose(applyMiddleware(sagaMiddleware)))
    : createStore(
        reducers,
        {},
        compose(applyMiddleware(sagaMiddleware, logger))
      );

sagaMiddleware.run(rootSagas);

endpoint.interceptors.request.use(
  async function (config) {
    // Do something before request is sent
    config.headers.Authorization = "Bearer " + localStorage.getItem("token");
    config.headers.deviceType = "web";
    config.cancelToken = source.token;
    if (process.env.REACT_APP_DEVELOPMENT === "true") {
      config.headers["x-api-encrypt"] = false;
    } else {
      if (config.data && Object.keys(config.data).length > 0) {
        encryptAsync(config.data)
          .then((res) => {
            config.data = { data: res };
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

endpoint.interceptors.response.use(
  async function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    if (process.env.REACT_APP_DEVELOPMENT !== "true") {
      if (
        typeof response?.data?.data === "string" &&
        response?.data?.error === false
      )
        response.data.data = await decryptAsync(response.data?.data);
    }
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (
      error?.response?.status === 401 ||
      (error?.response?.status === 422 &&
        error?.response.data?.message === "Signature has expired")
    ) {
      source.cancel("Session Timeout");
      message.error(
        <span className="messageText">
          Session Expired. Please login again.
        </span>
      );
      localStorage.clear();
      store.dispatch({
        type: "LOGOUT/AUTH_SUCCESS",
        payload: {},
      });
    }
    return Promise.reject(error);
  }
);

export default store;
