import { EyeFilled, EyeInvisibleFilled } from "@ant-design/icons";
import {
  Button,
  Col,
  Dropdown,
  Menu,
  message,
  Row,
  Skeleton,
  Space,
  Typography,
} from "antd";
import React, { useCallback, useLayoutEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { get_person_accounts } from "../../actions/z_accounts";
import copy from "../../assets/icons/copy.png";
import down from "../../assets/icons/down.png";
import { currencyMapper } from "../../config/helper";
import { amountFormat } from "../../utils";
import CardDashboard from "./CardDashboard";
import LineChart from "../Common/LineAreaForCard";
import AreaChart from "./AccountChart";
import { AccountDisplay } from "../Account/AccountCard";
import usePayAxios from "../../config/useAxios";
import { apis } from "../../config/APIs";

const { Title, Text, Link } = Typography;

const LoaderBankAccount = () => {
  return (
    <Row className="account-card">
      <Col className="first-col" style={{ width: "63%" }}>
        <CardDashboard>
          <div>
            {/* Account Selection */}
            <div className="mh-12">
              <div className="flex-between">
                <Skeleton.Input
                  loading={true}
                  active={true}
                  style={{ height: 40, width: "25rem" }}
                />

                <Space>
                  <Button className="gn-btn">+ Deposit</Button>
                  <Dropdown overlay={null} className="gn-btn">
                    <Button>
                      <span className="acc-num mr1">Transfer</span>
                      <img src={down} width={11} />
                    </Button>
                  </Dropdown>
                </Space>
              </div>

              {/* Balance Info */}
              <div className="bal-sec">
                <Text className="avl-bal">Available Balance</Text>
                <h3>
                  <Skeleton.Input
                    loading={true}
                    active={true}
                    style={{
                      height: 43,
                    }}
                  />
                </h3>
                <Text className="avl-bal">USD • Main</Text>
              </div>
            </div>
            {/* Balance Details */}
            <div className="hr" />
            <Row
              style={{
                gap: "4rem",
              }}
              className="bal-details"
            >
              <Col>
                <Text className="avl-bal">Type</Text>
                <div className="value">
                  <Skeleton.Input
                    loading={true}
                    active={true}
                    style={{
                      height: 21,
                    }}
                  />
                </div>
              </Col>
              <Col>
                <Text className="avl-bal">Balance</Text>
                <div className="value">
                  <Skeleton.Input
                    loading={true}
                    active={true}
                    style={{
                      height: 21,
                    }}
                  />
                </div>
              </Col>
              <Col>
                <Text className="avl-bal">Reserved Amount</Text>
                <div className="value">
                  <Skeleton.Input
                    loading={true}
                    active={true}
                    style={{
                      height: 21,
                    }}
                  />
                </div>
              </Col>
            </Row>
          </div>
        </CardDashboard>
      </Col>
      {/* Right Column */}

      <Col style={{ width: "37%" }} className="right-col">
        <CardDashboard className={"h100"}>
          <div className="w95 mh-12 mt1">
            <div className="flex-between">
              <Text className="avl-bal">Routing Number</Text>
              <div className="value">
                <Skeleton.Input
                  loading={true}
                  active={true}
                  style={{
                    height: 24,
                  }}
                />
              </div>
            </div>
            <div className="flex-between">
              <Text className="avl-bal">Account number</Text>
              <div className="value">
                <Skeleton.Input
                  loading={true}
                  active={true}
                  style={{
                    height: 24,
                  }}
                />
              </div>
            </div>
            <div className="flex-between">
              <Text className="avl-bal">Status</Text>
              <div>
                <Skeleton.Input
                  loading={true}
                  active={true}
                  style={{
                    height: 24,
                  }}
                />
              </div>
            </div>
          </div>
          <div className="hr" />
          <div>
            <span className="view-statement">View Statements</span>
          </div>
        </CardDashboard>
      </Col>
    </Row>
  );
};

const BankAccount = ({ acc, fromDetail, setKey }) => {
  const [visible, setVisible] = React.useState(false);
  const [selectedAccount, setSelectedAccount] = React.useState("");
  const history = useHistory();
  const dispatch = useDispatch();
  const toggleVisibility = () => {
    if (!visible) {
      dispatch(
        get_person_accounts({
          params: { page_number: 1, per_page: 10 },
        })
      );
      onCall({
        params: {
          account_id: acc?.id,
        },
      });
    }
    setVisible(!visible);
  };
  const {
    onCall,
    data: masterData,
    loading,
  } = usePayAxios({
    api: apis.accountBalanceByMasterID,
    method: "get",
  });
  const bAcc = useSelector((state) => state?.z_accs?.accounts?.data?.data);
  const bLoading = useSelector((state) => state?.z_accs?.accounts?.loading);
  const bankAcc = useSelector(
    (state) => state?.z_accs?.accounts?.data?.data || []
  );

  const menu = (
    <Menu onClick={handleTransferClick}>
      <Menu.Item key="1">Transfer To</Menu.Item>
      <Menu.Item key="2">Transfer From</Menu.Item>
    </Menu>
  );

  function handleMenuClick(itm) {
    if (bankAcc?.length) {
      setSelectedAccount(itm.key);
      setKey?.(itm.key);
    }
  }

  function handleTransferClick(itm) {
    if (itm.key === "1") {
      history.push("/account", {
        from: selectedAccount,
      });
    } else if (itm.key === "2") {
      history.push("/account", {
        to: selectedAccount,
      });
    }
  }

  const handleCopy = useCallback((text) => {
    navigator.clipboard.writeText(text).then(
      () => {
        message.success(<span className="messageText">Copied</span>);
      },
      (err) => console.error("Failed to copy text: ", err)
    );
  }, []);

  const accountMenu = useMemo(() => {
    return bankAcc?.length ? (
      <Menu onClick={handleMenuClick}>
        {bankAcc?.map((itm) => (
          <Menu.Item key={itm?.id}>
            {itm?.account_name} - {itm.account_number}
          </Menu.Item>
        ))}
      </Menu>
    ) : (
      <Menu>
        <Menu.Item key="1">No Accounts</Menu.Item>
      </Menu>
    );
  }, [bankAcc]);

  useLayoutEffect(() => {
    dispatch(
      get_person_accounts({
        params: { page_number: 1, per_page: 10 },
      })
    );
  }, []);

  useLayoutEffect(() => {
    if (bankAcc?.length) {
      setSelectedAccount(bankAcc?.[0]?.id);
      setKey?.(bankAcc?.[0]?.id);
    }
  }, [bAcc]);

  const account = acc
    ? acc
    : Array.isArray(bankAcc)
    ? bankAcc?.find((ele) => ele.id === selectedAccount)
    : {};

  return bLoading && !account ? (
    <LoaderBankAccount />
  ) : (
    <Row className="account-card">
      <CardDashboard
        pStyle={{
          width: "63%",
        }}
      >
        <div>
          {/* Account Selection */}
          <div
            className="mh-12"
            // style={{
            //   display: "flex",
            //   justifyContent: "space-between",
            // }}
          >
            <div className="flex-between">
              <Dropdown
                overlay={accountMenu}
                trigger={["click"]}
                disabled={fromDetail}
              >
                <Button>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: 10,
                    }}
                  >
                    <img
                      src="https://flagcdn.com/us.svg"
                      alt="US Flag"
                      width="24"
                    />
                    <span className="acc-num" title={account?.account_name}>
                      {account?.account_name?.length > 25
                        ? `${account?.account_name?.substr(0, 25)}...`
                        : account?.account_name}{" "}
                      -{" "}
                      <AccountDisplay
                        accountNumber={account?.account_number}
                        showEye={true}
                      />
                    </span>
                    {fromDetail ? null : <img src={down} width={11} />}
                  </div>
                </Button>
              </Dropdown>
              <Space>
                {/* <Button className="gn-btn">+ Deposit</Button> */}
                <Dropdown overlay={menu} className="gn-btn">
                  <Button>
                    <span className="acc-num mr1">Transfer</span>
                    <img src={down} width={11} />
                  </Button>
                </Dropdown>
              </Space>
            </div>

            {/* Balance Info */}
            <div className="bal-sec">
              <Text className="avl-bal">Available Balance</Text>
              <h3>
                {currencyMapper[account?.balance?.currency] ?? "$"}
                {visible ? (
                  acc?.account_type === "CURRENCY" ? (
                    loading ? (
                      <span className="shimmer">
                        <span
                          style={{
                            width: "10rem",
                            height: "1.8rem",
                            marginLeft: 10,
                          }}
                        ></span>
                      </span>
                    ) : (
                      amountFormat(
                        masterData?.data?.[0]
                          ?.availableBalanceAmount
                      )
                    )
                  ) : (
                    `${amountFormat(account?.balance?.balance)}`
                  )
                ) : (
                  "******"
                )}
                <Button
                  type="link"
                  icon={visible ? <EyeInvisibleFilled /> : <EyeFilled />}
                  onClick={toggleVisibility}
                />
              </h3>
              <Text className="avl-bal">
                {account?.balance?.currency} • Main
              </Text>
            </div>
            {/* <div>
                <span className="status status_active">Active</span>
              </div> */}
          </div>
          {/* <AreaChart /> */}
          {/* Balance Details */}
          <div className="hr" />
          <Row
            style={{
              gap: "4rem",
            }}
            className="bal-details"
          >
            <Col>
              <Text className="avl-bal">Type</Text>
              <div className="value">{account?.account_type}</div>
            </Col>
            {account?.account_type === "CURRENCY" && (
              <>
                <Col>
                  <Text className="avl-bal">Reserved Amount</Text>
                  <div className="value">
                    {currencyMapper[account?.balance?.currency] ?? "$ "}
                    {visible ? (
                      <>
                        {loading ? (
                          <span className="shimmer">
                            <span
                              style={{
                                width: "7rem",
                                height: "1.3rem",
                                marginLeft: 10,
                              }}
                            ></span>
                          </span>
                        ) : (
                          amountFormat(
                            masterData?.data?.responseData?.[0]?.reservedAmount
                          )
                        )}
                      </>
                    ) : (
                      "******"
                    )}
                  </div>
                </Col>
                <Col>
                  <Text className="avl-bal">Total Amount</Text>
                  <div className="value">
                    {currencyMapper[account?.balance?.currency] ?? "$ "}
                    {visible ? (
                      <>
                        {loading ? (
                          <span className="shimmer">
                            <span
                              style={{
                                width: "7rem",
                                height: "1.3rem",
                                marginLeft: 10,
                              }}
                            ></span>
                          </span>
                        ) : (
                          amountFormat(
                            masterData?.data?.responseData?.[0]?.balanceAmount
                          )
                        )}
                      </>
                    ) : (
                      "******"
                    )}
                  </div>
                </Col>
              </>
            )}
          </Row>
        </div>
      </CardDashboard>
      <CardDashboard
        className={"h100 routing"}
        pStyle={{
          width: fromDetail ? "34%" : "36%",
        }}
      >
        <div className="w95 mh-12 mt1">
          <div className="flex-between">
            <Text className="avl-bal">Account Name</Text>
            <div className="value">
              {account?.account_name}{" "}
              <img
                src={copy}
                width={12}
                onClick={() => {
                  handleCopy(account?.account_name);
                }}
              />
            </div>
          </div>
          <div className="flex-between">
            <Text className="avl-bal">Routing Number</Text>
            <div className="value">
              {account?.routing_code}{" "}
              <img
                src={copy}
                width={12}
                onClick={() => {
                  handleCopy(account?.routing_code);
                }}
              />
            </div>
          </div>
          <div className="flex-between">
            <Text className="avl-bal">Account number</Text>
            <div className="value">
              <AccountDisplay
                accountNumber={account?.account_number}
                left={true}
              />
              <img
                src={copy}
                width={12}
                onClick={() => {
                  handleCopy(account?.account_number);
                }}
              />
            </div>
          </div>
          <div className="flex-between">
            <Text className="avl-bal">Status</Text>
            <div className={`status status_${account?.status?.toLowerCase()}`}>
              {account?.status}
            </div>
          </div>
        </div>
        <div className="hr" />
        <div
          className="flex-between"
          style={{
            alignItems: "center",
          }}
        >
          <span
            className="copy-light"
            onClick={() => {
              handleCopy(
                `Account Name:    ${account?.account_name} \nRouting Code:    ${account?.routing_code} \nAccount Number:  ${account?.account_number}`
              );
            }}
          >
            Copy
          </span>
          <span
            className="view-statement"
            onClick={() => {
              history.push("/statements", {
                accountId: account?.id,
              });
            }}
          >
            View Statements
          </span>
        </div>
      </CardDashboard>
    </Row>
  );
};

export default BankAccount;
