import { message } from "antd";
import Close from "../../assets/icons/close.png";
import { FormInput } from "../inputs";

import { Select } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";
import Bank from "../../assets/icons/bank_transfer_free.svg";
import Credit from "../../assets/icons/credit_card.svg";
import Debit from "../../assets/icons/debit_card.svg";
import { apis } from "../../config/APIs";
import usePayAxios from "../../config/useAxios";
import "../Common/styles/sendemail.css";
const { Option } = Select;
const SendEmail = () => {
  const {t} = useTranslation()
  const { onCall: sendEmail } = usePayAxios({
    api: apis.sendEmail,
    method: "post",
  });
  const history = useHistory();
  const location = useLocation();
  const [emailData, setEmailData] = useState([]);
  const [comName, setComName] = useState("");
  const [emails, setEmails] = useState([]);
  const [openAddNew, setOpenAddNew] = useState(false);

  const handleEmailsChange = (newEmails) => {
    setEmails(newEmails);
  };

  useEffect(() => {
    setEmailData(location?.state?.formData?.data?.data);
    setComName(
      emailData?.company?.name
        .split(" ")
        .map((ele) => ele[0])
        .join("")
    );
  }, []);

  const {
    reset,
    watch,
    control,
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    getValues,
  } = useForm({
    defaultValues: {
      order_id: "",
      to: "",
      cc: [],
      subject: "",
      is_payment: false,
    },
    shouldUseNativeValidation: false,
  });

  const onSubmit = (data) => {
    let payload = {
      order_id: emailData?.id,
      to: data.to,
      cc: emails,
      subject: data.subject,
      is_payment: false,
    };
    sendEmail({
      params: {
        module_type: "ESTIMATE",
        type: "payment",
      },
      data: payload,
    })
      .then((data) => {
        message.success(<span className="messageText">"Mail Send"</span>);
        history.push(location?.state?.from);
      })
      .catch((err) => {
        message.error(<span className="messageText">{err.message}</span>);
        history.push(location?.state?.from);
      });
  };
  useEffect(() => {
    setValue("to", emailData?.beneficiaries?.email);
  }, [emailData]);
  return (
    <>
      <form
        className="new-bill-form minHeight"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="head">
          <label>Email to Customer</label>
          <span className="close cursor mr2">
            <img
              src={Close}
              width={20}
              alt={"close"}
              onClick={() => {
                history.push(location?.state?.from, {
                  setSideMenu: true,
                });
              }}
            />
          </span>
        </div>

        <div className="email_header">
          <div className="container">
            <div style={{ marginBottom: "2rem" }}>
              <span className="label">Send To</span>
              <div className="form_div">
                <FormInput
                  control={control}
                  {...register("to")}
                  errors={errors}
                  placeholder="Enter your Email"
                  hideCreateOption={true}
                />
              </div>
            </div>
            <div style={{ marginBottom: "2rem" }}>
              <span className="label">Cc</span>
              <div className="form_div multi_input">
                <Select
                  mode="tags"
                  style={{ width: "100%" }}
                  placeholder="Enter email addresses"
                  onChange={handleEmailsChange}
                  value={emails}
                >
                  {emails.map((email) => (
                    <Option key={email}>{email}</Option>
                  ))}
                </Select>
              </div>
            </div>
            <div style={{ marginBottom: "2rem" }}>
              <span className="label">Subject</span>
              <div className="form_div">
                <FormInput
                  control={control}
                  {...register("subject")}
                  hideCreateOption={true}
                  placeholder="Enter your Subject"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="email_section">
          <div className="email_container">
            <div className="email_from">
              <div className="email_header">
                <span className="circle">
                  {emailData?.company?.name
                    .split(" ")
                    .map((ele) => ele[0])
                    .join("")
                    .slice(0, 2)}
                </span>
                <h5 className="header sm">{emailData?.company?.name}</h5>
                <span className="header label">is requesting a payment of</span>
                <h4 className="header lg">${emailData?.total_amount}</h4>
                <span className="header light">
                  Tax Amount - ${emailData?.total_tax_amount}
                </span>
                <div className="line"></div>
              </div>
              <div className="email_body">
                <div>
                  <span className="body">Invoice Number#</span>
                  <span className="body">Sales Order#</span>
                </div>
                <div style={{ marginBottom: "1.5rem" }}>
                  <span className="body color">{emailData?.invoice_no}</span>
                  <span className="body color">{emailData?.ref_po_no}</span>
                </div>
                <div>
                  <span className="body">Payment Terms</span>
                  <span className="body">Payment Due Date</span>
                </div>
                <div>
                  <span className="body color">{emailData?.payment_terms}</span>
                  <span className="body color">
                    {moment(emailData?.po_due_date).format("MMMM Do YYYY")}
                  </span>
                </div>
                <div style={{ marginTop: "1.5rem" }}>
                  <span className="note">No note from vendor</span>
                  <div></div>
                </div>
                <div className="line bottom_margin"></div>
                <div>
                  <span className="body">Vendor GST Number</span>
                  <span className="body">Customer GST Number</span>
                </div>
                <div>
                  <span className="body color">
                    {location?.state?.isSales
                      ? "------"
                      : emailData?.beneficiaries?.gstin}
                  </span>
                  <span className="body color">
                    {location?.state?.isSales
                      ? emailData?.beneficiaries?.gstin
                      : "------"}
                  </span>
                </div>
                <div>
                  <button type="button" className="body_button" onClick={()=> setOpenAddNew(true)}>
                    Make Payment
                  </button>
                </div>
              </div>
              <div className="email_footer">
                <div>
                  <span className="footer_header">You can pay using:</span>
                </div>
                <div className="footer_cards">
                  <div>
                    <img src={Credit} />
                    <span>
                      Credt Card <br /> 2.9% fee
                    </span>
                  </div>
                  <div>
                    <img src={Bank} />
                    <span>
                      Bank Transfer
                      <br />
                      fee
                    </span>
                  </div>
                  <div>
                    <img src={Debit} />
                    <span>
                      Debit Card <br /> 2.9% fee
                    </span>
                  </div>
                </div>
                <div className="bottom_margin">
                  <h5 className="footer_text">
                    For questions about this payment request email{" "}
                    <span style={{ color: "#3781F5" }}>
                      {emailData?.beneficiaries?.email}
                    </span>
                  </h5>
                </div>
              </div>
            </div>
          </div>
          <div className="overlay">
            <button style={{ color: "#3f81f4" }} className="border">
              Cancel
            </button>
            <button className="primary" htmlType="submit">
              Send
            </button>
          </div>
        </div>
        {/* <div className="check_box">
          <Checkbox>Attach Purchase Order PDF</Checkbox>
          <div></div>
        </div> */}
      </form>
    </>
  );
};

export default SendEmail;
