// import down from "../../../assets/icons/down.png";
import { useHistory } from "react-router-dom";
import {
  Button,
  Modal,
  Menu,
  // Row, Col, Dropdown
} from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../../actions/auth";

const KYCHeader = ({ Header, SubHeader }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useDispatch();
  const reject_reason = useSelector(
    (state) => state?.on_board?.onboardStep?.data?.data?.reject_reason
  );
  const onboardStepResponse = useSelector(
    (state) => state?.on_board?.onboardStep?.data
  );

  const menu = (
    <Menu onClick={handleMenuClick}>
      {/* <Menu.Item key="5">Change Password</Menu.Item> */}
      <Menu.Item key="1">Logout</Menu.Item>
    </Menu>
  );
  function handleMenuClick(e) {
    switch (e.key) {
      case "1": {
        localStorage.clear();
        dispatch(logout());
        break;
      }
      default:
    }
  }

  return (
    <>
      <Modal
        title={"Are you sure you'd like to close the tab?"}
        visible={isModalOpen}
        onCancel={() => {
          setIsModalOpen(false);
        }}
        footer={[]}
        className={"center-aligned-modal"}
      >
        <div className="close_modal_section">
          <div className="close_modal_container">
            <div className="close_body">
              <h4>Closing this tab will result in the loss of your data.</h4>
            </div>
            <div className="close_button">
              <Button
                key="2"
                className="cancelButton mr1 mt1"
                htmlType="button"
                onClick={() => setIsModalOpen(false)}
              >
                {t("cancel")}
              </Button>
              <Button
                key="1"
                className="formButton mt1"
                onClick={() => {
                  history.push("/dashboard");
                }}
              >
                {t("confirm")}
              </Button>
            </div>
          </div>
        </div>
      </Modal>
      <div className="kyc-header-section mb2">
        <div className="kyc-header-container">
          {/* <div className="kyc-close flex-end mb4">
          
            <Row>
              <Col span={24} className="header-right candidate-logout">
                <div className="user-drop">
                  <Dropdown overlay={menu} placement="bottomLeft">
                    <div className="d-flex align-items-center cursor ">
                      <div className="circle">
                        <div className="first-letter">
                          {localStorage
                            .getItem("name")
                            ?.slice(0, 1)
                            ?.toUpperCase()}
                        </div>
                      </div>
                      <span className="user-name">
                        {" "}
                        {localStorage.getItem("name")}
                      </span>
                      <img src={down} alt="" className="down" width={10} />
                    </div>
                  </Dropdown>
                </div>
              </Col>
            </Row>
          </div> */}
          {reject_reason && (
            <div className="strip red w60">{reject_reason}</div>
          )}
          {onboardStepResponse?.data?.kyc_status === "kyc_approved" &&
            !reject_reason && (
              <div className="flex-between strip green w60">
                <span>
                  Your KYC status has updated. Please logout and login again.
                </span>
                <button
                  onClick={() => {
                    localStorage.clear();
                    dispatch(logout());
                  }}
                >
                  Logout
                </button>
              </div>
            )}

          <div className="kyc-header w70">
            <h4 className="header-text fw-M mb0">{Header}</h4>
            <span className="sub-header-text fw-M fs-18">{SubHeader}</span>
          </div>
        </div>
      </div>
    </>
  );
};
export default KYCHeader;
