import { Button, Result, Steps, Upload, message } from "antd";
import React, { useEffect, useState } from "react";
// import ExcelRenderer from "papaparse";
import { saveAs } from "file-saver";
import { parse } from "json2csv";
import { ExcelRenderer } from "react-excel-renderer";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import fileIcon from "../../../src/assets/icons/file_icon.svg";
import importIcon from "../../../src/assets/icons/import_icon.svg";
import removeIcon from "../../../src/assets/icons/remove_icon.svg";
import {
  ExcelDateToJSDate,
  getCsvColumnMessage,
  getFileExtension,
  hasEmptyMandatoryFields,
} from "../../../src/utils";
import {
  GetSummary,
  ImportApi,
  getPageDetails,
  getSummary,
  importApi,
} from "../../actions/imports/imports";
import { headerTitles } from "../../actions/masterdata/masterdata";
import UploadFileMaping from "./UploadFileMaping";
import "./UploadMain.css";
import UploadPreview from "./UploadPreview";
const { Dragger } = Upload;
const Step = Steps.Step;

const UploadMain = ({ type }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [step, setStep] = React.useState(0);
  const [columnRows, setColumnRows] = useState({});

  const uploadRes = useSelector((state) => state?.users?.upload);
  const [columnHeader, setColumnHeader] = useState({});
  const [fileKeyValue, setFileKeyValue] = useState([]);
  const [previewRes, setPreviewRes] = useState({});
  const [previewCol, setPreviewCol] = useState([]);
  const [fileContent, setFileContent] = useState({
    name: "",
    columns: [],
    rows: [],
  });
  const getPageDetailsResponse = useSelector(
    (state) => state?.imports?.getPageDetails
  );
  const getSummaryResponse = useSelector((state) => state?.imports?.getSummary);
  const importApiRes = useSelector((state) => state?.imports?.importApi);
  useEffect(() => {
    if (step == 0 && columnHeader && Object?.keys(columnHeader)?.length !== 2) {
      setColumnHeader((prev) => {
        return {
          ...prev,
          ...getPageDetailsResponse?.data?.data,
        };
      });
    }
  }, [step, getPageDetailsResponse]);
  useEffect(() => {
    if (
      getSummaryResponse?.data?.error === false &&
      !localStorage.getItem("import_id")
    ) {
      // message.success(<span className="messageText">{getSummaryResponse?.data?.message}</span>)
      localStorage.setItem(
        "import_id",
        getSummaryResponse?.data?.data?.import_id
      );
    }
    setPreviewRes(getSummaryResponse?.data?.data);
    getSummaryResponse?.data?.data?.skipped_row_data?.map((ele, index) => {
      return {
        title: ele,
        dataIndex: "transaction_number",
        key: "transaction_number",
      };
    });
  }, [getSummaryResponse]);

  useEffect(() => {
    if (importApiRes?.data?.error === false) {
      dispatch({
        type: ImportApi.RESET,
      });
      dispatch({
        type: GetSummary.RESET,
      });
      localStorage.removeItem("import_id");
      setFileContent({
        name: "",
        columns: [],
        rows: [],
      });
      setStep(() => step + 1);
    }
  }, [importApiRes]);
  useEffect(() => {
    dispatch(
      headerTitles({
        title: t(`${type}_select_file`),
        description: "",
      })
    );
    dispatch(getPageDetails({ import_type: type }));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    return () => {
      dispatch({
        type: ImportApi.RESET,
      });
      dispatch({
        type: GetSummary.RESET,
      });
      localStorage.removeItem("import_id");
    };
  }, []);

  const beforeUpload = (file, fileList) => {
    const fileName = getFileExtension(file?.name);
    if (["xlsx", "XLSX", "csv", "CSV", "xls", "XLS"]?.includes(fileName)) {
      ExcelRenderer(file, (err, resp) => {
        if (err) {
          message.error(<span className="messageText">{err}</span>);
        } else {
          if (
            resp?.rows?.filter((innerArray) => innerArray?.length > 1)
              ?.length >= 2
          ) {
            const headers = resp?.rows?.[0];
            const result = [];
            for (let i = 1; i < resp?.rows?.length; i++) {
              const obj = {};
              for (let j = 0; j < headers.length; j++) {
                if (resp?.rows[i][j] !== undefined)
                  obj[headers[j]] = resp?.rows[i][j];
              }
              if (Object.keys(obj).length > 0) {
                result.push(obj);
              }
            }
            const previewColumns = headers?.map((ele, index) => {
              return {
                title: ele,
                dataIndex: ele,
                key: ele,
                render: (text, record) => {
                  let result = getCsvColumnMessage(record.errors, ele);
                  if (result?.isPresent) {
                    return (
                      <span title={result?.message} style={{ color: "red" }}>
                        {text}
                      </span>
                    );
                  } else {
                    return <span style={{ color: "#000" }}>{text}</span>;
                  }
                },
              };
            });
            setFileContent({
              name: file?.name,
              columns: resp?.rows?.[0],
              rows: resp?.rows,
            });
            setFileKeyValue(result);
            setPreviewCol(previewColumns);
            setColumnHeader((prev) => {
              return {
                ...prev,
                data_list: result,
              };
            });
          } else {
            message.error(
              <span className="messageText">{"Uploaded File is Empty"}</span>
            );
            return Upload.LIST_IGNORE;
          }
        }
      });
    } else {
      message.error(
        <span className="messageText">{"Please Upload xlsx or csv files"}</span>
      );
      return Upload.LIST_IGNORE;
    }
    return false;
  };
  const handleFileChange = () => {};

  const handleStep = async () => {
    if (step == 0) {
      if (columnHeader?.page_details) {
        const page_details = columnHeader?.page_details;
        const data_list = columnHeader?.data_list;
        page_details?.forEach((ele) => {
          ele.entity_columns?.forEach((itm) => {
            itm.entity_list?.forEach((el) => {
              const stringsWith = fileContent?.columns?.filter((string) =>
                string
                  ?.toLowerCase()
                  ?.trim()
                  ?.includes(el.auto_select_column?.toLowerCase()?.trim())
              );

              const exact = stringsWith?.find(
                (sw) =>
                  sw?.toLowerCase()?.trim() ===
                  el.auto_select_column?.toLowerCase()?.trim()
              );
              if (exact && el.data_type === "string") {
                data_list?.forEach((dl) => {
                  dl[exact] = dl[exact]?.toString();
                });
              } else if (exact && el.data_type === "number") {
                data_list?.forEach((dl) => {
                  dl[exact] = !isNaN(dl[exact]) ? Number(dl[exact]) : dl[exact];
                });
              } else if (exact && el.data_type === "date") {
                data_list?.forEach((dl) => {
                  dl[exact] = dl[exact]
                    ? ExcelDateToJSDate(dl[exact])
                    : dl[exact];
                });
              }

              if (exact) {
                el.csv_column = exact;
              } else {
                el.csv_column = "";
              }
            });
          });
        });

        setColumnHeader({
          ...columnHeader,
          page_details,
          data_list,
        });
      }
      setStep(step + 1);
    } else if (step == 1) {
      const isEmptyMandatoryFields = await hasEmptyMandatoryFields(
        columnHeader?.page_details
      );
      if (isEmptyMandatoryFields) {
        message.error(
          <span className="messageText">{"Fill the Required Fields"}</span>
        );
      } else if (!isEmptyMandatoryFields) {
        if (localStorage.getItem("import_id")) {
          dispatch(
            getSummary({
              ...columnHeader,
              params: {
                import_type: type,
                import_id: localStorage.getItem("import_id"),
              },
            })
          );
        } else {
          dispatch(
            getSummary({ ...columnHeader, params: { import_type: type } })
          );
        }
        setStep(step + 1);
      }
    } else if (step == 2) {
      dispatch(
        importApi({
          import_id: localStorage.getItem("import_id"),
          params: { import_type: type },
        })
      );
    } else {
      setStep(step + 1);
    }
  };
  function getAutoSelectColumns(data) {
    const autoSelectColumns = {};

    for (const group of data) {
      for (const entityColumn of group.entity_columns[0].entity_list) {
        autoSelectColumns[entityColumn.auto_select_column] = "";
      }
    }

    downloadCsv(autoSelectColumns);
  }
  // const downloadCsv = async (value) => {
  //   json2csv(value).then((csv) => {
  //     var blob = new Blob([csv], { type: "text/csv;charset=utf-8" });
  //     saveAs(blob, `${type}_sample_file.csv`);
  //   });
  // };

  const downloadCsv = async (value) => {
    try {
      const csv = parse(value); // Directly parse JSON to CSV
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8" });
      saveAs(blob, `${type}_sample_file.csv`);
    } catch (error) {
      console.error("Error generating CSV file:", error);
    }
  };
  const uploadListInfo = [{}];
  return (
    <div>
      <div className="heading-div">
        {/* <div className="m1">
          <PageHeader
            title=""
            isClose={true}
            onCloseClick={() => {
              history.goBack();
            }}
          />
        </div> */}
        <div className="bulk-users">
          <div className="steps-align upload-steps">
            <Steps
              current={step}
              status={step === 3 ? "finish" : "process"}
              className="steps"
            >
              <Step
                title={t("configure")}
                // onClick={() => {
                // 	setStep(0);
                // }}
              />

              <Step
                title={t("map_fields")}
                // onClick={() => {
                // 	setStep(1);
                // }}
              />

              <Step
                title={t("preview")}
                // onClick={() => {
                // 	setStep(2);
                // }}
              />
              <Step
                title={t("Complete")}
                // onClick={() => {
                // 	setStep(3);
                // }}
              />
            </Steps>
          </div>
          {step === 0 && (
            <>
              <div className="flex-center w100 upload_section flex-col">
                {fileContent?.name ? (
                  <div className="after_file_upload">
                    <div className="upload_icon mb1">
                      <img src={fileIcon} alt="file icon" />
                    </div>
                    <h4 className="file_name">{fileContent?.name}</h4>
                    <div
                      className="upload_remove d-flex-center"
                      onClick={() => {
                        setFileContent({
                          name: "",
                          columns: [],
                          rows: [],
                        });
                      }}
                    >
                      <div className="remove_icon">
                        <img src={removeIcon} alt="file icon" />
                      </div>
                      <h4 className="mb0 mlHalf" style={{ color: "#000" }}>
                        {t("Remove")}
                      </h4>
                    </div>
                  </div>
                ) : null}
                <div>
                  <Dragger
                    showUploadList={false}
                    maxCount={1}
                    beforeUpload={beforeUpload}
                    name="upload-file"
                    onChange={(evt) => handleFileChange(evt, "gst_certificate")}
                    className={`dragger_section ${
                      fileContent?.name ? "border_top_remove" : null
                    }`}
                  >
                    <div className="upload_container">
                      {!fileContent?.name ? (
                        <div className="upload_top">
                          <div className="import_icon">
                            <img src={importIcon} alt="upload icon" />
                          </div>
                          <div className="upload_text">
                            <h4>{t("drag_and_drop")}</h4>
                          </div>
                        </div>
                      ) : null}
                      <div className="upload_bottom">
                        <div>
                          <button type="button" className="send-btn">
                            {fileContent?.name
                              ? t("replace_file")
                              : t("choose_file")}
                          </button>
                        </div>
                        <div className="upload_note">
                          <span>{t("max_file_size")}</span>
                        </div>
                      </div>
                    </div>
                  </Dragger>
                </div>
              </div>
              <div className="upload_button_content">
                <h4 className="button_text">
                  Download a{" "}
                  <span
                    className="button_link sample_csv"
                    onClick={() =>
                      getAutoSelectColumns(
                        getPageDetailsResponse?.data?.data?.page_details
                      )
                    }
                  >
                    sample csv
                  </span>{" "}
                  file and compare it to your import file to ensure you have the
                  file perfect for the import.
                  {/* or <span className="button_link sample_xls" onClick={() => getAutoSelectColumns(getPageDetailsResponse?.data?.data?.page_details, "xls")}>sample xls</span>  file
									and compare it to your import file to ensure you have the file perfect for the import. */}
                </h4>
              </div>
              {/* <div className="upload_info_section w100">
                <div className="upload_info_container">
                  <div className="upload_info">
                    <div className="info_header">
                      <h4>Instructions</h4>
                    </div>

                    <div className="upload_info_list">
                      <ul className="upload_info_ul">
                        {uploadListInfo?.map((ele, index) => (
                          <li key={index} className="upload_info_li">
                            file and compare it to your import file to ensure
                            you have the file perfect for the import
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div> */}
            </>
          )}
          {step === 1 && (
            <div>
              <UploadFileMaping
                setColumnRows={setColumnRows}
                fileContent={fileContent}
                columnRows={columnRows}
                columnHeader={columnHeader}
                setStep={setStep}
                uploadLoading={uploadRes?.loading}
                setColumnHeader={setColumnHeader}
              />
            </div>
          )}
          {step === 2 && (
            <UploadPreview
              previewRes={previewRes}
              previewCol={previewCol}
              type={type}
              fileContent={fileContent}
              getSummaryResponse={getSummaryResponse}
            />
          )}
          {step === 3 && (
            <div style={{ display: step === 3 ? "block" : "none" }}>
              <Result
                status="success"
                title={t("success_import")}
                subTitle={t("its_take")}
                extra={[
                  <button
                    className="send-btn"
                    style={{ borderRadius: "5px" }}
                    onClick={() => {
                      setStep(0);
                    }}
                  >
                    {t("go_back")}
                  </button>,
                ]}
                step={step}
              />
            </div>
          )}

          {step === 3 ? null : (
            <div className="bottom_button">
              {step >= 1 && (
                <Button
                  className="pp-secondary-button"
                  onClick={() => {
                    setStep((b) => b - 1);
                  }}
                >
                  <span>{t("back")}</span>
                </Button>
              )}

              <button
                type="primary"
                htmlType="submit"
                className="send-btn"
                style={{ marginLeft: "2rem" }}
                onClick={handleStep}
                disabled={
                  !fileContent?.name || step === 2
                    ? previewRes?.ready_to_import >= 1
                      ? false
                      : true
                    : false
                }
              >
                {t("next")}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UploadMain;
