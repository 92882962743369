import React, { useEffect } from "react";
import p_c from "../../assets/images/p_check.png";
import p_uc from "../../assets/images/p_uc.png";

const PasswordCheck = ({ password, dark, setPasswordCheck }) => {
  const alphabet = /[a-z]/;
  const alphabetUpper = /[A-Z]/;
  const specialcharacters = /[@$!^%#?&]/;
  const number = /[0-9]/;
  console.log({ password });
  useEffect(() => {
    if (password) {
      setPasswordCheck &&
        setPasswordCheck(
          !alphabet.test(password) ||
            !specialcharacters.test(password) ||
            !alphabetUpper.test(password) ||
            !number.test(password)
        );
    } else {
      setPasswordCheck && setPasswordCheck(false);
    }
    // eslint-disable-next-line
  }, [password]);
  return (
    password && (
      <div
        style={{ color: dark ? "#333" : "red", fontSize: 14, marginTop: 30 }}
      >
        <>
          {!alphabet.test(password)}
          {alphabet.test(password) ? (
            <span
              style={{
                marginTop: 10,
                color: "#212121",
                display: "flex",
                alignItems: "center",
                fontWeight: 600,
                gap: 8,
              }}
            >
              <img src={p_c} width={15} />
              Atleast one lowercase letter (a-z)
              <br />
            </span>
          ) : (
            <span
              style={{
                marginTop: 10,
                color: "#212121",
                display: "flex",
                alignItems: "center",
                fontWeight: 600,
                gap: 8,
                opacity: 0.6,
              }}
            >
              <img src={p_uc} width={15} />
              Atleast one lowercase letter (a-z)
              <br />
            </span>
          )}
          {!alphabetUpper.test(password)}
          {alphabetUpper.test(password) ? (
            <>
              <span
                style={{
                  marginTop: 10,
                  color: "#212121",
                  display: "flex",
                  alignItems: "center",
                  fontWeight: 600,
                  gap: 8,
                }}
              >
                <img src={p_c} width={15} />
                Atleast one uppercase letter (A-Z)
                <br />
              </span>
            </>
          ) : (
            <span
              style={{
                marginTop: 10,
                color: "#212121",
                display: "flex",
                alignItems: "center",
                fontWeight: 600,
                gap: 8,
                opacity: 0.6,
              }}
            >
              <img src={p_uc} width={15} />
              Atleast one uppercase letter (A-Z)
              <br />
            </span>
          )}
          {!number.test(password)}
          {number.test(password) ? (
            <>
              <span
                style={{
                  marginTop: 10,
                  color: "#212121",
                  display: "flex",
                  alignItems: "center",
                  fontWeight: 600,
                  gap: 8,
                }}
              >
                <img src={p_c} width={15} />
                Atleast one number (0-9)
                <br />
              </span>
            </>
          ) : (
            <span
              style={{
                marginTop: 10,
                color: "#212121",
                display: "flex",
                alignItems: "center",
                fontWeight: 600,
                gap: 8,
                opacity: 0.6,
              }}
            >
              <img src={p_uc} width={15} />
              Atleast one number (0-9)
              <br />
            </span>
          )}
          {!specialcharacters.test(password)}
          {specialcharacters.test(password) ? (
            <>
              <span
                style={{
                  marginTop: 10,
                  color: "#212121",
                  display: "flex",
                  alignItems: "center",
                  fontWeight: 600,
                  gap: 8,
                }}
              >
                <img src={p_c} width={15} />
                Atleast special character ($@%!)
                <br />
              </span>
            </>
          ) : (
            <span
              style={{
                marginTop: 10,
                color: "#212121",
                display: "flex",
                alignItems: "center",
                fontWeight: 600,
                gap: 8,
                opacity: 0.6,
              }}
            >
              <img src={p_uc} width={15} />
              Atleast special character ($@%!)
              <br />
            </span>
          )}
        </>
      </div>
    )
  );
};

export default PasswordCheck;
