import React, { useEffect, useState } from "react";
import close from "../../assets/icons/close.png";
import { useDispatch, useSelector } from "react-redux";
import { headerTitles } from "../../actions/masterdata/masterdata";
import {
  Button,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Switch,
  Table,
  Tag,
  Typography,
} from "antd";
import SecurityTables from "./AlertsNSecurityComps/SecurityTables";
import { apis } from "../../config/APIs";
import usePayAxios from "../../config/useAxios";
import { useFormik } from "formik";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import PasswordCheck from "../Common/PasswordCheck";
import { ChangePassword, changePassword } from "../../actions/auth";
import OTPComponent from "../Common/OTPComponent";
const { Text, Title } = Typography;
const ValidationSchema = yup.object().shape({
  old_password: yup
    .string()
    .trim()
    .required("Current Password is required")
    .min(8),
  new_password: yup
    .string()
    .trim()
    .required("Password is required")
    .min(8)
    .notOneOf(
      [yup.ref("old_password"), null],
      "Current Password and Password must not match"
    ),
  password_confirmation: yup
    .string()
    .trim()
    .required("Password Confirmation is required")
    .min(8)
    .oneOf([yup.ref("new_password"), null], "Passwords must match"),
});
const Security = () => {
  const [otp, setOtp] = useState({
    otpSent: false,
    otp: "",
  });
  const { onCall: verifyOtp, loading: verifyLoading } = usePayAxios({
    api: apis.payment_verify_otp,
    method: "post",
  });
  const { onCall: gen_otp, loading: genLoading } = usePayAxios({
    api: apis.gen_otp,
    method: "post",
  });
  const [passwordCheck, setPasswordCheck] = React.useState(true);
  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: {
      old_password: "",
      new_password: "",
      password_confirmation: "",
    },
    enableReinitialize: true,
    validationSchema: ValidationSchema,
    onSubmit: (vals) => {
      verifyOtp({
        data: {
          user_id: localStorage.getItem("user_id"),
          email_id: localStorage.getItem("user"),
          source: "CHANGE_PASSWORD",
          otp: otp.otp,
          otp_token: otp.token,
        },
      })
        .then((res) => {
          if (res.error === false) {
            dispatch(changePassword(vals));
          }
        })
        .catch((err) => {
          message.error(
            <span className="messageText">{err?.response?.data?.message}</span>
          );
        });
    },
  });
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const changePasswordResponse = useSelector(
    (state) => state.auth.changePassword
  );

  const dispatch = useDispatch();
  const {
    onCall,
    data: auth,
    reset,
  } = usePayAxios({
    api: apis.enabletwoFactorAuth,
    method: "post",
  });

  console.log({
    auth
  });
  

  React.useEffect(() => {
    const { data: { message: res_message = "", error = undefined } = {} } =
      changePasswordResponse;
    if (error === false) {
      message.success(<span className="messageText">{res_message}</span>);
      setIsModalVisible(false);
      dispatch({
        type: ChangePassword.RESET,
      });
      formik.resetForm();
    } else if (error) {
      res_message &&
        message.error(<span className="messageText">{res_message}</span>);
      dispatch({
        type: ChangePassword.RESET,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changePasswordResponse]);

  useEffect(() => {
    dispatch(
      headerTitles({
        title: "Security",
        description: "Configure security",
      })
    );
    onCall({});
  }, []);

  const columns = [
    {
      title: "Method",
      dataIndex: "method",
      key: "method",
      render: (text, record) => (
        <>
          {text}
          {record.required && (
            <Tag color="red" style={{ marginLeft: "8px" }}>
              Required
            </Tag>
          )}
        </>
      ),
    },
    {
      title: "Added On",
      dataIndex: "addedOn",
      key: "addedOn",
    },
    {
      title: "Most Recent Activity",
      dataIndex: "recentActivity",
      key: "recentActivity",
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <>
          {record.action === "Generate New Codes" ? (
            <button className="link">Generate New Codes</button>
          ) : record.action === "Reset" ? (
            <button className="link">Reset</button>
          ) : (
            <button className="link">Remove</button>
          )}
        </>
      ),
    },
  ];

  // Define data for the table rows
  const data = [
    {
      key: "1",
      method: "Backup codes (8/10 remaining)",
      required: true,
      addedOn: "Aug 21 at 12:00 AM",
      recentActivity: "Sep 15 at 12:00 AM",
      action: "Generate New Codes",
    },
    {
      key: "2",
      method: "2FA app (Third-party)",
      required: true,
      addedOn: "Aug 21 at 12:00 AM",
      recentActivity: "Sep 15 at 12:00 AM",
      action: "Reset",
    },
    {
      key: "3",
      method: "YubiKey",
      addedOn: "Sep 17 at 12:00 AM",
      recentActivity: "-",
      action: "Remove",
    },
    {
      key: "4",
      method: "Touch ID",
      addedOn: "Sep 22 at 12:00 AM",
      recentActivity: "Sep 26 at 12:00 AM",
      action: "Remove",
    },
    {
      key: "5",
      method: "+ Add security key",
      addedOn: "",
      recentActivity: "",
      action: "Add",
    },
  ];

  const handleCancel = () => {
    setIsModalVisible(false);
    formik.resetForm();
  };

  const renderChangePassword = () => {
    return (
      <div className="psd_content">
        {otp?.otpSent ? null : (
          <>
            <Title level={3} className="mt1">
              {t("set_ur_pwd")}
            </Title>
            <Text>{t("please_create_new_pwd")}</Text>
            <br />
          </>
        )}
        <Form
          onSubmitCapture={formik.handleSubmit}
          className="mt2"
          layout="vertical"
        >
          {otp?.otpSent ? (
            <div className="flex-center flex-col w100 mb3">
              <div className="otp_info" style={{ maxWidth: "80%" }}>
                <div>
                  <b
                    className="mt-2 mb-1"
                    style={{
                      fontSize: "1.6rem",
                    }}
                  >
                    Enter the OTP
                  </b>
                </div>
                <span>
                  Check your email!{" "}
                  <span style={{ fontWeight: "800" }}>
                    {localStorage.getItem("user")}
                  </span>{" "}
                  We've sent an OTP to your linked email.
                </span>
              </div>
              <OTPComponent
                source={"CHANGE_PASSWORD"}
                label={"Enter the OTP"}
                hideTitle={true}
                value={otp.otp}
                userData={otp}
                loading={changePasswordResponse?.loading || verifyLoading}
                onChange={(otp) => {
                  setOtp((o) => ({
                    ...o,
                    otp,
                  }));
                }}
              />
              <button
                className="send-btn mt2"
                id="otp"
                disabled={changePasswordResponse?.loading}
              >
                Verify OTP
              </button>
            </div>
          ) : (
            <>
              <Form.Item
                label={t("current_pwd")}
                placeholder={t("enter_current_pwd")}
                help={formik.touched.old_password && formik.errors.old_password}
              >
                <Input.Password
                  placeholder="Enter password"
                  value={formik.values.old_password}
                  onChange={(e) => {
                    formik.setFieldValue(
                      "old_password",
                      e.target.value?.trim()
                    );
                  }}
                  name="old_password"
                />
              </Form.Item>
              <Form.Item
                label={t("new_pwd")}
                placeholder={t("enter_new_pwd")}
                help={formik.touched.new_password && formik.errors.new_password}
              >
                <Input.Password
                  placeholder={t("enter_pwd")}
                  value={formik.values.new_password}
                  onChange={(e) => {
                    formik.setFieldValue(
                      "new_password",
                      e.target.value?.trim()
                    );
                  }}
                  name="new_password"
                />
                <PasswordCheck
                  password={formik.values.new_password}
                  setPasswordCheck={setPasswordCheck}
                />
              </Form.Item>
              <Form.Item
                label={t("confirm_new_pwd")}
                placeholder={t("enter_new_pwd")}
                help={
                  formik.touched.password_confirmation &&
                  formik.errors.password_confirmation
                }
              >
                <Input.Password
                  placeholder={t("enter_pwd")}
                  value={formik.values.password_confirmation}
                  onChange={(e) => {
                    formik.setFieldValue(
                      "password_confirmation",
                      e.target.value?.trim()
                    );
                  }}
                  name="password_confirmation"
                />
              </Form.Item>

              <button
                id="sendOtp"
                className="send-btn mt1"
                type="button"
                disabled={
                  passwordCheck || changePasswordResponse?.loading || genLoading
                }
                onClick={() => {
                  gen_otp({
                    data: {
                      user_id: localStorage.getItem("user_id"),
                      email_id: localStorage.getItem("user"),
                      source: "CHANGE_PASSWORD",
                    },
                  })
                    .then((res) => {
                      if (res.error === false) {
                        message.success(
                          <span className="messageText">{res?.message}</span>
                        );
                        setOtp({
                          ...otp,
                          otpSent: true,
                          token: res?.data,
                        });
                      }
                      if (res.error) {
                        message.error(
                          <span className="messageText">{res?.message}</span>
                        );
                      }
                    })
                    .catch((err) => {
                      message.error(
                        <span className="messageText">
                          {err?.response?.data?.message}
                        </span>
                      );
                    });
                }}
              >
                Change Password
              </button>
              <button
                type="button"
                className="back-btn ml1 mt1"
                onClick={() => handleCancel()}
              >
                {t("cancel")}
              </button>
            </>
          )}
        </Form>
      </div>
    );
  };
  return (
    <>
      {isModalVisible && (
        <Modal
          title="Change Password"
          visible={isModalVisible}
          onCancel={handleCancel}
          footer={[]}
          className="center-aligned-modal"
        >
          {renderChangePassword()}
        </Modal>
      )}
      <div className="upload_payment_parent alerts">
        <div className="sec-head">
          <h2>Security</h2>
          <p className="alert-info">
            Stay updated with critical security alerts related to your account,
            such as unauthorized access attempts and password changes.
          </p>
          {/* <img src={close} width={20} className="close" /> */}
        </div>
        <Divider />
        <div className="p2 security">
          <div className="alert-row flex-between">
            <div>
              <label>Password</label>
              <span className="sub">
                Set a unique password to protect your account.
              </span>
            </div>
            <button
              className="btn"
              onClick={() => {
                setIsModalVisible(true);
              }}
            >
              Change Password
            </button>
          </div>
          <div className="alert-row flex-between">
            <div>
              <label>Two-factor authentication</label>
              <span className="sub">
                Two-factor authentication (2FA) keeps accounts secure by adding
                an extra layer of protection beyond a password. By default, we
                require you to set up a 2FA app that can generate 2FA codes, but
                you can add a security key to log in even quicker.
              </span>
            </div>
            <div>
              <Switch
                checked={auth?.data?.["2FA"]}
                onChange={(checked) => {
                  onCall({
                    params: {
                      is_update: true,
                    },
                    data: {
                      is_enable_two_fa: checked,
                      two_fa_method: {
                        email: checked,
                      },
                    },
                  });
                }}
              >
                Set 2FA
              </Switch>
            </div>
          </div>
          {/* <Table columns={columns} dataSource={data} pagination={false} /> */}
          <br />
          <br />
          <SecurityTables />
        </div>
      </div>
    </>
  );
};

export default Security;
