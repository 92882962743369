import { Input, Modal, Select } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { countries as country } from "./country";

const CompanyAddressModal = ({ company, formik }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const updateCompanyProfileResponse = useSelector(
    (state) => state.companySetting.updateCompanyProfile || {}
  );

  React.useEffect(() => {
    const { data: { status = "" } = {} } = updateCompanyProfileResponse;
    if (status) {
      setIsModalVisible(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateCompanyProfileResponse]);

  return (
    <>
      <Modal
        visible={isModalVisible}
        onCancel={() => {
          setIsModalVisible(false);
        }}
        title="Edit Company Details"
        className="right-alinged-modal"
        getContainer={false}
      >
        <div className="pp-form-item">
          <label className="required">Street One</label>

          <Input.TextArea
            className="pp-form-input"
            placeholder="Enter Street One"
            value={formik?.values?.street_one}
            onChange={formik.handleChange}
            name="street_one"
          />
        </div>
        <div className="pp-form-item">
          <label className="required">Street Two</label>

          <Input.TextArea
            className="pp-form-input"
            placeholder="Enter Street Two"
            value={formik?.values?.street_two}
            onChange={formik.handleChange}
            name="street_two"
          />
        </div>
        <div className="pp-form-item">
          <label className="required">City</label>
          <Input
            className="pp-form-input"
            placeholder="Enter City"
            value={formik?.values?.city}
            onChange={formik.handleChange}
            name="city"
          />
        </div>

        <div className="pp-form-item">
          <label className="required">State</label>
          <Input
            className="pp-form-input"
            placeholder="Enter State"
            value={formik?.values?.state}
            onChange={formik.handleChange}
            name="state"
          />
        </div>

        <div className="pp-form-item">
          <label>Country</label>
          <Select
            className="pp-dropdown pp-dropdown-inline"
            style={{ width: "100%" }}
            placeholder={"Select Country"}
            options={country?.map((ele) => ({
              label: (
                <>
                  <img
                    src={`https://cdn.jsdelivr.net/npm/svg-country-flags@1.2.10/svg/${ele.code?.toLowerCase()}.svg`}
                    className="mr1"
                    style={{
                      width: "2rem",
                      boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.4)",
                    }}
                  />{" "}
                  {ele.label}
                </>
              ),
              value: ele.code,
              code: ele.value,
            }))}
            showSearch
            name="country"
            onChange={(val, data) => {
              formik.setFieldValue("country", data.code);
            }}
            value={formik.values.country}
            allowClear
          />
        </div>

        <div className="pp-form-item">
          <label>Zipcode</label>
          <Input
            className="pp-form-input"
            placeholder="Enter Pincode"
            value={formik?.values?.pincode}
            onChange={formik.handleChange}
            name="pincode"
          />
        </div>

        <div className="action-btns mt2">
          <button className="send-btn">Update Address</button>
          <button
            className="back-btn"
            type="button"
            onClick={() => {
              setIsModalVisible(false);
            }}
          >
            Cancel
          </button>
        </div>
      </Modal>
      <div className="flex-between mb1">
        <h3>Company Address</h3>
        <button
          onClick={() => {
            setIsModalVisible(true);
          }}
          type="button"
        >
          Edit
        </button>
      </div>
      <div className="company-address company-details">
        <div className="item">
          <label>Address</label>
          <span>
            {company?.address && (
              <>
                {company.address?.[0]?.street_one}{" "}
                {company.address?.[0]?.street_two}
                <br />
              </>
            )}
            {company?.address?.[0]?.city && (
              <>
                {company?.address?.[0]?.city}
                <br />
              </>
            )}
            {company?.address?.[0]?.state && (
              <>
                {company?.address?.[0]?.state}
                <br />
              </>
            )}
            {company?.address?.[0]?.country && (
              <>
                {company?.address?.[0]?.country}
                <br />
              </>
            )}
            {company?.address?.[0]?.zipcode && (
              <>{company?.address?.[0]?.zipcode}</>
            )}
          </span>
        </div>
      </div>
    </>
  );
};

export default CompanyAddressModal;
